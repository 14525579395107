import { Spacer } from "~/components/Space";
import { SidebarTemplate } from "./SidebarTemplate";
import { isNative } from "~/utils/env";

export const FeedbackView = () => {
	// const _submitFeedback = () => {
	// 	if (isEmpty(feedback())) {
	// 		return;
	// 	}
	// 	setLoading(true);
	// 	client
	// 		.post("/api/v1/submit-feedback", {
	// 			feedback: feedback(),
	// 			email: user()?.email ?? email() ?? null,
	// 		})
	// 		.then(() => {
	// 			setSuccess(true);
	// 			setLoading(false);
	// 		});
	// };
	// const { form, data, setFields } = createForm<Form>({
	// 	initialValues: { email: user()?.email ?? "" },
	// });
	return (
		<SidebarTemplate
			header={"Feature request? Bug report? Let us know"}
			actions={[]}
			bodyPadding={true}
		>
			<p class="body-text">
				We'd love to hear your feedback on the {isNative ? "app" : "site"}, you can open feature
				requests or submit bug reports{" "}
				<a
					href="https://chessbook.canny.io"
					target="_blank"
					rel="noreferrer"
					class="text-primary font-semibold"
				>
					on our Canny board
				</a>
				.
			</p>
			<Spacer between={["body-text", "body-text"]} />
			<p class="body-text">
				Or, if you want to contact us directly, you can send us an email at{" "}
				<a href="mailto:support@chessbook.com" class="text-primary font-semibold">
					support@chessbook.com
				</a>
				.
			</p>
		</SidebarTemplate>
	);
};
