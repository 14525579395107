import { Index } from "solid-js";
import type { MoveRating } from "~/types/MoveRating";
import { clsx } from "~/utils/classes";

export const MoveRatingsRow = (props: {
	counts: Record<MoveRating, number>;
}) => {
	const progressIcons = () => {
		return [
			{
				icon: "fa-kit fa-tick bg-green-60 rounded-full",
				class: "",
				text: `${props.counts.good}`,
			},
			{
				icon: "fa-kit fa-inaccuracy",
				class: "bg-gray-80 rounded-full",
				text: props.counts.inaccuracy,
			},
			{
				icon: "fa-kit fa-mistake",
				class: "bg-orange-45 rounded-full",
				text: props.counts.mistake,
			},
			{
				icon: "fa-kit fa-blunder",
				class: "bg-red-60 rounded-full",
				text: props.counts.blunder,
			},
		];
	};
	return (
		<div class={clsx("row items-center space-x-4 lg:space-x-6 shrink-0 pl-4 opacity-100")}>
			<Index each={progressIcons()}>
				{(i) => {
					return (
						<div class="row items-center text-gray-10 ">
							<p class={clsx("text-base font-semibold lg:text-lg text-secondary")}>{i().text}</p>
							<i class={clsx(i().class, i().icon, " ml-2 text-base lg:text-lg")} />
						</div>
					);
				}}
			</Index>
		</div>
	);
};
