import { destructure } from "@solid-primitives/destructure";
import { every, filter, isEmpty, isNil } from "lodash-es";
import { Match, Show, Switch, createMemo, createSignal, onCleanup, onMount } from "solid-js";
import { isServer } from "solid-js/web";
import { Puff } from "solid-spinner";
import { Spacer } from "~/components/Space";
import type { Side } from "~/types/Side";
import { TableResponse } from "~/types/TableResponse";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, quick, useMode } from "~/utils/app_state";
import { isDevelopment } from "~/utils/env";
import { registerViewMode } from "~/utils/register_view_mode";
import { usePlansAction, useViewCoursesAction } from "~/utils/sharedActions";
import { onBack } from "~/utils/signals/onBack";
import { c, stylex } from "~/utils/styles";
import { shouldUsePeerRates } from "~/utils/table_scoring";
import { trackEvent } from "~/utils/trackEvent";
import { CMText } from "./CMText";
import { CollapsibleSidebarSection } from "./CollapsibleSidebarSection";
import { Intersperse } from "./Intersperse";
import { PreAdd } from "./PreAdd";
import { PreReview } from "./PreReview";
import { RepertoireMovesTable } from "./RepertoireMovesTable";
import { RepertoireOverview } from "./RepertoirtOverview";
import { ReviewText } from "./ReviewText";
import { type SidebarAction, SidebarActions, useBiggestGapActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";

export const RepertoireBuilder: EnrichedComponent = function Responses() {
	const currentEpd = () => UI().getActiveChessboard().getCurrentEpd();
	const activeSide = () => BROWSING_STATE().repertoireSide;
	const positionReport = () =>
		REPERTOIRE_STATE().positionReports[BROWSING_STATE().activeRepertoireId!]?.[currentEpd()];
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId;

	const currentSide = () => BROWSING_STATE().currentSide;
	const currentLine = () =>
		UI()
			.getActiveChessboard()
			.get((s) => s.moveLog);
	const hasPendingLine = () => BROWSING_STATE().hasPendingLineToAdd;
	const isPastCoverageGoal = () => BROWSING_STATE().isPastCoverageGoal;
	const tableResponses = () => BROWSING_STATE().tableResponses;
	onMount(() => {});

	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const usePeerRates = () => shouldUsePeerRates(positionReport());
	const allLocalStockfish = () =>
		every(tableResponses(), (m) => TableResponse.getStockfishEvalSource(m) === "local") &&
		tableResponses().length > 0;
	const mode = useMode();
	const myMoves = createMemo(() =>
		filter(tableResponses(), (tr) => {
			return !isNil(tr.repertoireMove) && activeSide() === currentSide();
		}),
	);
	const builderActions = createMemo(() => getBuilderActions());
	const otherMoves = createMemo(() =>
		filter(tableResponses(), (tr) => {
			return isNil(tr.repertoireMove) && activeSide() === currentSide();
		}),
	);
	const prepareFor = createMemo(() =>
		filter(tableResponses(), () => {
			return activeSide() !== currentSide();
		}),
	);
	if (!isServer && !isDevelopment) {
		const beforeUnloadListener = (event: Event) => {
			if (hasPendingLine()) {
				event.preventDefault();
				const prompt = "You have an unsaved line, are you sure you want to exit?";
				// @ts-expect-error
				event.returnValue = prompt;
				return prompt;
			}
		};
		addEventListener("beforeunload", beforeUnloadListener, { capture: true });
		onCleanup(() => {
			removeEventListener("beforeunload", beforeUnloadListener, {
				capture: true,
			});
		});
	}
	const { header, body } = destructure(() => {
		return getResponsesHeader(
			currentLine(),
			allLocalStockfish(),
			myMoves().length,
			activeSide()!,
			currentSide(),
			isPastCoverageGoal()!,
			onboarding().isOnboarding,
		);
	});
	const moveLog = () =>
		UI()
			.getActiveChessboard()
			.get((v) => v).moveLog;
	onBack(() => {
		quick((_s) => {
			if (!isEmpty(moveLog())) {
				animateSidebar("left");
				UI().getActiveChessboard().backOne();
			} else {
				animateSidebar("left");
				UI().getActiveChessboard().resetPosition();
				UI().backToEither([RepertoireOverview, PreAdd]);
			}
		});
	}, "stay");
	const responses = createMemo(() => {
		if (!isEmpty(myMoves())) {
			return myMoves();
		}
		if (!isEmpty(prepareFor())) {
			return prepareFor();
		}
		return tableResponses();
	});
	const [loading, setLoading] = createSignal(false);
	return (
		<SidebarTemplate header={header()} actions={[]} bodyPadding={false} loadingOverlay={loading()}>
			<Show when={body?.()}>
				<>
					<p class="body-text padding-sidebar">{body?.()}</p>
					<Spacer between={["body-text", "table"]} />
				</>
			</Show>
			<Show when={positionReport()}>
				<Show when={!isEmpty(myMoves())}>
					<Intersperse each={myMoves} separator={() => <Spacer height={16} />}>
						{(response, i) => (
							<RepertoireMovesTable
								{...{
									repertoireId: activeRepertoireId()!,
									currentSide: currentSide()!,
									setLoading,
									hideHeaders: i !== 0,
									allLocalStockfish: allLocalStockfish(),
									usePeerRates,
									activeSide: activeSide()!,
									side: currentSide()!,
									responses: () => [response()],
								}}
							/>
						)}
					</Intersperse>
				</Show>
				<Show when={(isEmpty(myMoves()) && !isEmpty(otherMoves())) || !isEmpty(prepareFor())}>
					<div style={stylex()} id={`your-moves-play-${currentEpd()}`}>
						<RepertoireMovesTable
							{...{
								repertoireId: activeRepertoireId()!,
								currentSide: currentSide()!,
								setLoading,
								usePeerRates,
								allLocalStockfish: allLocalStockfish(),
								activeSide: activeSide()!,
								side: currentSide()!,
								responses: responses,
							}}
						/>
					</div>
				</Show>
				<Switch>
					<Match when={!positionReport()}>
						<div style={stylex(c.center, c.column, c.py(48))}>
							<Puff color={c.primaries[60]} />
						</div>
					</Match>
					<Match when={isEmpty(tableResponses()) && isEmpty(myMoves())}>
						<div
							style={stylex(
								c.column,
								c.alignCenter,
								c.selfCenter,
								c.px(12),
								c.maxWidth(240),
								c.py(48),
							)}
						>
							<CMText>
								<i class="fa-light fa-empty-set" style={stylex(c.fg(c.gray[50]), c.fontSize(24))} />
							</CMText>
							<Spacer height={18} />
							<CMText style={stylex(c.fg(c.gray[75]))}>
								No moves available for this position. You can still add a move by playing it on the
								board.
							</CMText>
						</div>
					</Match>
				</Switch>
				<Spacer between={["table", "actions"]} />
				<SidebarActions actions={builderActions()[0]} />
				<Show when={!isEmpty(myMoves()) && !isEmpty(otherMoves()) && mode() === "build"}>
					<div style={stylex(c.mt(18))} id={`alternate-moves-${currentEpd}`}>
						<CollapsibleSidebarSection header="Add an alternative move">
							<Spacer between={["collapsible-section", "table"]} />
							<RepertoireMovesTable
								{...{
									repertoireId: activeRepertoireId()!,
									currentSide: currentSide()!,
									setLoading,
									usePeerRates,
									allLocalStockfish: allLocalStockfish(),
									activeSide: activeSide()!,
									side: currentSide()!,
									responses: otherMoves,
								}}
							/>
							<Spacer between={["table", "actions"]} />
						</CollapsibleSidebarSection>
					</div>
				</Show>
				<Spacer height={20} />
				<SidebarActions actions={builderActions()[1]} />
			</Show>
		</SidebarTemplate>
	);
};

function getResponsesHeader(
	currentLine: string[],
	allLocalStockfish: boolean,
	myMoves: number,
	activeSide: Side,
	currentSide: Side,
	pastCoverageGoal: boolean,
	onboarding = false,
): { header: string; body?: string } {
	const hasMove = myMoves;
	if (activeSide !== currentSide) {
		let prepareForHeader: any = "Choose a move to prepare for";
		if (pastCoverageGoal) {
			prepareForHeader = "Most common responses";
		}
		if (allLocalStockfish) {
			prepareForHeader = "Best moves according to Stockfish";
		}
		return {
			header: prepareForHeader,
			body:
				onboarding && currentLine.length < 2
					? "You'll need to cover all of these eventually, but just pick one you're familiar with for now."
					: undefined,
		};
	}
	if (myMoves) {
		if (myMoves === 1) {
			return { header: "This move is in your repertoire" };
		}
		return { header: "These moves are in your repertoire" };
	}
	if (!hasMove && isEmpty(currentLine)) {
		if (onboarding) {
			return {
				header: "Let's add the first line to your repertoire",
				body: "To start with, choose the first move you play as white...",
			};
		}
		return { header: "Which first move do you play as white?" };
	}
	if (activeSide === "black" && currentLine.length === 1) {
		return {
			header: `What do you play as black against 1. ${currentLine[0]}?`,
			body:
				currentLine.length < 4 && onboarding
					? "Not sure what to play? Check the stats beside each move to help decide."
					: undefined,
		};
	}
	return {
		header: `${isEmpty(currentLine) ? "Choose your first move" : onboarding ? "What do you play against this move?" : "Choose your next move"}`,
		body:
			currentLine.length < 2 && onboarding
				? "Not sure what to play? Check the stats beside each move to help decide."
				: undefined,
	};
}

registerViewMode(RepertoireBuilder, "build");

export const getBuilderActions = (): [SidebarAction[], SidebarAction[]] => {
	const currentEpd = () => UI().getActiveChessboard().getCurrentEpd();
	const currentLine = () => UI().getActiveChessboard().getMoveLog();
	const hasPendingLineToAdd = () => BROWSING_STATE().hasPendingLineToAdd;
	const isPastCoverageGoal = () => BROWSING_STATE().isPastCoverageGoal;
	const onboarding = () => REPERTOIRE_STATE().onboarding;

	// const [onboarding] = useRepertoireState((s) => [s.onboarding]);
	const plansAction = usePlansAction();
	const addBiggestMissAction = (buttons: SidebarAction[]) => {
		const biggestGapActions = createMemo(() => useBiggestGapActions());
		const actions = biggestGapActions();
		buttons.push(...actions);
	};
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const numMovesDueFromHere = () =>
		REPERTOIRE_STATE().numMovesDueFromEpd[activeRepertoireId()!]?.[currentEpd()];

	const buttons: SidebarAction[] = [];
	const secondaryButtons: SidebarAction[] = [];
	let showTogglePlansButton = true;
	if (onboarding().isOnboarding) {
		showTogglePlansButton = false;
	}

	if (hasPendingLineToAdd()) {
		if (onboarding().isOnboarding && !isPastCoverageGoal()) {
			return [[], []] as [SidebarAction[], SidebarAction[]];
		}
		buttons.push({
			onPress: () => {
				isPastCoverageGoal()
					? trackEvent("build.save_line")
					: trackEvent("build.save_line_premature");
				quick((s) => {
					s.repertoireState.browsingState.requestToAddCurrentLine();
				});
			},
			text: isPastCoverageGoal()
				? "Save these moves to my repertoire"
				: "I'll finish this later, save my progress",
			style: isPastCoverageGoal() ? "focus" : "primary",
		});
	}

	if (!hasPendingLineToAdd()) {
		addBiggestMissAction(buttons);
	}

	if (!hasPendingLineToAdd() && numMovesDueFromHere() > 0) {
		buttons.push({
			onPress: () => {
				trackEvent("build.review_from_here");
				UI().pushView(PreReview, {
					props: {
						repertoireId: BROWSING_STATE().activeRepertoireId!,
						startEpd: currentEpd(),
						startLine: currentLine(),
					},
				});
			},
			text: "Practice moves from this position",
			right: <ReviewText date={null} numDue={numMovesDueFromHere()} />,
			style: "secondary",
		});
	}

	if (showTogglePlansButton && plansAction()) {
		buttons.push(plansAction()!);
	}

	const viewCoursesAction = useViewCoursesAction({
		epd: currentEpd(),
		repertoireId: BROWSING_STATE().activeRepertoireId!,
	})();

	if (viewCoursesAction) {
		buttons.push(viewCoursesAction);
	}

	return [buttons, secondaryButtons];
};
