import { SoundEffects } from "capacitor-sound-effects";
import { USER_STATE } from "./app_state";
import { getStaticLocal } from "./assets";
import { isIos } from "./env";
import { type FrontendSettingOption, SoundSetting } from "./frontend_settings";

export type SoundFile = {
	src: string;
	filename: string;
	sound: Sound;
};

export type Sound = "move" | "capture" | "success" | "failure" | "drop";

export const SOUNDS: Record<"move" | "capture" | "success" | "failure" | "drop", SoundFile> = {
	move: {
		sound: "move",
		src: getStaticLocal("/sounds/move.mp3"),
		filename: "move.mp3",
	},
	capture: {
		sound: "capture",
		src: getStaticLocal("/sounds/capture.mp3"),
		filename: "capture.mp3",
	},
	success: {
		sound: "success",
		src: getStaticLocal("/sounds/success.mp3"),
		filename: "success.mp3",
	},
	failure: {
		sound: "failure",
		src: getStaticLocal("/sounds/failure.mp3"),
		filename: "failure.mp3",
	},
	drop: {
		sound: "drop",
		src: getStaticLocal("/sounds/drop.mp3"),
		filename: "drop.mp3",
	},
};

class SoundPlayer {
	private context: AudioContext;
	private buffers: Map<string, AudioBuffer>;

	constructor() {
		this.context = new AudioContext();
		this.buffers = new Map();
	}

	async loadSound(soundFile: SoundFile): Promise<void> {
		const response = await fetch(soundFile.src);
		const arrayBuffer = await response.arrayBuffer();
		const audioBuffer = await this.context.decodeAudioData(arrayBuffer);
		this.buffers.set(soundFile.sound, audioBuffer);
	}

	play(src: string): void {
		const buffer = this.buffers.get(src);
		if (!buffer) {
			console.error(`Sound not loaded: ${src}`);
			return;
		}
		if (this.context.state === "suspended") {
			this.context.resume();
		}
		if (this.context.state === "suspended") {
			this.context = new AudioContext();
		}

		const source = this.context.createBufferSource();
		source.buffer = buffer;
		source.connect(this.context.destination);
		try {
			source.start();
		} catch (e) {
			console.error("error playing sound", e);
		}
	}
}

// Initialize
const player = new SoundPlayer();

// Load all sounds at startup
Object.entries(SOUNDS).map(([_sound, soundFile]) => player.loadSound(soundFile));

export const playSound = async (sounds: SoundFile, _duration?: number) => {
	const sound_setting = USER_STATE().getFrontendSetting(
		"sound",
	) as FrontendSettingOption<SoundSetting>;
	if (sound_setting.value === SoundSetting.Muted) {
		return;
	}

	if (isIos) {
		SoundEffects.playSound({ filename: sounds.filename.replace(".mp3", ".wav") });
		return;
	}
	player.play(sounds.sound);
};
