import { clsx } from "~/utils/classes";

export const TrainingStreak = (props: { streak: number }) => {
	return (
		<div class="flex row justify-center padding-sidebar items-center">
			<div class="col space-y-1  justify-center items-center py-4 md:py-7">
				<p class="text-primary text-3xl font-semibold items-center flex">
					{props.streak}
					<i
						class={clsx(
							"fas fa-fire  pl-2 text-lg",
							props.streak >= 10
								? "text-red-60"
								: props.streak >= 5
									? "text-yellow-50"
									: props.streak > 0
										? "text-blue-50"
										: "text-tertiary",
						)}
					/>
				</p>
			</div>
		</div>
	);
};
