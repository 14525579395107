
import type { Move } from "@lubert/chess.ts/dist/types";
import { filter, map, noop, sortBy, dropRight } from "lodash-es";
import type { RepertoireMove } from "~/types/RepertoireMove";
import { Side } from "~/types/Side";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import type { TableResponse } from "~/types/TableResponse";
import type { Uuid } from "~/types/Uuid";
import { type AppState, BROWSING_STATE, REPERTOIRE_STATE } from "./app_state";
import { buildTableResponses } from "./build_table_responses";
import { createChessboardInterface } from "./chessboard_interface";
import type { StateGetter, StateSetter } from "./state_setters_getters";
import type { Repertoire } from "~/types/Repertoire";
import { START_EPD } from "./chess";
import type { Line } from "~/types/Line";

export enum SidebarOnboardingImportType {
	LichessUsername = "lichess_username",
	ChesscomUsername = "chesscom_username",
	PGN = "pgn",
	PlayerTemplates = "player_templates",
}

export type AuditState = ReturnType<typeof getInitialAuditState>;

type Stack = [AuditState, AppState];
type AuditMove = { move: RepertoireMove; line: Line; repertoireId: Uuid };

export const getInitialAuditState = (
	_setDoNotUse: StateSetter<AppState, any>,
	_getDoNotUse: StateGetter<AppState, any>,
) => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _setDoNotUse((s) => fn([s.auditState, s]));
	};
	const _setOnly = <T,>(fn: (stack: AuditState) => T, _id?: string): T => {
		return _setDoNotUse((s) => fn(s.auditState));
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _getDoNotUse((s) => fn([s.auditState, s]));
	};
	const initialState = {
		chessboard: createChessboardInterface()[1],
		tableResponses: [] as TableResponse[],
		onPositionUpdate: () => {},
		getNumDifficultMoves: () => {
			return get(([s]) => {
				return s.queue.length;
			});
		},
		buildQueue: () => {
			set(([s]) => {
				let repertoires = REPERTOIRE_STATE().repertoires;
				if (!repertoires) {
					return;
				}
				// let difficultMoves = flatMap(Object.values(repertoires), (repertoire) => {
				// 	let difficultMoves = filter(flatten(values(repertoire.positionResponses)), (m) => {
				// 		return m.mine && !m.isDisabled && SpacedRepetitionStatus.isDifficult(m.srs);
				// 	});
				// 	return difficultMoves.map((m) => {
				// 		return { move: m, repertoireId: repertoire.id };
				// 	});
				// });
				let difficultMoves: AuditMove[] = [];
				map(REPERTOIRE_STATE().getRepertoires(), (repertoire: Repertoire, _u: Uuid) => {
					let allSeenEpds = new Set();
					const recurse = (
						epd: string,
						line: string[],
						lineEpds: string[],
						lastPlayedSide: Side = "black",
						lastMove?: RepertoireMove,
					) => {
						if (allSeenEpds.has(epd)) {
							return;
						}
						allSeenEpds.add(epd);
						if (
							lastMove?.mine &&
							!lastMove.isDisabled &&
							SpacedRepetitionStatus.isDifficult(lastMove.srs)
						) {
							difficultMoves.push({
								move: lastMove,
								line: dropRight(line, 1),
								repertoireId: repertoire.id,
							});
						}
						let allMoves = filter(
							repertoire.positionResponses[epd] ?? [],
							(m: RepertoireMove) => !m.isDisabled,
						) as RepertoireMove[];
						allMoves = sortBy(allMoves, (m) => -(m.incidence ?? 0));
						allMoves.map((m) => {
							return recurse(
								m.epdAfter,
								[...line, m.sanPlus],
								lineEpds.concat(m.epdAfter),
								Side.flip(lastPlayedSide),
								m,
							);
						});
					};
					recurse(START_EPD, [], [START_EPD]);
				});
				s.queue = difficultMoves;
				s.setupNextAuditMove();
			});
		},
		setupNextAuditMove: () => {
			set(([s]) => {
				if (s.activeMove) {
					s.queue.shift();
				}
				s.activeMove = s.queue[0] ?? null;
				if (s.activeMove) {
					s.chessboard.setPerspective(s.activeMove.move.side);
					s.chessboard.playLine(s.activeMove.line, { animated: true });
				}
				BROWSING_STATE().fetchNeededPositionReports();
			});
		},
		queue: [] as AuditMove[],
		activeMove: null as AuditMove | null,
		skipMove: (opts: { temporary: boolean }) => {
			set(([s]) => {
				s.setupNextAuditMove();
				if (!opts.temporary) {
					// todo: actually notify the server
				}
			});
		},
		updateTableResponses: () =>
			set(([s]) => {
				if (!s.activeMove) {
					s.tableResponses = [];
					return;
				}
				let { move, repertoireId } = s.activeMove;
				const currentSide: Side = move.side;
				const currentEpd = move.epd;
				const tableResponses = buildTableResponses({
					repertoireId,
					currentEpd,
					currentSide,
					onStockfishUpdate: () => {
						set(([s]) => {
							s.updateTableResponses();
						});
					},
				});
				s.tableResponses = tableResponses;
			}),
	};

	initialState.chessboard = createChessboardInterface()[1];
	initialState.chessboard.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([s]) => {
					s.onPositionUpdate();
				});
			},
			madeManualMove: () => {
				get(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {});
			},
			onReset: () => {
				set(([_s]) => {});
			},
			onMovePlayed: () => {
				set(([_s, _rs]) => {});
			},
			shouldMakeMove: (_move: Move) =>
				set(([_s]) => {
					return true;
				}),
		};
	});
	return initialState;
};
