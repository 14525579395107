import { formatISO } from "date-fns";
import dayjs from "dayjs";
import { filter } from "lodash-es";
import { onMount } from "solid-js";
import toast from "solid-toast";
import { ChessGame } from "~/types/ChessGame";
import type { EcoNames } from "~/types/EcoCode";
import type { OpeningReport, OpeningsReport } from "~/types/OpeningsReport";
import {
	BROWSING_STATE,
	MODEL_GAMES_STATE,
	OPENINGS_REPORT_STATE,
	REPERTOIRE_STATE,
	UI,
	quick,
} from "~/utils/app_state";
import { rspcClient } from "~/utils/rspc_client";
import { onBack } from "~/utils/signals/onBack";
import { RepertoireBuilder } from "./RepertoireBuilder";
import { ReviewText } from "./ReviewText";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { SidebarTable } from "./SidebarTable";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const OpeningReportView = (props: {
	report: OpeningReport;
	overallReport: OpeningsReport;
	eco: EcoNames;
	type: "best" | "worst";
}) => {
	const diff = () => {
		if (props.type === "best") {
			return Math.round(props.report.minStrength - props.overallReport.minStrength);
		}
		return Math.round(props.overallReport.maxStrength - props.report.maxStrength);
	};
	onMount(() => {
		quick((_s) => {
			OPENINGS_REPORT_STATE().chessboard.playLine(props.report.line, {
				animated: true,
				reset: true,
			});
			OPENINGS_REPORT_STATE().chessboard.setPerspective(props.report.side);
		});
	});
	onBack(() => {
		quick((_s) => {
			OPENINGS_REPORT_STATE().chessboard.resetPosition();
			OPENINGS_REPORT_STATE().chessboard.setPerspective("white");
		});
	});
	const numDue = () => {
		return REPERTOIRE_STATE().numMovesDueFromEpd[props.report.side][props.report.epd];
	};
	const actions = () => {
		const actions: SidebarAction[] = [];
		if (numDue() > 0) {
			actions.push({
				text: "Practice from here",
				style: "primary",
				right: <ReviewText numDue={numDue()} />,
				onPress: () => {
					quick((s) => {
						s.repertoireState.backToOverview();
						s.repertoireState.reviewState.startReview({
							repertoireId: props.report.side,
							filter: "due",
							startLine: props.report.line,
							startPosition: props.report.epd,
						});
					});
				},
			});
		}
		actions.push({
			text: "Build from here",
			style: "primary",
			onPress: () => {
				quick((_s) => {
					const line = props.report.line;
					const repertoires = filter(
						REPERTOIRE_STATE().repertoires!,
						(r) => r.side === props.report.side,
					);
					const viewInRepertoireBuilder = (repertoire) => {
						UI().clearViews();
						UI().pushView(RepertoireBuilder);
						BROWSING_STATE().setActiveRepertoire(repertoire.id);
						if (line.length > 0) {
							UI().getActiveChessboard().playLine(line);
						}
					};
					if (repertoires.length === 1) {
						viewInRepertoireBuilder(repertoires[0]);
					} else {
						UI().pushView(SidebarTemplate, {
							props: {
								header: "Select a repertoire",
								bodyPadding: true,
								children: (
									<p class="body-text">
										You have multiple {props.report.side} repertoires, which one do you want to
										view?
									</p>
								),
								actions: repertoires.map((r) => {
									return {
										onPress: () => {
											viewInRepertoireBuilder(r);
										},
										text: r.name,
										style: "primary",
									} as SidebarAction;
								}),
							},
						});
					}
				});
			},
		});
		actions.push({
			text: "Play through a model game from here",
			style: "primary",
			onPress: () => {
				quick((_s) => {
					rspcClient
						.query([
							"openings.fetchModelGame",
							{
								epd: props.report.epd,
								date: formatISO(new Date()),
								line: props.report.line,
								side: props.report.side,
							},
						])
						.then((data) => {
							const game = data.game;
							if (!game) {
								toast.error("No model game found");
								return;
							}
							MODEL_GAMES_STATE().reviewGame(ChessGame.fromDto(game!), {
								startPly: props.report.line.length,
							});
						});
				});
			},
		});
		return actions;
	};
	return (
		<SidebarTemplate header={props.eco.fullName} actions={[]}>
			<p class="padding-sidebar body-text">
				When you play this opening, you perform <b>{diff()}</b> points{" "}
				{props.type === "best" ? "above" : "below"} your average.
			</p>
			<Spacer between={["body-text", "table"]} />
			<SidebarTable
				rows={props.report.games}
				onClick={(r) => {
					window.open(r.link, "_blank");
				}}
				leftColumns={[
					{
						label: "Opponent",
						width: "auto",
						render: (game) => {
							return <div class="text-primary font-medium text-xs">{game.opponentUsername}</div>;
						},
					},
				]}
				rightColumns={[
					{
						label: "Rating",
						width: 100,
						align: "right",
						render: (game) => {
							return <div>{game.opponentElo}</div>;
						},
					},
					{
						label: "Played",
						width: 60,
						render: (game) => {
							const format = "MMM D";
							// check with dayjs whether different year
							return <div>{dayjs(game.playedAt).format(format)}</div>;
						},
					},
					{
						label: "Result",
						width: 50,
						render: (game) => {
							const color = () =>
								game.result === "Win"
									? "text-green-70"
									: game.result === "Draw"
										? "text-secondary"
										: "text-red-70";
							return <div class={color()}>{game.result}</div>;
						},
					},
				]}
			/>
			<Spacer between={["table", "actions"]} />
			<SidebarActions actions={actions()} />
		</SidebarTemplate>
	);
};
