import { quick } from "~/utils/app_state";
export const PRODUCT_CHESSBOOK_PRO_MONTHLY_IOS = "chessbook_pro.monthly";
export const PRODUCT_CHESSBOOK_PRO_ANNUAL_IOS = "chessbook_pro.annual";
import { find, some } from "lodash-es";
// export const PRODUCT_CHESSBOOK_PRO_MONTHLY_ANDROID = "chessbook-pro-monthly";
// export const PRODUCT_CHESSBOOK_PRO_ANNUAL_ANDROID = "chessbook-pro-annual";
import client from "./client";
import { isAndroid, isIos } from "./env";
import { MultiCallback } from "./multi_callback";

export const offerIsAnnual = (offer: CdvPurchase.Offer) => {
	return some(offer.pricingPhases, (pricingPhase) => {
		return pricingPhase.billingPeriod === "P1Y";
	});
};

export const RECEIPT_VERIFIED_MULTI_CALLBACK = new MultiCallback<
	(receipt: CdvPurchase.AppleAppStore.SKApplicationReceipt) => void
>();

export type InAppProductId =
	| typeof PRODUCT_CHESSBOOK_PRO_MONTHLY_IOS
	| typeof PRODUCT_CHESSBOOK_PRO_ANNUAL_IOS
	// | typeof PRODUCT_CHESSBOOK_PRO_MONTHLY_ANDROID
	// | typeof PRODUCT_CHESSBOOK_PRO_ANNUAL_ANDROID;
	| typeof PRODUCT_CHESSBOOK_PRO_ANDROID;

export const PRODUCT_CHESSBOOK_PRO_ANDROID = "chessbook_pro";

const getSubscription = (period: "monthly" | "annual"): InAppProductId => {
	if (isIos) {
		return period === "monthly"
			? PRODUCT_CHESSBOOK_PRO_MONTHLY_IOS
			: PRODUCT_CHESSBOOK_PRO_ANNUAL_IOS;
	}
	return PRODUCT_CHESSBOOK_PRO_ANDROID;
};

export const PRODUCT_CHESSBOOK_MONTHLY = getSubscription("monthly");
export const PRODUCT_CHESSBOOK_ANNUAL = getSubscription("annual");

const ALL_PRODUCTS = isAndroid
	? [PRODUCT_CHESSBOOK_PRO_ANDROID]
	: [getSubscription("monthly"), getSubscription("annual")];

export namespace InAppPurchases {
	export async function loadProducts() {
		// @ts-expect-error
		await import("cordova-plugin-purchase");

		const { store } = CdvPurchase;
		const platform = isIos ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY;

		store.register(
			isAndroid
				? [
						{
							type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
							id: "chessbook_pro",
							platform: platform,
						},
					]
				: [
						{
							type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
							id: PRODUCT_CHESSBOOK_MONTHLY,
							platform: platform,
						},
						{
							type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
							id: PRODUCT_CHESSBOOK_ANNUAL,
							platform: platform,
						},
					],
		);
		store
			.when()
			.pending((x) => {
				// biome-ignore lint/suspicious/noConsoleLog: <explanation>
				console.log("pending? ", x);
			})
			.unverified((x) => {
				// biome-ignore lint/suspicious/noConsoleLog: <explanation>
				console.log("unverified? ", x);
			})
			.productUpdated((x) => {
				// biome-ignore lint/suspicious/noConsoleLog: <explanation>
				console.log("updated? ", x);
				quick((s) => {
					s.inAppPurchaseState.products[x.id as InAppProductId] = x;
				});
			})
			.approved((x) => {
				// biome-ignore lint/suspicious/noConsoleLog: <explanation>
				console.log("approve?", x);
				const productId = x.products[0].id as InAppProductId;
				if (ALL_PRODUCTS.includes(productId)) {
					// biome-ignore lint/suspicious/noConsoleLog: <explanation>
					console.log("approved ", x);
					x.verify();
				} else {
					// biome-ignore lint/suspicious/noConsoleLog: <explanation>
					console.log("not approved ", x);
				}
			})
			.verified((x) => {
				// biome-ignore lint/suspicious/noConsoleLog: <explanation>
				console.log("verified? ", x);
				x.finish();
			})
			.finished((x) => {
				// biome-ignore lint/suspicious/noConsoleLog: <explanation>
				console.log("finished? ", x);
				if (RECEIPT_VERIFIED_MULTI_CALLBACK.hasListeners()) {
					// biome-ignore lint/suspicious/noConsoleLog: <explanation>
					console.log("Calling listeners for receipt verified", x);
					RECEIPT_VERIFIED_MULTI_CALLBACK.call(
						x.parentReceipt as CdvPurchase.AppleAppStore.SKApplicationReceipt,
					);
					return;
				}
				const productId = x.products[0].id as InAppProductId;
				if (!ALL_PRODUCTS.includes(productId)) {
					// biome-ignore lint/suspicious/noConsoleLog: <explanation>
					console.log("not sending purchase notification for this:", x);
					return;
				}
				const {
					transactionId,
					products,
					platform,
					currency,
					purchaseId,
					amountMicros,
					parentReceipt,
				} = x;
				if (isIos) {
					const originalTransactionId = findLatestOriginalTransactionId([
						parentReceipt as CdvPurchase.AppleAppStore.SKApplicationReceipt,
					]);
					client
						.post("/api/apple/purchase", {
							transactionId,
							originalTransactionId,
							products,
							platform,
							currency,
							purchaseId,
							amountMicros,
							receipt: parentReceipt,
						})
						.then(({ data }) => {
							if (data?.user) {
								quick((s) => {
									s.userState.handleAuthResponse(data);
									// set temporarily
									s.userState.user!.subscribed = true;
								});
							}
						});
				} else {
					client
						.post("/api/play-store/purchased", {
							purchaseToken: purchaseId,
							transactionId,
							purchaseId,
						})
						.then(({ data }) => {
							if (data?.user) {
								quick((s) => {
									s.userState.handleAuthResponse(data);
								});
							}
						});
				}
			});

		store.initialize([
			isIos ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY,
		]);
	}
}

// ios-only
export const findLatestOriginalTransactionId = (
	reciepts: CdvPurchase.AppleAppStore.SKApplicationReceipt[],
): string | undefined => {
	const reciept = reciepts[reciepts.length - 1];
	const transactions = reciept.transactions as CdvPurchase.AppleAppStore.SKTransaction[];
	const transactionWithOriginal = find(
		transactions,
		(t: CdvPurchase.AppleAppStore.SKTransaction) => {
			return t.originalTransactionId;
		},
	) as CdvPurchase.AppleAppStore.SKTransaction | undefined;
	return transactionWithOriginal?.originalTransactionId;
};
