import { every, filter, isEmpty, isNil } from "lodash-es";
import { Match, Show, Switch, createEffect, createMemo, createSignal } from "solid-js";
import { Puff } from "solid-spinner";
import { Spacer } from "~/components/Space";
import { TableResponse } from "~/types/TableResponse";
import type { EnrichedComponent } from "~/types/View";
import { AUDIT_STATE, REPERTOIRE_STATE, UI } from "~/utils/app_state";
import { registerViewMode } from "~/utils/register_view_mode";
import { c, stylex } from "~/utils/styles";
import { shouldUsePeerRates } from "~/utils/table_scoring";
import { trackEvent } from "~/utils/trackEvent";
import { Intersperse } from "./Intersperse";
import { RepertoireMovesTable } from "./RepertoireMovesTable";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextIconRow } from "./TextIconRow";

export const RepertoireAudit: EnrichedComponent = function Responses() {
	let auditMove = () => AUDIT_STATE().activeMove;
	const currentEpd = () => auditMove()?.move.epd!;
	const activeSide = () => auditMove()?.move.side!;
	const positionReport = () =>
		REPERTOIRE_STATE().positionReports[auditMove()?.repertoireId!]?.[currentEpd()];

	const tableResponses = () => AUDIT_STATE().tableResponses;

	const usePeerRates = () => shouldUsePeerRates(positionReport());
	createEffect(() => {});
	const allLocalStockfish = () =>
		every(tableResponses(), (m) => TableResponse.getStockfishEvalSource(m) === "local") &&
		tableResponses().length > 0;
	const myMoves = createMemo(() =>
		filter(tableResponses(), (tr) => {
			return !isNil(tr.repertoireMove);
		}),
	);
	const otherMoves = createMemo(() =>
		filter(tableResponses(), (tr) => {
			return isNil(tr.repertoireMove);
		}),
	);
	const _moveLog = () =>
		UI()
			.getActiveChessboard()
			.get((v) => v).moveLog;
	// onBack(() => {
	// 	quick((_s) => {
	// 		if (!isEmpty(moveLog())) {
	// 			animateSidebar("left");
	// 			UI().getActiveChessboard().backOne();
	// 		} else {
	// 			animateSidebar("left");
	// 			UI().getActiveChessboard().resetPosition();
	// 			UI().backToEither([RepertoireOverview, PreAdd]);
	// 		}
	// 	});
	// }, "stay");
	const _responses = createMemo(() => {
		if (!isEmpty(myMoves())) {
			return myMoves();
		}
		return tableResponses();
	});
	const actions = () => {
		let actions: SidebarAction[] = [];
		actions.push({
			text: "I'm not sure, skip this move for now",
			style: "secondary",
			onPress: () => {
				trackEvent(`audit.skip_move_for_now`);
				AUDIT_STATE().skipMove({ temporary: true });
			},
		});
		return actions;
	};
	const [loading, setLoading] = createSignal(false);
	const numDifficultMoves = () => AUDIT_STATE().getNumDifficultMoves();
	return (
		<SidebarTemplate
			header={"Reviewing difficult moves"}
			actions={[]}
			bodyPadding={false}
			loadingOverlay={loading()}
			headerRight={
				<TextIconRow
					icons={[
						{
							icon: "fa fa-warning",
							text: `${numDifficultMoves()} remaining`,
							class: "text-red-50",
						},
					]}
				/>
			}
		>
			<Show when={positionReport()}>
				<Show when={!isEmpty(myMoves())}>
					<Intersperse each={myMoves} separator={() => <Spacer height={16} />}>
						{(response, i) => (
							<RepertoireMovesTable
								{...{
									header: "This move is in your repertoire",
									repertoireId: auditMove()?.repertoireId!,
									currentSide: auditMove()?.move.side!,
									setLoading,
									hideHeaders: i !== 0,
									allLocalStockfish: allLocalStockfish(),
									usePeerRates,
									activeSide: activeSide()!,
									side: activeSide()!,
									responses: () => [response()],
								}}
							/>
						)}
					</Intersperse>
				</Show>
				<Switch>
					<Match when={!positionReport()}>
						<div style={stylex(c.center, c.column, c.py(48))}>
							<Puff color={c.primaries[60]} />
						</div>
					</Match>
				</Switch>
				<Spacer between={["table", "actions"]} />
				<Show when={!isEmpty(otherMoves())}>
					<div style={stylex(c.mt(18))} id={`alternate-moves-${currentEpd}`}>
						<RepertoireMovesTable
							{...{
								header: "Change to something else?",
								repertoireId: auditMove()?.repertoireId!,
								currentSide: auditMove()?.move.side!,
								setLoading,
								usePeerRates,
								allLocalStockfish: allLocalStockfish(),
								activeSide: activeSide()!,
								side: activeSide()!,
								responses: otherMoves,
							}}
						/>
						<Spacer between={["table", "actions"]} />
					</div>
				</Show>
				<Spacer height={20} />
				<SidebarActions actions={actions()} />
			</Show>
		</SidebarTemplate>
	);
};

registerViewMode(RepertoireAudit, "audit");
