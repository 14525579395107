import { sortBy } from "lodash-es";
import { Show, createMemo } from "solid-js";
import type { CourseDetailsDTO, CourseOverviewDTO } from "~/rspc";
import { Course } from "~/types/Course";
import { Kep } from "~/types/kep";
import { renderAnnotation } from "~/utils/annotations";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import {
	type TableResponseRow,
	getMovesTableColumnsLeft,
	getMovesTableColumnsRight,
} from "~/utils/movesTableColumns";
import { pluralize } from "~/utils/pluralize";
import { registerViewMode } from "~/utils/register_view_mode";
import { onBack } from "~/utils/signals/onBack";
import { trackEvent } from "~/utils/trackEvent";
import { CourseTitle } from "./CourseTitle";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTable, type SidebarTableColumn } from "./SidebarTable";
import { Spacer } from "./Space";

export const CourseBrowser = (props: {
	course: CourseOverviewDTO;
	courseDetails: CourseDetailsDTO;
	originalEpd: string;
}) => {
	const currentEpd = () => UI().getActiveChessboard().getCurrentEpd();
	// const activeRepertoire = () => BROWSING_STATE().getActiveRepertoire();
	const _numMovesFromHere = createMemo(() => {
		return Course.countMovesFrom(props.courseDetails.responses, currentEpd()).totalMoves;
	});
	const activeSide = () => BROWSING_STATE().repertoireSide!;
	const currentLine = () =>
		UI()
			.getActiveChessboard()
			.get((v) => v).moveLog;
	const tableResponseRows = createMemo(() => {
		let moves = () => {
			let moves = props.courseDetails.responses[currentEpd()] ?? [];
			moves = sortBy(moves, (m) => {
				let incidence = props.courseDetails.epdStats[m.epdAfter]?.incidence ?? 0;
				return -incidence;
			});
			return moves;
		};
		return moves()
			.filter((m) => {
				return (props.courseDetails.responses[m.epdAfter]?.length ?? 0) > 0;
			})
			.map((m) => {
				let trr: TableResponseRow = {
					tableResponse: () => {
						return {
							side: activeSide(),
							tags: [],
							courseMove: m,
						};
					},
					courseStats: () => props.courseDetails.epdStats,
					suggestedMove: () => undefined,
					positionReport: () => undefined,
					earliestDueDate: () => undefined,
					numMovesDueFromHere: () => 0,
					side: () => activeSide(),
					line: () => currentLine(),
					epd: () => currentEpd(),
					annotation: () => {
						let annotation = REPERTOIRE_STATE().getAnnotation(
							Kep.toKey({ epd: currentEpd(), san: m.sanPlus }),
						)?.text;

						if (annotation) {
							return renderAnnotation(annotation);
						}
						return undefined;
					},
					openingName: () => BROWSING_STATE().getOpeningNameForMove(m.epdAfter) ?? undefined,
					tagsRow: () => {
						return null;
						// if (!myTurn()) {
						// 	return null;
						// }
						// return (
						// 	<div class="flex row gap-2 items-center">
						// 		<CourseAvatar course={props.course} size={20} />
						// 		<p class="font-regular text-sm text-secondary">{props.course.name}</p>
						// 	</div>
						// );
					},
					unclickable: () => false,
					isFaded: () => false,
					hideAnnotations: () => false,
				};
				return trr;
			});
	});
	onBack(() => {
		if (currentEpd() !== props.originalEpd) {
			quick((s) => {
				s.repertoireState.browsingState.chessboard.backOne();
				animateSidebar("left");
			});
		} else {
			quick((_s) => {
				UI().popView();
			});
		}
	}, "stay");
	const onHover = (move?: TableResponseRow) => {
		UI()
			.getActiveChessboard()
			.previewMove(move?.tableResponse().courseMove?.sanPlus! || null);
	};
	const myTurn = () => BROWSING_STATE().repertoireSide! === UI().getActiveChessboard().getTurn();
	const columns = () => {
		let columns: SidebarTableColumn<TableResponseRow>[] = getMovesTableColumnsRight({
			myTurn: myTurn(),
			allLocalStockfish: false,
			course: props.course,
		});
		if (!myTurn()) {
			columns.push({
				width: 65,
				align: "right",
				render: (columnProps) => {
					return (
						<>
							{pluralize(
								Course.countMovesFrom(
									props.courseDetails.responses,
									columnProps.tableResponse().courseMove!.epdAfter,
									undefined,
								).totalMoves,
								"move",
							)}
						</>
					);
				},
				label: "Covered",
			});
		}

		return columns;
	};
	const actions = () => {
		let actions: SidebarAction[] = [];
		actions.push({
			text: `Save to my repertoire`,
			style: "primary",
			// right: `${pluralize(numMovesFromHere(), "move")}`,
			onPress: () => {
				quick((_s) => {
					trackEvent("courses.saveToRepertoire.clicked", {
						courseId: props.course.id,
						courseName: props.course.name,
					});
					BROWSING_STATE().requestCourseImport({
						course: props.course,
						courseDetails: props.courseDetails,
						fromEpd: props.originalEpd,
					});
				});
			},
		});

		return actions;
	};
	const leftColumns = () => {
		let columns: SidebarTableColumn<TableResponseRow>[] = getMovesTableColumnsLeft({
			course: props.course,
			myTurn: myTurn(),
		});

		return columns;
	};
	return (
		<div>
			<div class="padding-sidebar">
				<CourseTitle course={props.course} />
			</div>
			<Spacer between={["header", "table"]} />
			<div class="">
				<div class="relative">
					<Show when={tableResponseRows().length > 0}>
						<div class="absolute left-0 top-0 flex items-center justify-center padding-sidebar">
							<p class="text-xs text-primary font-medium">
								{myTurn() ? "This is the repertoire move" : "This repertoire covers these moves"}
							</p>
						</div>
						<SidebarTable
							// isFaded={(trr) => trr.isFaded()}
							// isClickable={(trr) => !trr.unclickable()}
							rows={tableResponseRows()}
							onHover={(trr) => onHover(trr)}
							onBlur={(_trr) => onHover()}
							onClick={(trr) => {
								quick((s) => {
									animateSidebar("right");
									s.repertoireState.browsingState.chessboard.makeMove(
										trr.tableResponse().courseMove!.sanPlus,
										{
											animate: true,
											sound: "move",
										},
									);
								});
							}}
							rightColumns={columns()}
							leftColumns={leftColumns()}
							renderBelow={(_trr) => {
								// todo
								return null;
								//      (
								// 	// <Show when={trr.annotation() && isMobile() && !trr.editingAnnotation()}>
								// 	// 	<p class="text-secondary text-xs  leading-5">{trr.annotation()}</p>
								// 	// </Show>
								// );
							}}
							maxRows={null}
						/>
					</Show>
					<Show when={tableResponseRows().length === 0}>
						<p class="body-text padding-sidebar">
							You've reached the end of the repertoire. Save this line?
						</p>
					</Show>
				</div>
			</div>
			<Spacer between={["table", "actions"]} />
			<Show when={myTurn()}>
				<SidebarActions actions={actions()} />
			</Show>
		</div>
	);
};

registerViewMode(CourseBrowser, "build");
