import { MOCK_BLACK_PUZZLE, MOCK_WHITE_PUZZLE } from "~/mocks/puzzles";
import type { LichessPuzzle } from "~/types/LichessPuzzle";
import client from "~/utils/client";
import { isDevelopment } from "./env";

export interface PuzzleFetchOptions {
	ratingGte?: number;
	ratingLte?: number;
	maxPly?: number;
}

let flipper = 0;
const MOCK_RESPONSES = isDevelopment && false;

export const fetchNewPuzzle = async (args: PuzzleFetchOptions): Promise<LichessPuzzle | null> => {
	if (MOCK_RESPONSES && isDevelopment) {
		flipper++;
		if (flipper % 2 === 0) {
			return MOCK_WHITE_PUZZLE;
		}
		return MOCK_BLACK_PUZZLE;
	}
	try {
		const response = await client.post("/api/v2/tactic", {
			...args,
		});
		return response.data.tactic as LichessPuzzle;
	} catch (_error) {}
	return null;
};
