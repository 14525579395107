import type { JSXElement } from "solid-js";
import { clsx } from "~/utils/classes";
import { CMText } from "./CMText";

export const SidebarHeader = (props: {
	children: JSXElement;
	right?: JSXElement;
	truncate?: boolean;
}) => {
	return (
		<div class="row justify-between items-center mt-0 lg:mt-3">
			<CMText
				class={clsx(
					"text-primary   font-semibold text-xl lg:text-2xl leading-relaxed align-bottom cursor-default",
					props.truncate && "truncate",
				)}
			>
				{props.children}
			</CMText>
			{props.right}
		</div>
	);
};
