import { ALL_ELO_RANGES } from "~/constants";
import { EloRange, RatingSource } from "~/types/EloRange";
import { REPERTOIRE_STATE, USER_STATE } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { DEFAULT_ELO_RANGE } from "~/utils/repertoire_state";
import { c, stylex } from "~/utils/styles";
import { getRecommendedMissThreshold } from "~/utils/user_state";
import { CMText } from "./CMText";
import { Pressable } from "./Pressable";
import { Dropdown } from "./SidebarOnboarding";

export const RatingSelection = () => {
	const user = () => USER_STATE().user;
	const isOnboarding = () => REPERTOIRE_STATE().onboarding.isOnboarding;
	const updateSettings = ({ range, system }: { range?: string; system?: string }) => {
		let currentUser = USER_STATE().user;
		let newRange = range ?? currentUser?.ratingRange ?? EloRange.asString(DEFAULT_ELO_RANGE);
		let newSystem = system ?? currentUser?.ratingSystem ?? RatingSource.ChessCom;
		let newEloRange = EloRange.fromSystemAndRange(newSystem, EloRange.fromString(newRange));
		let missThreshold = USER_STATE().getCurrentThreshold();
		if (isOnboarding()) {
			missThreshold = getRecommendedMissThreshold(newEloRange);
		}
		USER_STATE().updateUserRatingSettings({
			ratingRange: newRange,
			ratingSystem: newSystem,
			missThreshold: missThreshold * 100,
		});
	};
	return (
		<div style={stylex(c.row, c.alignCenter)} class={"space-x-2"}>
			<Dropdown
				title={"Rating range"}
				onSelect={(range) => {
					updateSettings({ range });
				}}
				choices={ALL_ELO_RANGES}
				choice={user()?.ratingRange}
				renderChoice={(choice, inList, onPress) => {
					const textColor = c.gray[80];
					const textStyles = stylex(c.fg(textColor), c.fontSize(14));
					const containerStyles = stylex(
						c.py(12),
						inList && c.px(16),
						c.row,
						c.clickable,
						c.justifyStart,
						c.selfStart,
						c.alignCenter,
						c.width("fit-content"),
						c.minWidth(100),
					);
					const inner = (
						<CMText
							style={stylex(textStyles, !inList && stylex(c.fullWidth))}
							class={clsx("whitespace-nowrap break-keep")}
						>
							{choice}
						</CMText>
					);
					return (
						<Pressable
							style={stylex(containerStyles)}
							onPress={(e) => {
								onPress(e);
							}}
						>
							{inner}
						</Pressable>
					);
				}}
			/>
			<div style={stylex(c.row)}>
				<Dropdown
					title={"Platform"}
					onSelect={(system: RatingSource) => {
						updateSettings({ system });
					}}
					choices={[
						RatingSource.ChessCom,
						RatingSource.Lichess,
						RatingSource.FIDE,
						RatingSource.USCF,
					]}
					choice={user()?.ratingSystem ?? RatingSource.ChessCom}
					renderChoice={(choice, inList, onPress) => {
						const textClasses = "text-gray-80 font-semibold font-sm";
						const containerClasses = clsx(
							"py-3 cursor-pointer row w-full self-start items-end w-fit-content min-w-[90px] justify-start",
							inList && "px-4",
						);
						const text =
							choice === RatingSource.Lichess
								? "Lichess"
								: choice === RatingSource.USCF
									? "USCF"
									: choice === RatingSource.FIDE
										? "FIDE"
										: choice === RatingSource.ChessCom
											? "Chess.com"
											: "";
						return (
							<Pressable class={containerClasses} onPress={onPress}>
								<CMText class={textClasses}>{text}</CMText>
							</Pressable>
						);
					}}
				/>
			</div>
		</div>
	);
};
