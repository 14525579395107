import axios, { type InternalAxiosRequestConfig } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { camelCase, isArray, isObject, transform } from "lodash-es";
import { quick } from "~/utils/app_state";
import { displayServerError } from "~/utils/displayServerError";
import { getAuthHeaders } from "./auth";
import { BASE_API_URL } from "./base_url";

const EPD_REGEX = /.*\/.*\/.*\/.*\/.*\/.*\/.*\/.*/;
const UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

// @ts-expect-error
const client: AxiosInstance = applyCaseMiddleware(
	axios.create({
		baseURL: BASE_API_URL,
	}),
	{
		preservedKeys: (input) => {
			return /^\d/.test(input);
		},
		caseFunctions: {
			camel: (s) => {
				return s;
			},
		},
	},
);

const camelCaseWithoutSome = (obj: any) => {
	return transform(obj, (acc: any, value, key: string, target) => {
		if (isArray(target)) {
		} else if (/^\d/.test(key)) {
		} else if (key.startsWith("GAME_ID_")) {
			key = key.replace("GAME_ID_", "");
		} else if (key.startsWith("EPD_")) {
			key = key.replace("EPD_", "");
		} else if (EPD_REGEX.test(key)) {
		} else if (UUID_REGEX.test(key)) {
		} else {
			key = camelCase(key);
		}

		if (key === "refutations") {
			acc[key] = value;
		} else {
			acc[key] = isObject(value) ? camelCaseWithoutSome(value) : value;
		}
	});
};

client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	// console.log({ url: config.url });
	if (config.url?.includes("lichess.org") && !config.url?.includes("oauth")) {
		return config;
	}
	config.headers = { ...config.headers, ...getAuthHeaders() };
	return config;
});

client.interceptors.response.use(
	(response) => {
		try {
			if (typeof response.data === "string") {
				return response;
			}
			response.data = camelCaseWithoutSome(response.data);
		} catch (e) {
			console.error("error thing", e);
		}
		return response;
	},
	(error) => {
		console.error("error thing", error);
		if (error?.response?.status === 401) {
			quick((s) => {
				s.userState.logout();
				window.location.href = `${window.location.origin}/login`;
			});
		} else {
			displayServerError(error);
		}
		return Promise.reject(error);
	},
);

export default client;
