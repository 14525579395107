import { takeWhile } from "lodash-es";
import { type Accessor, Match, Show, Switch, createMemo } from "solid-js";
import type { CourseDetailsDTO, CourseOverviewDTO, ImportCourseBehavior } from "~/rspc";
import { Course } from "~/types/Course";
import { EcoCode } from "~/types/EcoCode";
import { BROWSING_STATE, REPERTOIRE_STATE, UI } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { pluralize } from "~/utils/pluralize";
import { trackEvent } from "~/utils/trackEvent";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const PreCourseImport = (props: {
	course: CourseOverviewDTO;
	courseDetails: CourseDetailsDTO;
	fromEpd: string;
}) => {
	const activeRepertoire = () => BROWSING_STATE().getActiveRepertoire();
	const totalCourseMoves = createMemo(() =>
		Course.countMovesFrom(props.courseDetails.responses, START_EPD, undefined),
	);
	const totalCourseMovesFromEntrypoint = createMemo(() =>
		Course.countMovesFrom(props.courseDetails.responses, props.fromEpd, undefined),
	);
	const entrypointOpeningName = () => {
		let positionHistory = [
			...takeWhile(
				UI()
					.getActiveChessboard()
					.get((s) => s.positionHistory),
				(p) => p !== props.fromEpd,
			),
			props.fromEpd,
		];
		const currentEcoCode = REPERTOIRE_STATE().getLastEcoCode(positionHistory);
		return currentEcoCode ? EcoCode.getAppropriateEcoName(currentEcoCode)[0] : null;
	};
	const atTopLevel = () =>
		totalCourseMovesFromEntrypoint().totalMoves === totalCourseMoves().totalMoves;
	const continueWithImport = (epd: string) => {
		let movesToAdd = Course.countMovesFrom(props.courseDetails.responses, epd, activeRepertoire());
		if (movesToAdd.existingMoves === 0) {
			BROWSING_STATE().importCourse({
				course: props.course,
				courseDetails: props.courseDetails,
				fromEpd: epd,
				importBehavior: "add",
			});
		} else {
			UI().pushView(PreCourseImportConflicts, {
				props: {
					course: props.course,
					courseDetails: props.courseDetails,
					fromEpd: epd,
				},
			});
		}
	};
	const actions = () => {
		const actions: SidebarAction[] = [];
		if (atTopLevel()) {
			actions.push({
				text: `Yes, add these moves`,
				style: "primary",
				onPress: () => {
					continueWithImport(props.fromEpd);
				},
			});
			actions.push({
				text: `No, I've changed my mind`,
				style: "primary",
				onPress: () => {
					UI().backOne();
				},
			});
		} else {
			actions.push({
				text: `The ${entrypointOpeningName()!}`,
				right: `${pluralize(totalCourseMovesFromEntrypoint().totalMoves, "move")}`,
				style: "primary",
				onPress: () => {
					continueWithImport(props.fromEpd);
				},
			});
			actions.push({
				text: `The entire pre-made repertoire`,
				right: `${pluralize(totalCourseMoves().totalMoves, "move")}`,
				style: "primary",
				onPress: () => {
					continueWithImport(START_EPD);
				},
			});
			actions.push({
				text: `Nothing, I've changed my mind`,
				style: "secondary",
				onPress: () => {
					UI().backOne();
				},
			});
		}
		return actions;
	};
	return (
		<SidebarTemplate
			header={atTopLevel() ? `Save these moves to your repertoire?` : `What do you want to add?`}
			actions={actions()}
			bodyPadding={true}
		>
			<Show when={atTopLevel()}>
				<p class="body-text">
					This will add the moves from <b>{props.course.name}</b> to your{" "}
					<b>{activeRepertoire()!.name}</b> repertoire. You can remove them again at any time.
				</p>
			</Show>
		</SidebarTemplate>
	);
};

export const PreCourseImportConflicts = (props: {
	course: CourseOverviewDTO;
	courseDetails: CourseDetailsDTO;
	fromEpd: string;
}) => {
	const activeRepertoire = () => BROWSING_STATE().getActiveRepertoire();
	const courseAdditionStats = createMemo(() => {
		return Course.countMovesFrom(props.courseDetails.responses, props.fromEpd, activeRepertoire());
	});
	const importBehaviorOptions: Accessor<ImportCourseBehavior[]> = createMemo(() => {
		// let options: ImportCourseBehavior[] = [];
		if (courseAdditionStats().existingMoves === 0) {
			return [];
		}
		if (courseAdditionStats().newMoves === 0) {
			return ["add", "replace"] as ImportCourseBehavior[];
		}
		return ["skip", "replace", "add"] as ImportCourseBehavior[];
	});
	// const countToAdd: Accessor<number> = () => {
	// 	if (importBehavior() === "add") {
	// 		return courseAdditionStats().totalMoves;
	// 	}
	// 	if (importBehavior() === "replace") {
	// 		return courseAdditionStats().totalMoves;
	// 	}
	// 	if (importBehavior() === "skip") {
	// 		return courseAdditionStats().newMoves;
	// 	}
	// 	devAssert(false, "Unknown import behavior");
	// 	return 0;
	// };
	const saveToMyRepertoire = (behavior: ImportCourseBehavior) => {
		trackEvent("courses.importCourse.clicked", {
			behavior: behavior,
			courseId: props.course.id,
			courseName: props.course.name,
		});
		BROWSING_STATE().importCourse({
			course: props.course,
			courseDetails: props.courseDetails,
			fromEpd: props.fromEpd,
			importBehavior: behavior,
		});
	};
	// const [importBehavior, setImportBehavior] = createSignal<ImportCourseBehavior>(
	// 	importBehaviorOptions()[0] ?? "skip",
	// );
	return (
		<SidebarTemplate
			header={
				courseAdditionStats().newMoves === 0
					? `Replace your existing moves?`
					: `How do you want to handle conflicts?`
			}
			actions={[]}
		>
			<Switch>
				<Match when={importBehaviorOptions().length > 1}>
					<p class="body-text padding-sidebar">
						<Show
							when={courseAdditionStats().newMoves === 0}
							fallback={
								<>
									You have some moves already, for positions that this course covers. How do you
									want to add these course moves?
								</>
							}
						>
							You already have saved moves in some of the positions covered by this pre-made
							repertoire.
						</Show>
					</p>
					<Spacer between={["body-text", "actions"]} />
					<SidebarActions
						actions={[
							{
								onPress: () => {
									saveToMyRepertoire("skip");
								},
								text: `Skip moves I've already covered`,
								style: "secondary",
							},
							{
								onPress: () => {
									saveToMyRepertoire("replace");
								},
								text: `Replace my existing moves`,
								style: "secondary",
							},
							// TODO: implement this
							// {
							//   onPress: () => {saveToMyRepertoire("add")},
							//   text: `Save as alternative moves`,
							//   style: "secondary",
							// },
						]}
					/>
				</Match>
			</Switch>
		</SidebarTemplate>
	);
};
