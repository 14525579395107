import { Badge } from "@capawesome/capacitor-badge";
import { Share } from "capacitor-share";
import dedent from "dedent-js";
import {
	set as _set,
	every,
	filter,
	find,
	findLast,
	flatMap,
	flatten,
	forEach,
	groupBy,
	isEmpty,
	isNil,
	keyBy,
	last,
	map,
	mapValues,
	max,
	min,
	minBy,
	noop,
	pickBy,
	size,
	some,
	sortBy,
	sum,
	sumBy,
	take,
	uniqBy,
	values,
	zip,
} from "lodash-es";
import { AskAboutUserSource } from "~/components/AskAboutUserSource";
import { animateSidebar } from "~/components/SidebarContainer";
import {
	ImportSuccessOnboarding,
	OnboardingComplete,
	OnboardingIntro,
	TrimRepertoireOnboarding,
} from "~/components/SidebarOnboarding";
import type { CourseOverviewDTO, MoveAnnotationDTO } from "~/rspc";
import type { Bookmark } from "~/types/Bookmark";
import { EcoCode, type EcoNames } from "~/types/EcoCode";
import type { ExternalChessSite } from "~/types/ExternalChessSite";
import type { LichessMistake as GameMistake } from "~/types/GameMistake";
import { Line } from "~/types/Line";
import type { PositionReport } from "~/types/PositionReport";
import {
	type ByRepertoireId,
	type ByRepertoireReviewId,
	Repertoire,
	type Repertoires,
} from "~/types/Repertoire";
import type { RepertoireGrade, RepertoireMiss } from "~/types/RepertoireGrade";
import type { RepertoireMove, RepertoireMoveRequestDTO } from "~/types/RepertoireMove";
import { type BySide, SIDES, Side } from "~/types/Side";
import { MasteryLevel, SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import type { Uuid } from "~/types/Uuid";
import { Kep, type KepKey } from "~/types/kep";
import client from "~/utils/client";
import { Quiz } from "~/utils/queues";
import { AUDIT_STATE, type AppState, REPERTOIRE_STATE, UI, USER_STATE, quick } from "./app_state";
import { devAssert } from "./assert";
import { getInitialBrowsingState } from "./browsing_state";
import { START_EPD } from "./chess";
import { getExpectedNumMovesBetween } from "./coverage_estimation";
import { useLineEcoCode } from "./eco_codes";
import { isDevelopment, isNative } from "./env";
import { getInitialModelGamesState } from "./model_games_state";
import { MultiCallback } from "./multi_callback";
import { getInitialOpeningsReportState } from "./opening_reports_state";
import { getMaxMovesPerSideOnFreeTier } from "./payment";
import { getInitialReviewState } from "./review_state";
import { rspcClient } from "./rspc_client";
import type { StateGetter, StateSetter } from "./state_setters_getters";
import { yieldToMain } from "./yield_to_main";

export interface LichessOauthData {
	codeVerifier: string;
	redirectUri: string;
	clientId: string;
	codeChallenge: string;
}

export const BOOKMARK_EXCLUDED_EPDS = [
	"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -",
];

export type RepertoireState = ReturnType<typeof getInitialRepertoireState>;

export type StartMistakesAnimation = (startAnimationProps: {
	initialDue: number;
	finalDue: number;
	finalEarliestDue: string | null;
	numCorrect: number;
	onEnd?: () => void;
}) => void;

export interface AddNewLineChoice {
	title: string;
	line: string;
	active?: boolean;
	incidence?: number;
}

export enum AddedLineStage {
	Initial = 0,
	AddAnother = 1,
}

export interface FetchRepertoireResponse {
	repertoires: Record<Uuid, Repertoire>;
	grades: Record<Uuid, RepertoireGrade>;
}

export const DEFAULT_ELO_RANGE = [1300, 1500] as [number, number];

type Stack = [RepertoireState, AppState];
const selector = (s: AppState): Stack => [s.repertoireState, s];

export const getInitialRepertoireState = (
	_setDoNotUse: StateSetter<AppState, any>,
	_getDoNotUse: StateGetter<AppState, any>,
) => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _setDoNotUse((s) => fn(selector(s)));
	};
	// const setOnly = <T,>(fn: (stack: RepertoireState) => T, id?: string): T => {
	// 	return _set((s) => fn(s.repertoireState));
	// };
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _getDoNotUse((s) => fn(selector(s)));
	};
	const initialState = {
		autoBookmarks: {} as ByRepertoireId<Bookmark[]>,
		onCorrectGameMovesCallbacks: new MultiCallback<StartMistakesAnimation>(),
		superRepertoires: null as BySide<Repertoire> | null,
		lichessMistakes: null as GameMistake[] | null,
		moveAnnotations: {} as Record<KepKey, Record<string | "null", MoveAnnotationDTO>>,
		courses: {} as Record<string, CourseOverviewDTO>,
		loadingMoveAnnotations: {} as Record<KepKey, boolean>,
		needsToRefetchLichessMistakes: false,
		needsNewModelGame: false,
		numMovesFromEpd: {} as ByRepertoireId<Record<string, number>>,
		numMovesNeededFromEpd: {} as ByRepertoireId<Record<string, number>>,
		movesFromEpd: {} as ByRepertoireId<Record<string, Set<Uuid>>>,
		masteryFromEpd: {} as ByRepertoireId<Record<string, number>>,
		masteryOverall: 0 as number,
		movesByMastery: {} as ByRepertoireId<Record<MasteryLevel, number>>,
		numMovesDueFromEpd: { white: {}, black: {} } as ByRepertoireReviewId<Record<string, number>>,
		startOnboarding: () => {
			set(([s]) => {
				s.onboarding.isOnboarding = true;
				s.onboarding.autoplay = true;
				UI().ensureView(OnboardingIntro);
			});
		},
		askAboutSourceOrEndOnboarding: () => {
			set(([_s]) => {
				if (Math.random() < 0.1) {
					UI().pushView(AskAboutUserSource);
				} else {
					UI().pushView(OnboardingComplete);
				}
			});
		},
		earliestReviewDueFromEpd: { white: {}, black: {} } as ByRepertoireId<Record<string, string>>,
		earliestReviewDue: null as string | null,
		totalMovesDue: null as number | null,
		expectedNumMovesFromEpd: {} as ByRepertoireId<Record<string, number>>,
		// All epds that are covered or arrived at (epd + epd after)
		epdIncidences: { white: {}, black: {} },
		deleteMoveState: {
			modalOpen: false,
			isDeletingMove: false,
		},
		ecoCodes: [] as EcoCode[],
		addedLineState: null,
		isUpdatingEloRange: false,
		repertoires: undefined as Repertoires | undefined,
		repertoiresList: [] as Repertoire[],
		browsingState: getInitialBrowsingState(_setDoNotUse, _getDoNotUse),
		modelGamesState: getInitialModelGamesState(),
		openingReportsState: getInitialOpeningsReportState(),
		reviewState: getInitialReviewState(),
		repertoireGrades: {} as Record<Uuid, RepertoireGrade>,
		hasCompletedRepertoireInitialization: undefined as boolean | undefined,
		ecoCodeLookup: {} as Record<string, EcoCode>,
		positionReports: { white: {}, black: {} } as ByRepertoireId<Record<string, PositionReport>>,
		onboarding: {
			isOnboarding: false,
			autoplay: false,
			side: "white" as Side | undefined,
		},
		currentLine: [],
		epdNodes: { white: {}, black: {} } as ByRepertoireId<Record<string, boolean>>,
		myResponsesLookup: undefined as ByRepertoireId<RepertoireMove[]> | undefined,
		numMyMoves: { white: 0, black: 0 } as ByRepertoireReviewId<number>,
		numMyEnabledMoves: { white: 0, black: 0 } as ByRepertoireReviewId<number>,
		numResponses: undefined as ByRepertoireId<number> | undefined,
		numResponsesAboveThreshold: undefined as ByRepertoireId<number> | undefined,
		divergencePosition: undefined as string | undefined,
		divergenceIndex: undefined as number | undefined,
		getLastEcoCode: (positions: string[]) => {
			return get(([s]) => {
				return last(
					filter(
						map(positions, (p) => {
							return s.ecoCodeLookup[p];
						}),
					),
				);
			});
		},
		initState: () =>
			set(([s, _gs]) => {
				s.repertoires = undefined;
				s.fetchRepertoire(true);
				s.fetchSupplementary();
				s.fetchLichessMistakes();
				s.modelGamesState.fetchDailyModelGame();
				s.modelGamesState.fetchModelGameHistory();
				s.openingReportsState.fetchOpeningsReport();
			}, "initState"),
		importIntoRepertoire: ({
			pgn,
			repertoireId,
			siteGamesSource,
			studyChapterIds,
			studyId,
		}: {
			pgn?: string;
			repertoireId?: Uuid;
			studyId?: string;
			studyChapterIds?: string[];
			siteGamesSource?: ExternalChessSite;
		}) =>
			set(async ([_s]) => {
				const resp: { data: FetchRepertoireResponse } = await client.post(
					"/api/v2/openings/import",
					{
						pgn,
						repertoireId,
						studyId,
						studyChapterIds,
						siteGamesSource,
					},
				);
				const { data } = resp;
				set(([s, gs]) => {
					s.repertoireFetched(data);
					const minimumToTrim = 10;
					const numBelowThreshold = s.getNumResponsesBelowThreshold(
						gs.repertoireState.getRepertoireThreshold(repertoireId ?? null),
						null,
					);
					if (numBelowThreshold > minimumToTrim) {
						UI().pushView(TrimRepertoireOnboarding);
					} else {
						animateSidebar("right");
						UI().clearViews();
						UI().pushView(ImportSuccessOnboarding);
					}
				});
			}),
		analyzeMoveOnLichess: (fen: string, move: string, turn: Side) =>
			set(([_s]) => {
				const bodyFormData = new FormData();
				bodyFormData.append(
					"pgn",
					`
          [Variant "From Position"]
          [FEN "${fen}"]

          ${turn === "white" ? "1." : "1..."} ${move}
        `,
				);
				const windowReference = window.open("about:blank", "_blank");
				client.post("https://lichess.org/api/import", bodyFormData).then(({ data }) => {
					const url = data.url;
					windowReference!.location = `${url}/${turn}#999`;
				});
			}),
		analyzeLineOnLichess: (line: string[], _side?: Side) =>
			set(([_s]) => {
				if (isEmpty(line)) {
					// TODO: figure out a way to open up analysis from black side
					window.open("https://lichess.org/analysis", "_blank");
					return;
				}
				const side = _side ?? Line.sideOfLastMove(line);
				window.open(`https://lichess.org/analysis/pgn/${line.join("_")}?color=${side}`, "_blank");
			}),
		onMove: () => set(([_s]) => {}, "onMove"),
		updateMove: (move: RepertoireMoveRequestDTO, repertoireId: Uuid) =>
			set(([_s]) => {
				return client
					.post("/api/v1/openings/update_move", {
						response: move,
						repertoireId: repertoireId,
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						quick((s) => {
							let keepPositionReports = new Set<string>();
							keepPositionReports.add(move.epd);
							s.repertoireState.repertoireFetched(data, { keepPositionReports });
						});
					});
			}),
		toggleMove: (move: RepertoireMove, disable: boolean, line: string[], repertoireId: Uuid) =>
			set(([_s]) => {
				return client
					.post("/api/v2/openings/toggle_move", {
						response: move,
						disable: disable,
						repertoireId: repertoireId,
						line: line,
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						quick((s) => {
							s.repertoireState.repertoireFetched(data);
						});
					});
			}),
		updateRepertoireStructures: (options: { keepPositionReports?: Set<string> } = {}) =>
			set(([s, gs]) => {
				if (!s.repertoires) {
					return;
				}
				s.repertoiresList = sortBy(
					values(s.repertoires),
					(r) => {
						return r.side === "white" ? 0 : 1;
					},
					(r) => {
						return r.createdAt;
					},
				);
				forEach(s.repertoires, (_repertoire, id: string) => {
					_set(s.earliestReviewDueFromEpd, [id as Uuid], {});
					if (options.keepPositionReports) {
						_set(
							s.positionReports,
							[id as Uuid],
							pickBy(s.positionReports[id as Uuid], (_pr, k) =>
								options.keepPositionReports!.has(k),
							),
						);
					} else {
						_set(s.positionReports, [id as Uuid], {});
					}
				});
				// combining repertoires into super repertoires for each side
				s.superRepertoires = {} as RepertoireState["superRepertoires"];
				forEach(SIDES, (side: Side) => {
					const superRepertoire: Repertoire = {
						name: "Super Repertoire",
						side: side,
						createdAt: new Date().toISOString(),
						plans: {},
						id: `${side}` as Uuid,
						eloRanges: [],
						positionResponses: {},
					};
					forEach(s.getRepertoires({ side }), (repertoire) => {
						forEach(repertoire.positionResponses, (responses: RepertoireMove[]) => {
							forEach(responses, (response) => {
								const existingResponses = superRepertoire.positionResponses[response.epd];
								const matchingResponse = find(existingResponses, (r) => r.id === response.id);
								if (!isEmpty(existingResponses) && matchingResponse) {
									matchingResponse.repertoireIds ??= [];
									matchingResponse.repertoireIds.push(repertoire.id);
								} else {
									superRepertoire.positionResponses[response.epd] ??= [];
									superRepertoire.positionResponses[response.epd].push({
										...response,
										repertoireIds: [repertoire.id],
									});
								}
							});
						});
					});
					_set(s.superRepertoires!, [side], superRepertoire);
				});
				const allEnabledMoves = flatMap(values(s.superRepertoires!), (r: Repertoire) => {
					return filter(flatten(values(r.positionResponses)), (m) => {
						return !m.isDisabled;
					});
				});
				s.earliestReviewDue =
					min(
						map(
							filter(map(allEnabledMoves, (m: RepertoireMove) => m.srs!)),
							(srs: SpacedRepetitionStatus) => srs.dueAt as string,
						) as unknown as string[],
					) ?? null;
				s.updateTotalMovesDue();
				type RecurseResult = {
					myMoves: Set<Uuid>;
					mastery: number;
				};
				map(s.getRepertoires(), (repertoire: Repertoire, _u: Uuid) => {
					const seenEpds: Set<string> = new Set();
					let moveLookup = keyBy(Repertoire.getAllEnabledRepertoireMoves(repertoire), (m) => m.id);
					const recurse = (
						epd: string,
						seenEpds: Set<string>,
						lastMove?: RepertoireMove,
					): RecurseResult => {
						if (seenEpds.has(epd)) {
							let moves: Set<Uuid> = new Set();
							if (lastMove?.mine) {
								moves.add(lastMove.id);
							}
							return { myMoves: moves, mastery: 0 };
						}
						const newSeenEpds = new Set(seenEpds);
						newSeenEpds.add(epd);
						const allMoves = (repertoire.positionResponses[epd] ?? []) as RepertoireMove[];
						let childResults = allMoves.map((m) => {
							return recurse(m.epdAfter, newSeenEpds, m);
						});

						let allMovesFromHere = new Set(
							childResults.reduce((a, c) => a.concat([...c.myMoves]), [] as Uuid[]),
						);
						let totalMastery = 0;
						let earliestDueFromHere: string | null = null;
						let dueFromHere = 0;
						let debugMovesFromHere: RepertoireMove[] = [];
						let denominatorMastery = 0;
						allMovesFromHere.forEach((moveId) => {
							let move = moveLookup[moveId];
							if (!move) {
								return 0;
							}
							let mastery = SpacedRepetitionStatus.toMasteryPercentage(move.srs) ?? 0;
							devAssert(mastery >= 0);
							totalMastery += mastery;
							denominatorMastery++;
							if (isDevelopment) {
								debugMovesFromHere.push(move);
							}
							if (!move.isDisabled) {
								if (move.srs && SpacedRepetitionStatus.isReviewDue(move.srs)) {
									dueFromHere++;
								}
								if (
									move.srs?.dueAt &&
									(earliestDueFromHere === null || earliestDueFromHere > move.srs.dueAt)
								) {
									earliestDueFromHere = move.srs!.dueAt;
								}
							}
						});

						_set(s.numMovesDueFromEpd, [repertoire.id, epd], dueFromHere);
						if (earliestDueFromHere) {
							_set(s.earliestReviewDueFromEpd, [repertoire.id, epd], earliestDueFromHere);
						}
						if (lastMove?.mine) {
							let mastery = SpacedRepetitionStatus.toMasteryPercentage(lastMove.srs) ?? 0;
							totalMastery += mastery;
							denominatorMastery++;
						}

						let numeratorMastery = totalMastery;
						let averageMastery = numeratorMastery / denominatorMastery;
						if (Number.isNaN(averageMastery)) {
							averageMastery = 0;
						}
						// if (epd === "rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR b KQkq -") {
						// 	console.log(
						// 		`Mastery for ${lastMove?.sanPlus} is ${totalMastery} from interval of ${lastMove.srs?.interval}, from ${epd} is ${averageMastery}, division: ${numeratorMastery} / ${denominatorMastery}`,
						// 		{
						// 			lastMove: logProxy(lastMove),
						// 			childResults: logProxy(childResults),
						// 			debugMovesFromHere: debugMovesFromHere.map((m) => [m.srs, m.sanPlus]),
						// 		},
						// 	);
						//
						// 	// if (numeratorMastery !== denominatorMastery) {
						// 	// 	debugger;
						// 	// }
						// }

						_set(s.masteryFromEpd, [repertoire.id, epd], averageMastery);
						if (lastMove?.mine) {
							allMovesFromHere.add(lastMove.id);
						}
						return {
							mastery: totalMastery,
							myMoves: allMovesFromHere,
						};
					};
					recurse(START_EPD, seenEpds, undefined);
				});
				(() => {
					if (!s.superRepertoires) {
						return;
					}
					let myMoves = Object.values(s.superRepertoires).flatMap((repertoire: Repertoire) =>
						Object.values(repertoire.positionResponses)
							.flat()
							.filter((response) => response.mine && !response.isDisabled),
					);
					let mastery =
						sumBy(myMoves, (m) => SpacedRepetitionStatus.toMasteryPercentage(m.srs) ?? 0) /
						myMoves.length;
					s.masteryOverall = mastery;
				})();
				type ExpectedMovesRecurseResult = {
					myMoves: Set<Uuid>;
					myNeededMoves: Set<Uuid>;
					// maxIncidence: number;
					expectedNumMoves: number;
					excludedMoves?: number;
				};
				map(s.getRepertoires(), (repertoire: Repertoire, _u: Uuid) => {
					const threshold = gs.repertoireState.getRepertoireThreshold(repertoire.id ?? null);
					const seenEpds: Set<string> = new Set();
					const side = repertoire.side;
					const recurse = (
						epd: string,
						seenEpds: Set<string>,
						lastMove?: RepertoireMove,
					): ExpectedMovesRecurseResult => {
						if (seenEpds.has(epd)) {
							let moves: Set<Uuid> = new Set();
							let neededMoves: Set<Uuid> = new Set();
							if (lastMove?.mine) {
								moves.add(lastMove.id);
								if (lastMove?.needed) {
									neededMoves.add(lastMove.id);
								}
							}
							return { myMoves: moves, myNeededMoves: moves, expectedNumMoves: 0 };
						}
						let incidence = lastMove?.incidence ?? 1;
						let expectedNumMoves = getExpectedNumMovesBetween(
							incidence,
							threshold,
							side,
							USER_STATE().getSingleElo(),
						);
						if (lastMove?.isExcluded) {
							return {
								myMoves: new Set(),
								myNeededMoves: new Set(),
								expectedNumMoves: 0,
								excludedMoves: expectedNumMoves,
							};
						}
						const newSeenEpds = new Set(seenEpds);
						newSeenEpds.add(epd);
						const allMoves = filter(
							repertoire.positionResponses[epd] ?? [],
							(m: RepertoireMove) => m.needed && !m.isDisabled,
						) as RepertoireMove[];
						// let maxIncidence = lastMove?.incidence ?? 0;
						const [mainMove, ..._others] = allMoves;
						let childResults = allMoves.map((m) => {
							return recurse(m.epdAfter, newSeenEpds, m);
						});
						// if (lastMove?.sanPlus === "e4") {
						// 	console.log("start!", {
						// 		childResults,
						// 		expectedNumMoves,
						// 		combinedChildrenProbability,
						// 	});
						// }
						if (mainMove?.mine) {
							expectedNumMoves = sum(childResults.map((r) => r.expectedNumMoves)) ?? 0;
						}
						// let maxChildIncidence = max(childResults.map((r) => r.maxIncidence)) ?? 0;
						// maxIncidence = Math.max(maxIncidence, maxChildIncidence);

						let allMovesFromHere = new Set(
							childResults.reduce((a, c) => a.concat([...c.myMoves]), [] as Uuid[]),
						);
						let allNeededMovesFromHere = new Set(
							childResults.reduce((a, c) => a.concat([...c.myNeededMoves]), [] as Uuid[]),
						);
						// childResults.forEach((r) => {
						// 	if (r.excludedMoves) {
						// 		console.log("excluding from expected num moves", r.excludedMoves, epd);
						// 		expectedNumMoves -= r.excludedMoves;
						// 	}
						// });
						// console.log("All moves from here", { allMovesFromHere, epd, childResults });
						// if (
						// 	epd === "rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR b KQkq -" &&
						// 	side === "white"
						// ) {
						// 	console.log("from this birds eye view", {
						// 		expectedNumMoves,
						// 		allMovesFromHere,
						// 		childResults,
						// 		// maxIncidence: maxIncidence,
						// 		// incidence,
						// 	});
						// }
						// if (epd === START_EPD) {
						// 	console.log("all moves from here", { allMovesFromHere, epd, childResults });
						// 	let correct: Uuid[] = map(
						// 		filter(
						// 			flatten(values(repertoire.positionResponses)),
						// 			(m: RepertoireMove) => m.mine && m.needed && !m.isDisabled,
						// 		) as RepertoireMove[],
						// 		(m: RepertoireMove) => m.id,
						// 	);
						// 	forEach(correct, (id) => {
						// 		if (!allMovesFromHere.has(id)) {
						// 			console.log("what?", id);
						// 			console.log(
						// 				filter(flatten(values(repertoire.positionResponses)), (m) => m.id === id),
						// 				repertoire.positionResponses,
						// 			);
						// 			// throw new Error("what?");
						// 		}
						// 	});
						// }
						// if (
						// 	lastMove?.sanPlus === "e5" &&
						// 	lastMove?.epd === "rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR b KQkq -" &&
						// 	side === "white"
						// ) {
						// 	console.log("end of e4!", {
						// 		expectedNumMoves,
						// 		allMovesFromHere,
						// 		// maxIncidence: maxIncidence,
						// 		// incidence,
						// 	});
						// }
						_set(s.numMovesFromEpd, [repertoire.id, epd], allMovesFromHere.size);
						_set(s.numMovesNeededFromEpd, [repertoire.id, epd], allNeededMovesFromHere.size);
						_set(s.movesFromEpd, [repertoire.id, epd], allMovesFromHere);
						_set(s.expectedNumMovesFromEpd, [repertoire.id, epd], expectedNumMoves);
						if (lastMove?.mine) {
							allMovesFromHere.add(lastMove.id);
							if (lastMove?.needed) {
								allNeededMovesFromHere.add(lastMove.id);
							}
						}
						return {
							expectedNumMoves,
							myMoves: allMovesFromHere,
							myNeededMoves: allNeededMovesFromHere,
						};
					};
					recurse(START_EPD, seenEpds, undefined);
				});
				type ExpectedFromStartRecurseResult = {
					expectedNumMoves: number;
				};
				map(s.getRepertoires(), (repertoire: Repertoire, _u: Uuid) => {
					s.movesByMastery[repertoire.id] = { new: 0, learning: 0, solid: 0, mastered: 0 };
					Repertoire.getAllEnabledRepertoireMoves(repertoire).forEach((move) => {
						if (!move.srs) {
							return;
						}
						let level = MasteryLevel.fromInterval(move.srs!.interval);
						s.movesByMastery[repertoire.id][level]++;
					});
				});
				map(s.getRepertoires(), (repertoire: Repertoire, _u: Uuid) => {
					const threshold = gs.repertoireState.getRepertoireThreshold(repertoire.id ?? null);
					let allSeenEpds = new Set();
					let grade = s.repertoireGrades[repertoire.id];
					const side = repertoire.side;
					let allKnownMoves = new Set();
					const recurse = (
						epd: string,
						lastMove?: RepertoireMove,
					): ExpectedFromStartRecurseResult => {
						if (allSeenEpds.has(epd)) {
							return { expectedNumMoves: 0 };
						}
						allSeenEpds.add(epd);
						const incidence = lastMove?.incidence ?? 1;
						const allMoves = filter(
							repertoire.positionResponses[epd] ?? [],
							(m: RepertoireMove) => m.needed && !m.isDisabled,
						) as RepertoireMove[];
						// const [mainMove, ..._others] = allMoves;
						// reminder: probability is from-here, incidence is from-start
						const combinedChildrenProbability = Math.min(
							1,
							sum(allMoves.map((m) => m.incidence ?? 0)) / incidence,
						);

						let expectedNumMoves = 0;
						let calcExpected = getExpectedNumMovesBetween(
							incidence,
							threshold,
							side,
							USER_STATE().getSingleElo(),
						);
						const movesFromHere = s.movesFromEpd[repertoire.id][epd] ?? [];
						devAssert(!isNil(movesFromHere), "movesFromHere is nil");
						const completedBranch = !grade.biggestMisses[epd];
						if (completedBranch) {
							allKnownMoves = new Set([...allKnownMoves, ...movesFromHere]);
							// let realNumMoves = s.numMovesFromEpd[repertoire.id][epd];
							// expectedNumMoves = realNumMoves;
							// console.log(
							// 	`for ${repertoire.name} ${epd} no miss, new allKnownMoves`,
							// 	allKnownMoves,
							// );
						}
						if (allMoves.length === 0) {
							// console.log(
							// 	`for ${repertoire.name} ${epd}, no moves, using calculated expected`,
							// 	calcExpected,
							// );
							expectedNumMoves = calcExpected;
						} else {
							let unknownExpected = calcExpected * (1 - combinedChildrenProbability);
							const childResults: {
								result: ReturnType<typeof recurse>;
							}[] = allMoves.map((m) => {
								return { result: recurse(m.epdAfter, m) };
							});
							if (lastMove?.mine) {
								// console.log(
								// 	`Last move was mine (${lastMove.sanPlus}), childResults`,
								// 	childResults,
								// 	`Unknown expected`,
								// 	unknownExpected,
								// 	`child probability`,
								// 	combinedChildrenProbability,
								// );
								expectedNumMoves =
									(sum(childResults.map((r) => r.result.expectedNumMoves)) ?? 0) + unknownExpected;
								// console.log(
								// 	`for ${repertoire.name} ${epd} mine, expectedNumMoves`,
								// 	expectedNumMoves,
								// 	childResults,
								// );
							} else {
								expectedNumMoves = max(childResults.map((r) => r.result.expectedNumMoves)) ?? 0;
								// console.log(
								// 	`for ${repertoire.name} ${epd} opponent's, expectedNumMoves`,
								// 	expectedNumMoves,
								// 	childResults,
								// );
							}
						}

						expectedNumMoves = Math.max(1, expectedNumMoves);

						if (completedBranch) {
							expectedNumMoves = 0;
						}
						return {
							expectedNumMoves: expectedNumMoves + (lastMove?.mine ? 1 : 0),
						};
					};
					let res = recurse(START_EPD, undefined);
					_set(
						s.expectedNumMovesFromEpd,
						[repertoire.id, START_EPD],
						res.expectedNumMoves + allKnownMoves.size,
					);
				});
				s.myResponsesLookup = mapValues(
					s.getStandardAndSuperRepertoires(),
					(repertoire: Repertoire) => {
						return flatten(Object.values(repertoire.positionResponses)).filter(
							(m: RepertoireMove) => m.mine,
						);
					},
				);
				s.epdNodes = mapValues(s.getStandardAndSuperRepertoires(), (repertoireSide: Repertoire) => {
					const nodeEpds = {};
					const allEpds = flatten(Object.values(repertoireSide.positionResponses)).flatMap((m) => [
						m.epd,
						m.epdAfter,
					]);
					allEpds.forEach((epd) => {
						nodeEpds[epd] = true;
					});
					return nodeEpds;
				});
				// Bookmarks stuff

				map(s.getRepertoires(), (repertoire: Repertoire, _u: Uuid) => {
					let allSeenEpds = new Set();
					s.autoBookmarks[repertoire.id] ??= [];
					let bookmarks = [...s.autoBookmarks[repertoire.id]];
					let baseBookmarks: Record<string, Bookmark> = {};
					let rootBookmarks: Record<string, Bookmark> = {};
					let userThreshold = USER_STATE().getCurrentThreshold();
					let minIncidence = userThreshold * 4;
					const recurse = (
						epd: string,
						line: string[],
						lineEpds: string[],
						lastPlayedSide: Side = "black",
						lastMove?: RepertoireMove,
					) => {
						if (allSeenEpds.has(epd)) {
							let bookmarksFromTransposedPosition = filter(bookmarks, (b) => {
								return b.epds.includes(epd);
							});
							if (bookmarksFromTransposedPosition.length > 0) {
								bookmarks = filter(bookmarks, (b) => {
									return !lineEpds.includes(b.epd);
								});
							}
							return;
						}
						allSeenEpds.add(epd);
						let numMovesFromHere = s.numMovesFromEpd[repertoire.id]?.[epd] ?? 0;
						let incidence = lastMove?.incidence ?? 0;
						if (lastMove) {
							if (incidence < minIncidence) {
								return;
							}
							let ecoCode = useLineEcoCode(line)();
							let bookmark: Bookmark = {
								repertoireId: repertoire.id,
								epd: epd,
								line: line,
								epds: lineEpds,
								numMovesFromHere: numMovesFromHere,
								ecoCode: ecoCode!,
								incidence: incidence,
							};
							if (
								incidence >= minIncidence &&
								lastPlayedSide === repertoire.side &&
								!BOOKMARK_EXCLUDED_EPDS.includes(epd) &&
								line.length > 1
							) {
								bookmarks = bookmarks.filter((b) => {
									let isAncestor = lineEpds.includes(b.epd);
									if (!isAncestor || b.root) {
										return true;
									}
									let stepsAway = (lineEpds.length - b.epds.length) / 2;
									let incidenceRatio = incidence / b.incidence;
									let minimumRatio = 1.1 ** stepsAway;
									// console.log("steps away", stepsAway, incidenceRatio, allowedRatio, bookmark, b);
									return incidenceRatio > minimumRatio;
								});
								if (ecoCode) {
									bookmarks.push(bookmark);
								}
							}
							if (ecoCode && !ecoCode.fullName.includes(":")) {
								baseBookmarks[ecoCode.fullName] = bookmark;
							}
							if (line.length === 1 && ecoCode) {
								rootBookmarks[line[0]] = { ...bookmark, root: true };
							}
						}
						let allMoves = filter(
							repertoire.positionResponses[epd] ?? [],
							(m: RepertoireMove) => !m.isDisabled,
						) as RepertoireMove[];
						allMoves = sortBy(allMoves, (m) => -(m.incidence ?? 0));
						allMoves.map((m) => {
							return recurse(
								m.epdAfter,
								[...line, m.sanPlus],
								lineEpds.concat(m.epdAfter),
								Side.flip(lastPlayedSide),
								m,
							);
						});
					};
					// bookmarks = sortBy(bookmarks, (b) => -(b.epds.length));
					recurse(START_EPD, [], [START_EPD]);
					bookmarks = sortBy(bookmarks, (b) => -b.incidence);
					bookmarks = uniqBy(bookmarks, (b) => b.ecoCode.fullName);
					let rootEcoNames = map(rootBookmarks, (b) => b.ecoCode.name);
					bookmarks = filter(bookmarks, (b) => {
						return !rootEcoNames.includes(b.ecoCode.name);
					});
					bookmarks = take(
						sortBy(bookmarks, (b) => -b.incidence),
						12,
					);
					bookmarks = sortBy(bookmarks, (b) => b.ecoCode.fullName);
					let groupedBookmarks = groupBy(bookmarks, (b) => b.line[0]);
					// add root bookmarks to start of each group
					forEach(groupedBookmarks, (group, line) => {
						let rootBookmark = rootBookmarks[line];
						if (rootBookmark) {
							group.unshift(rootBookmark);
						}
					});
					let bookmarkGroups = sortBy(
						Object.values(groupedBookmarks),
						(g) => -g[0].numMovesFromHere,
					);
					let seenEcoNames = new Set();
					forEach(bookmarkGroups, (group) => {
						for (let i = 0; i < group.length; i++) {
							let bookmark = group[i];
							let ecoCodeBase = bookmark.ecoCode.name;
							let others = group.filter((b) => b.ecoCode.name === ecoCodeBase);
							if (!seenEcoNames.has(ecoCodeBase) && others.length > 1) {
								if (!some(group, (b) => b.ecoCode.fullName === ecoCodeBase)) {
									let baseBookmark = baseBookmarks[ecoCodeBase];
									if (baseBookmark) {
										// add after root
										group.splice(i, 0, baseBookmark);
									}
								}
							}
							seenEcoNames.add(ecoCodeBase);
							if (others.length > 1 && bookmark.ecoCode.lastVariation) {
								bookmark.multipleInThisOpening = true;
							}
						}
					});
					// root stuff
					s.autoBookmarks[repertoire.id] = flatten(Object.values(bookmarkGroups));
				});
				s.numResponses = mapValues(s.repertoires, (repertoireSide: Repertoire) => {
					return flatten(Object.values(repertoireSide.positionResponses)).length;
				});
				s.numMyEnabledMoves = mapValues(
					s.getStandardAndSuperRepertoires(),
					(repertoireSide: Repertoire) => {
						return filter(
							flatten(Object.values(repertoireSide.positionResponses)),
							(m) => m.mine && !m.isDisabled,
						).length;
					},
				) as ByRepertoireReviewId<number>;
				s.numMyMoves = mapValues(
					s.getStandardAndSuperRepertoires(),
					(repertoireSide: Repertoire) => {
						return filter(flatten(Object.values(repertoireSide.positionResponses)), (m) => m.mine)
							.length;
					},
				) as ByRepertoireReviewId<number>;
				s.numResponsesAboveThreshold = mapValues(s.repertoires, (repertoireSide: Repertoire) => {
					let moves = flatten(Object.values(repertoireSide.positionResponses)).filter(
						(m) => m.needed && !m.isDisabled && m.mine,
					);
					return moves.length;
				});
			}, "updateRepertoireStructures"),
		getStandardAndSuperRepertoires: (): ByRepertoireReviewId<Repertoire> =>
			get(([s]) => {
				return {
					...s.repertoires,
					...s.superRepertoires,
				} as ByRepertoireReviewId<Repertoire>;
			}, "getStandardAndSuperRepertoires"),

		trimRepertoire: (threshold: number, repertoireId: Uuid) =>
			set(([_s]) => {
				return client
					.post("/api/v2/openings/trim", {
						threshold,
						repertoireIds: [repertoireId],
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						set(([s]) => {
							s.repertoireFetched(data);
						});
					})
					.finally(noop);
			}, "deleteMoveConfirmed"),
		deleteMove: (response: RepertoireMove, repertoireId: Uuid) =>
			set(([s]) => {
				s.deleteMoveState.isDeletingMove = true;
				return client
					.post("/api/v2/openings/delete_move", {
						response: response,
						repertoireId: repertoireId,
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						set(([s]) => {
							s.repertoireFetched(data);
						});
					})
					.finally(() => {
						set(([s]) => {
							s.deleteMoveState.isDeletingMove = false;
							// @ts-expect-error
							s.deleteMoveState.response = null;
							s.deleteMoveState.modalOpen = false;
							s.onRepertoireChange();
						});
					});
			}, "deleteMoveConfirmed"),
		deleteRepertoire: (repertoireId: Uuid) =>
			set(([_s]) => {
				client
					.post("/api/v2/openings/delete", {
						repertoireId,
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						set(([s]) => {
							s.repertoireFetched(data);
							s.onRepertoireChange();
						});
					});
			}, "deleteRepertoire"),
		getRepertoires: ({ side, includeSupers }: { side?: Side; includeSupers?: boolean } = {}) =>
			get(([s]) => {
				if (!s.repertoires) {
					return [];
				}
				const repertoires = includeSupers ? s.getStandardAndSuperRepertoires() : s.repertoires;
				if (side === undefined) {
					return values(repertoires);
				}
				return filter(repertoires, (r) => r.side === side);
			}),
		getDefaultRepertoire: (side: Side) =>
			set(([s]) => {
				if (!s.repertoires) {
					return;
				}
				return find(s.repertoires, (r) => r.side === side);
			}),
		exportPgn: (repertoireId: Uuid) =>
			set(([s]) => {
				const repertoire = s.repertoires?.[repertoireId]!;
				let pgn = dedent`
        [Event "${repertoire.side} Repertoire"]
        [Site "chessbook.com"]
        [Date ""]
        [Round "N/A"]
        [White "N/A"]
        [Black "N/A"]
        [Result "*"]
        `;
				pgn += "\n\n";

				const seenEpds = new Set();
				const recurse = (epd, line) => {
					const [mainMove, ...others] = sortBy(
						s.repertoires![repertoireId]?.positionResponses[epd] ?? [],
						(m) => -(m.incidence ?? 0),
					);
					// const newSeenEpds = new Set(seenEpds);
					seenEpds.add(epd);
					if (!mainMove) {
						return;
					}
					const mainLine = [...line, mainMove.sanPlus];
					pgn = `${pgn}${getLastMoveWithNumber(Line.toPgn(mainLine))} `;
					forEach(others, (variationMove) => {
						if (seenEpds.has(variationMove.epdAfter)) {
							return;
						}
						const variationLine = [...line, variationMove.sanPlus];
						pgn += "(";
						if (Line.sideOfLastMove(variationLine) === "black") {
							const n = Math.floor(variationLine.length / 2);

							pgn += `${n}... ${getLastMoveWithNumber(Line.toPgn(variationLine)).trim()} `;
						} else {
							pgn += `${getLastMoveWithNumber(Line.toPgn(variationLine))} `;
						}

						recurse(variationMove.epdAfter, variationLine);
						pgn = pgn.trim();
						pgn += ") ";
					});
					if (seenEpds.has(mainMove.epdAfter)) {
						return;
					}
					// if (!isEmpty(others) && Line.sideOfLastMove(mainLine) === "white") {
					// 	pgn += `${Line.getMoveNumber(mainLine)}... `;
					// }

					seenEpds.add(mainMove.epdAfter);
					recurse(mainMove.epdAfter, mainLine);
				};
				recurse(START_EPD, []);
				pgn = `${pgn.trim()} *`;

				const downloadLink = document.createElement("a");
				let filename = `${repertoire.name}-${new Date().toISOString()}.pgn`;

				if (isNative) {
					Share.share({
						text: pgn,
						// title: "Export PGN",
						filename: `${filename}`,
					}).catch((error) => {
						console.error("Error sharing:", error);
					});
				} else {
					const csvFile = new Blob([pgn], { type: "text" });

					const url = window.URL.createObjectURL(csvFile);
					downloadLink.download = filename;
					downloadLink.href = url;
					downloadLink.style.display = "none";
					document.body.appendChild(downloadLink);
					downloadLink.click();
				}
			}, "exportPgn"),

		uploadMoveAnnotation: ({
			epd,
			san,
			text,
			repertoireId,
		}: {
			epd: string;
			san: string;
			text: string;
			repertoireId?: Uuid | null;
		}): Promise<void> =>
			get(([_s]) => {
				return client
					.post("/api/v1/openings/move_annotation", {
						text: text,
						epd: epd,
						san,
						repertoireId,
					})
					.then(({ data: annotation }: { data: MoveAnnotationDTO }) => {
						set(([s]) => {
							if (s.browsingState.activeRepertoireId) {
								s.positionReports[s.browsingState.activeRepertoireId][epd]?.suggestedMoves?.forEach(
									(sm) => {
										if (sm.sanPlus === san) {
											sm.annotation = text;
										}
									},
								);
							}
							s.moveAnnotations[Kep.toKey({ epd, san })] ??= {};
							s.moveAnnotations[Kep.toKey({ epd, san })][repertoireId ?? "null"] = annotation;
							s.browsingState.onPositionUpdate();
						});
					});
			}),
		backToOverview: () =>
			set(([s, gs]) => {
				if (UI().mode === "review") {
					s.reviewState.stopReviewing();
				}
				gs.navigationState.push("/");
				UI().clearViews();
				s.backToStartPosition();
				s.browsingState.reset();
				s.browsingState.setActiveRepertoire(undefined);
				s.divergencePosition = undefined;
				s.divergenceIndex = undefined;
				if (s.needsToRefetchLichessMistakes) {
					s.fetchLichessMistakes();
				}
				if (s.needsNewModelGame) {
					s.modelGamesState.fetchDailyModelGame();
				}
			}),
		hasMultipleRepertoires: (side?: Side) =>
			get(([s]) => {
				if (!side) {
					return size(s.repertoires) > 2;
				}

				const count = filter(s.repertoires, (r) => r.side === side).length;
				return count > 1;
			}),
		onRepertoireUpdate: (options: { keepPositionReports?: Set<string> } = {}) =>
			set(([s, appState]) => {
				s.updateRepertoireStructures(options);
				if (appState.userState.flagEnabled("repertoire_audit")) {
					AUDIT_STATE().buildQueue();
					AUDIT_STATE().updateTableResponses();
				}
				s.browsingState.fetchNeededPositionReports();
				s.fetchNeededAnnotations();
				s.browsingState.updateRepertoireProgress();
				s.browsingState.updateTableResponses();
				s.needsToRefetchLichessMistakes = true;
				s.reviewState.invalidateSession();
				s.updateBadge();
				s.startProcessingMissEcoCodes();
			}),
		onMoveReviewed: () =>
			set(([s]) => {
				s.updateTotalMovesDue();
				s.updateBadge();
			}),
		onGameMistakeReviewed: () =>
			set(([s]) => {
				s.updateBadge();
			}),
		getAnnotation: (kepKey: KepKey, options: { repertoireId?: Uuid | null } = {}) =>
			get(([s]) => {
				const annotations = s.moveAnnotations[kepKey];
				// if (isDevelopment && !annotations && MOCK_ANNOTATIONS) {
				// 	return {
				//         epd: kepKey
				//       }
				// }
				if (!annotations) {
					return null;
				}
				if (options.repertoireId) {
					return annotations[options.repertoireId] ?? annotations.null;
				}
				return annotations.null;
			}),
		updateBadge: () =>
			set(([s]) => {
				const mistakes = s.lichessMistakes?.length ?? 0;
				const movesDue = s.totalMovesDue ?? 0;
				const total = mistakes + movesDue;
				(async () => {
					const permissions = await Badge.checkPermissions();
					if (permissions.display === "granted") {
						if (isNative) {
							Badge.set({ count: total })
								.then(() => {})
								.catch((e) => {
									console.error("set badge error", e);
								});
						} else {
							Badge.clear().catch((_e) => {});
						}
					}
				})();
			}),
		updateTotalMovesDue: () =>
			set(([s]) => {
				const now = new Date();
				const allEnabledMoves = flatMap(values(s.superRepertoires!), (r: Repertoire) => {
					return filter(flatten(values(r.positionResponses)), (m) => {
						return !m.isDisabled;
					});
				});
				const dueMoves = sumBy(allEnabledMoves, (m: RepertoireMove) => {
					let due =
						!m.isDisabled && m.srs && SpacedRepetitionStatus.isReviewDue(m.srs, now) ? 1 : 0;
					return due;
				});
				s.totalMovesDue = dueMoves;
			}),
		fetchNeededCourses: () =>
			set(([s]) => {
				let courseIds: Set<string> = new Set();

				forEach(s.getRepertoires(), (repertoire) => {
					forEach(repertoire.positionResponses, (responses: RepertoireMove[]) => {
						forEach(responses, (response) => {
							if (response.courseId) {
								courseIds.add(response.courseId);
							}
						});
					});
				});
				forEach(Object.keys(s.courses), (c) => {
					if (courseIds.has(c)) {
						courseIds.delete(c);
					}
				});
				rspcClient
					.query(["openings.courses.overviewByIds", { ids: Array.from(courseIds) }])
					.then((courses) => {
						forEach(courses, (course) => {
							s.courses[course.id] = course;
						});
					});
			}),
		fetchNeededAnnotations: () =>
			set(([s, _rs]) => {
				let keps: Kep[] = [];

				// get from daily/active game
				const games = [s.modelGamesState.dailyGame, s.modelGamesState.activeGame];

				forEach(games, (game) => {
					if (game) {
						keps = keps.concat(
							map(take(zip(game.epds, game.sans), 20), ([epd, san]) => {
								return {
									epd: epd!,
									san: san!,
								};
							}),
						);
					}
				});

				// from active review queue
				const activeQueue = s.reviewState.activeQueue;
				const currentQuizGroup = s.reviewState.currentQuizGroup;
				[...(currentQuizGroup ? [currentQuizGroup] : []), ...(activeQueue ?? [])].forEach(
					(quizGroup) => {
						Quiz.getMoves(quizGroup)?.forEach((move) => {
							keps.push({
								epd: move.epd,
								san: move.sanPlus,
							});
						});
					},
				);

				// from browsing
				const repertoire = s.browsingState.getActiveRepertoire();
				if (UI().mode === "build" && repertoire) {
					const epd = s.browsingState.chessboard.getCurrentEpd();
					const addMove = (move: RepertoireMove) => {
						keps.push({
							epd: move.epd,
							san: move.sanPlus,
						});
					};
					forEach(repertoire.positionResponses[epd], (move) => {
						addMove(move);
						forEach(repertoire.positionResponses[move.epdAfter], (move) => {
							addMove(move);
						});
					});
				}
				if (s.browsingState.activeCourse.details) {
					const moves = flatten(values(s.browsingState.activeCourse.details.responses));
					moves.forEach((move) => {
						keps.push({
							epd: move.epd,
							san: move.sanPlus,
						});
					});
				}

				// exclude fetched/loading
				keps = filter(
					keps,
					(kep) =>
						!(Kep.toKey(kep) in s.moveAnnotations) && !s.loadingMoveAnnotations[Kep.toKey(kep)],
				);

				// mark as loading
				forEach(keps, (kep) => {
					s.loadingMoveAnnotations[Kep.toKey(kep)] = true;
				});

				if (isEmpty(keps)) {
					return;
				}
				rspcClient
					.query(["openings.fetchAnnotations", { keps }])
					.then((data) => {
						set(([s, _rs]) => {
							// forEach(keps, (kep) => {
							// 	s.moveAnnotations[Kep.toKey(kep)] = null;
							// });
							data.forEach((annotation) => {
								const key = Kep.toKey({ epd: annotation.epd, san: annotation.san });
								s.moveAnnotations[key] ??= {};
								s.moveAnnotations[key][annotation.repertoireId ?? "null"] = annotation;
							});
						});
					})
					.finally(() => {
						keps.forEach((kep) => {
							delete s.loadingMoveAnnotations[Kep.toKey(kep)];
						});
					});
			}),
		onRepertoireChange: () =>
			set(([s]) => {
				s.needsNewModelGame = true;
			}),
		startProcessingMissEcoCodes: async () => {
			let s = REPERTOIRE_STATE();
			let getAllMisses = () => {
				let misses: Record<string, RepertoireMiss> = {};
				forEach(s.repertoireGrades, (grade) => {
					forEach(Object.entries(grade.biggestMisses), ([epd, miss]) => {
						misses[epd] = miss;
					});
					if (grade.biggestMiss) {
						misses[grade.biggestMiss.epd] = grade.biggestMiss;
					}
				});
				return misses;
			};
			let ecoCodesByEpd: Record<string, EcoNames | null> = {};
			for (let [epd, miss] of Object.entries(getAllMisses())) {
				if (!miss.ecoCodeName) {
					let epds = await Line.toPositions(miss.lines[0]);
					await yieldToMain();
					let ecoCode = REPERTOIRE_STATE().getLastEcoCode(epds);
					ecoCodesByEpd[epd] = ecoCode ? EcoCode.toEcoNames(ecoCode) : null;
				}
			}
			set(([_s]) => {
				let misses = getAllMisses();
				for (let [epd, miss] of Object.entries(misses)) {
					miss.ecoNames = ecoCodesByEpd[epd];
				}
			});
		},
		fetchSupplementary: () =>
			set(([_s]) => {
				return client.get("/api/v1/openings/supplementary").then(
					({
						data,
					}: {
						data: {
							ecoCodes: EcoCode[];
						};
					}) => {
						set(([s]) => {
							s.ecoCodes = data.ecoCodes;
							s.ecoCodeLookup = keyBy(s.ecoCodes, (e) => e.epd);
							const mode = UI().mode;
							if (mode === "review") {
								s.reviewState.onPositionUpdate();
							} else {
								s.browsingState.onPositionUpdate();
							}
							s.startProcessingMissEcoCodes();
						});
					},
				);
			}),
		fetchEcoCodes: () =>
			set(([_s]) => {
				client.get("/api/v1/openings/eco_codes").then(({ data }: { data: EcoCode[] }) => {
					set(([s]) => {
						s.ecoCodes = data;
						s.ecoCodeLookup = keyBy(s.ecoCodes, (e) => e.epd);
					});
				});
			}),
		getEarliestDue: (): string | null => {
			return (
				get(([s]) => {
					const earliest = filter(
						mapValues(s.earliestReviewDueFromEpd, (xs) => xs[START_EPD]),
						(x) => !isNil(x),
					);
					return minBy(earliest, (x) => x);
				}) ?? null
			);
		},
		getTotalDue: () => {
			return get(([s]) => {
				return s.totalMovesDue ?? 0;
			});
		},
		getChessboard: () => {
			return get(([_s]) => {
				return UI().getActiveChessboard();
			});
		},
		repertoireFetched: (
			repertoireResponse: FetchRepertoireResponse,
			options: { keepPositionReports?: Set<string> } = {},
		) =>
			set(([s, appState]) => {
				s.repertoires = repertoireResponse.repertoires;
				s.repertoireGrades = repertoireResponse.grades;
				s.hasCompletedRepertoireInitialization = true;
				s.fetchNeededCourses();
				s.onRepertoireUpdate(options);
				if (!s.getIsRepertoireEmpty()) {
					appState.userState.pastLandingPage = true;
				}
				if (
					s.browsingState.activeRepertoireId &&
					!repertoireResponse.repertoires[s.browsingState.activeRepertoireId]
				) {
					s.backToOverview();
				}
			}),
		fetchRepertoire: (_initial?: boolean) =>
			set(([_s, _appState]) => {
				rspcClient.query(["openings.fetchRepertoire"]).then((data) => {
					set(([s]) => {
						s.repertoireFetched(data);
					});
				});
			}),
		getNumberOfMovesPastFreeTier: (side?: Side) =>
			get(([s]) => {
				if (!s.repertoires) {
					return 0;
				}
				let numPast = 0;
				const [allowedMoves, _typeOfAllowedMoves] = getMaxMovesPerSideOnFreeTier();
				let _numMoves = 0;
				if (side === undefined) {
					if (s.numMyMoves.white > allowedMoves) {
						numPast += s.numMyMoves.white - allowedMoves;
					}
					if (s.numMyMoves.black > allowedMoves) {
						numPast += s.numMyMoves.black - allowedMoves;
					}
				} else {
					if (s.numMyMoves[side] > allowedMoves) {
						numPast = s.numMyMoves[side] - allowedMoves;
					}
				}
				return numPast;
			}),
		pastFreeTier: (side?: Side, additionalMoves?: number) =>
			get(([s]) => {
				if (!s.repertoires) {
					return false;
				}
				const [allowedMoves, _typeOfAllowedMoves] = getMaxMovesPerSideOnFreeTier();
				let numMoves = 0;
				if (side === undefined) {
					numMoves = Math.max(s.numMyMoves.white ?? 0, s.numMyMoves.black ?? 0);
				} else {
					numMoves = s.numMyMoves?.[side] ?? 0;
				}
				return numMoves + (additionalMoves ?? 0) > allowedMoves;
			}),
		getNumResponsesBelowThreshold: (_threshold: number, repertoireId: Uuid | null) =>
			get(([s]) => {
				if (!s.repertoires) {
					return 0;
				}
				const moves = repertoireId
					? Repertoire.getAllEnabledRepertoireMoves(s.repertoires?.[repertoireId]!)
					: Repertoire.getAllEnabledRepertoiresMoves(s.repertoires);
				return filter(moves, (m) => {
					const belowThreshold = !m.needed && m.mine;
					return belowThreshold;
				}).length;
			}),
		getIsRepertoireEmpty: (id?: Uuid) =>
			get(([s]) => {
				if (!s.repertoires) {
					return true;
				}
				if (id) {
					return isEmpty(s.repertoires[id].positionResponses);
				}
				return every(
					mapValues(s.repertoires, (repertoire) => isEmpty(repertoire.positionResponses)),
				);
			}),
		backToStartPosition: () =>
			set(([s]) => {
				if (UI().mode !== "review") {
					s.browsingState.chessboard.resetPosition();
					return;
				}
			}),
		getNearestMiss: (
			reeprtoire: Repertoire,
			positionHistory: string[],
		): RepertoireMiss | null | undefined =>
			get(([s, _gs]) => {
				const currentEpd = last(positionHistory);
				return findLast(
					map(positionHistory, (epd) => {
						const miss = s.repertoireGrades[reeprtoire.id]?.biggestMisses?.[epd];
						if (miss?.epd !== currentEpd) {
							return miss;
						}
					}),
				);
			}),
		skipFirstPractice: () => {
			set((_s) => {
				client
					.post("/api/v1/openings/skip_first_practice", {})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						quick((s) => {
							s.repertoireState.repertoireFetched(data);
						});
					})
					.catch((_err) => {});
			});
		},
		setRepertoireThreshold: (repertoireId: Uuid, threshold: number) => {
			return set(([s]) => {
				s.repertoires![repertoireId].coverageTarget = threshold;
				return client
					.post("/api/v1/openings/update", {
						coverageTarget: threshold,
						repertoireId: repertoireId,
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						quick((s) => {
							s.repertoireState.repertoireFetched(data);
						});
					});
			});
		},
		getRepertoireThreshold: (repertoireId: Uuid | null) => {
			return get(([s]) => {
				let userThreshold = USER_STATE().getCurrentThreshold();
				if (!repertoireId) {
					return userThreshold;
				}
				const repertoire = s.repertoires?.[repertoireId];
				if (!repertoire) {
					return userThreshold;
				}
				return repertoire.coverageTarget ?? userThreshold;
			});
		},
		fetchLichessMistakes: () => {
			set(([s, gs]) => {
				const connected = gs.userState.user?.lichessUsername || gs.userState.user?.chesscomUsername;
				s.lichessMistakes = null;
				s.needsToRefetchLichessMistakes = false;
				if (connected) {
					client.get("/api/v2/get_lichess_mistakes").then(
						({
							data,
						}: {
							data: {
								mistakes: GameMistake[];
								repertoire: FetchRepertoireResponse;
								numCorrectMoves: number | undefined;
							};
						}) => {
							set(([s]) => {
								const { mistakes, repertoire, numCorrectMoves } = data;
								s.lichessMistakes = mistakes;
								const initialDue = s.getTotalDue();
								if (repertoire) {
									s.repertoireFetched(repertoire);
								}
								const finalDue = s.getTotalDue();
								if (numCorrectMoves) {
									const props: Parameters<StartMistakesAnimation>[0] = {
										initialDue: initialDue,
										finalDue: finalDue,
										numCorrect: numCorrectMoves,
										finalEarliestDue: s.getEarliestDue(),
									};
									s.onCorrectGameMovesCallbacks.call(props);
								}
							});
						},
					);
				}
			});
		},
	};

	return initialState;
};

function getLastMoveWithNumber(id: string) {
	const [n, m] = last(id.split(" "))!.split(".");
	if (!m) {
		return n;
	}
	return `${n}. ${m}`;
}

export function mapSides<T, Y>(bySide: BySide<T>, fn: (x: T, side: Side) => Y): BySide<Y> {
	return {
		white: fn(bySide.white, "white"),
		black: fn(bySide.black, "black"),
	};
}
export { getExpectedNumMovesBetween };
