import { last, map } from "lodash-es";

export interface EcoCode {
	epd: string;
	fullName: string;
	code: string;
	friendlyName?: string;
}
export namespace EcoCode {
	export const getAppropriateEcoName = (ecoCode: EcoCode): [string, string[]] => {
		let fullName = ecoCode.fullName;
		if (!fullName) {
			// @ts-expect-error
			return null;
		}

		if (ecoCode.friendlyName) {
			return [ecoCode.friendlyName, []];
		}
		const name = fullName.split(":")[0];
		const variations = map(fullName.split(":")?.[1]?.split(","), (s) => s.trim());
		return [name, variations];
	};

	export const getNameEcoCodeIdentifier = (ecoCode: EcoCode): string => {
		return getAppropriateEcoName(ecoCode)[0];
	};

	export const getVariationEcoCodeIdentifier = (ecoCode: EcoCode): string => {
		return getAppropriateEcoName(ecoCode)[1]?.[1];
	};

	export const toEcoNames = (ecoCode: EcoCode): EcoNames => {
		const parts = getAppropriateEcoName(ecoCode);
		return {
			ecoCode,
			lastVariation: last(parts[1]) ?? null,
			fullVariation: parts[1]?.join(", ") ?? null,
			fullName: ecoCode.fullName,
			name: ecoCode?.friendlyName ?? parts[0],
		};
	};
}

export type EcoNames = {
	name: string;
	fullName: string;
	lastVariation: string | null;
	fullVariation: string | null;
	ecoCode: EcoCode;
};
