import type { EnrichedComponent } from "~/types/View";
import { REVIEW_STATE } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { registerViewMode } from "~/utils/register_view_mode";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { SidebarActions } from "./SidebarActions";
import { Spacer } from "./Space";

export const BonusMoveView: EnrichedComponent = () => {
	return (
		<div>
			<SidebarHeader>
				<span class={clsx("text-yellow-45 font-bold padding-sidebar bonus-move")}>Bonus move</span>
			</SidebarHeader>
			<Spacer between={["header", "body-text"]} />
			<p class="body-text padding-sidebar">
				Time to think for yourself! This is a response outside your repertoire that you might see in
				a real game.
				<br />
				<br />
				Try to find a good move in this position.
			</p>
			<Spacer between={["body-text", "actions"]} />
			<SidebarActions
				actions={[
					{
						text: "Skip bonus move",
						style: "primary",
						onPress: () => {
							REVIEW_STATE().finishReview();
						},
					},
				]}
			/>
		</div>
	);
};

registerViewMode(BonusMoveView, "review");
