import { createElementBounds } from "@solid-primitives/bounds";
import { includes } from "lodash-es";
import {
	type ComponentProps,
	type JSX,
	type JSXElement,
	Show,
	children,
	createEffect,
	createSignal,
} from "solid-js";
import { Spacer } from "~/components/Space";
import { ChessboardView } from "~/components/chessboard/Chessboard";
import { APP_STATE, UI, quick, useMode } from "~/utils/app_state";
import type { ChessboardInterface } from "~/utils/chessboard_interface";
import { clsx, heightAboveChessboardClasses } from "~/utils/classes";
import { isChessmadra, isNative } from "~/utils/env";
import { chessboardFrozen } from "~/utils/freeze_chessboard";
import { onKeyPress } from "~/utils/signals/onKeyPress";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { BP, useResponsiveV2 } from "~/utils/useResponsive";
import { CMText } from "./CMText";
import { FadeInOut } from "./FadeInOut";
import { Intersperse } from "./Intersperse";
import { ModelGameHistory } from "./ModelGameHistory";
import { OpeningsReportView } from "./OpeningsReportView";
import { PreReview } from "./PreReview";
import { Pressable } from "./Pressable";
import { RepertoirePageLayout } from "./RepertoirePageLayout";
import { SidebarContainer, animateSidebar } from "./SidebarContainer";

export const VERTICAL_BREAKPOINT = BP.md;

export const SidebarLayout = (props: {
	shared?: boolean;
	chessboardView?: JSX.Element;
	breadcrumbs: JSXElement;
	sidebarContent: JSXElement;
	belowChessboard: JSXElement;
	chessboardInterface?: ChessboardInterface;
	backSection: JSXElement;
	settings: JSXElement;
	loading: boolean;
}) => {
	const mode = useMode();
	const activeTool = () => APP_STATE().trainersState.getActiveTool();

	onKeyPress((key, event) => {
		if (mode() !== "build") {
			return;
		}
		switch (key) {
			case "ArrowLeft":
				event.preventDefault();
				quick((s) => s.repertoireState.getChessboard()?.backOne());
				break;
			case "ArrowRight":
				event.preventDefault();
				quick((s) => s.repertoireState.getChessboard()?.forwardOne());
				break;
			case "ArrowUp":
				break;
			case "ArrowDown":
				break;
		}
	});

	const responsive = useResponsiveV2();
	const vertical = () => responsive().bp < VERTICAL_BREAKPOINT;
	const [chessboardContainerRef, setChessboardContainerRef] = createSignal(null);
	const chessboardLayout = createElementBounds(chessboardContainerRef, {
		trackMutation: false,
	});
	const chessboardHeight = () => chessboardLayout.height;
	const chessboardHidden = () => {
		if (isChessmadra) {
			return !activeTool();
		}
		if (!vertical()) {
			return false;
		}
		if (includes(["side_overview", "overview"], mode())) {
			return true;
		}
		if (includes([OpeningsReportView, ModelGameHistory], UI().currentView()?.component)) {
			return true;
		}
		let currentView = UI().currentView();
		if (currentView?.component === PreReview) {
			let props: ComponentProps<typeof PreReview> = currentView?.props ?? {};
			if (props.startEpd) {
				return false;
			}
			return true;
		}
		return false;
	};
	createEffect(() => {
		if (isNative) {
			if (props.loading) {
				document.documentElement.style.overflow = "hidden";
			} else {
				document.documentElement.style.overflow = "auto";
			}
		}
	});
	const chessboardView = children(() => props.chessboardView);

	return (
		<RepertoirePageLayout flushTop bottom={null} fullHeight naked loading={props.loading}>
			<div
				id=""
				class="md:px-4 items-center grow"
				style={stylex(!vertical ? c.containerStyles(responsive().bp) : c.fullWidth, c.noUserSelect)}
			>
				<div
					class="grow self-stretch"
					style={stylex(
						vertical() ? c.width(c.min(600, "100%")) : c.fullWidth,
						vertical() ? c.column : c.row,
						vertical() ? c.justifyStart : c.justifyCenter,
					)}
				>
					<div
						style={stylex(
							c.column,
							!vertical() && stylex(c.grow, c.noBasis, c.flexShrink),
							vertical() && c.width("min(480px, 100%)"),
							!vertical() && c.minWidth("300px"),
							vertical() && c.grow,
							vertical() ? c.selfCenter : c.selfStretch,
						)}
						class={clsx(
							"md:max-w-[440px] lg:max-w-[440px] xl:max-w-[520px] 2xl:max-w-[650px] z-999",
						)}
					>
						{!vertical() ? (
							<div style={stylex(c.column, c.justifyEnd)} class={heightAboveChessboardClasses}>
								{props.breadcrumbs}
								<Spacer height={32} />
							</div>
						) : (
							<div class="row items-center w-full justify-between padding-sidebar py-2">
								{props.breadcrumbs}
								{props.settings}
							</div>
						)}
						<div ref={setChessboardContainerRef} class="col">
							<div
								class={clsx(
									"w-full duration-250 transition-opacity ease-in-out",
									vertical() && "self-center max-w-[480px] padding-sidebar",
									chessboardFrozen() && "pointer-events-none",
									chessboardHidden() ? "opacity-20" : "opacity-100",
								)}
							>
								<Show when={chessboardView}>{chessboardView()}</Show>
								<Show when={props.chessboardInterface} keyed>
									<ChessboardView chessboardInterface={props.chessboardInterface!} />
								</Show>
							</div>
							<Show when={props.belowChessboard}>
								<div class="row w-full justify-center pt-2 md:pt-5">{props.belowChessboard}</div>
							</Show>
							<Show when={responsive().isMobile}>
								<Spacer height={8} />
							</Show>
						</div>
						{vertical() ? (
							<div
								class={clsx("transition-mt duration-250 delay-100 ease-in-out relative z-10 grow")}
								style={stylex(
									chessboardHeight()
										? // @ts-expect-error
											c.mt(!chessboardHidden() ? 0 : -chessboardHeight() + 100)
										: c.mt(0),
									{
										"will-change": "margin-top",
									},
								)}
							>
								<SidebarContainer backSection={props.backSection} settings={props.settings}>
									{props.sidebarContent}
								</SidebarContainer>
							</div>
						) : (
							<Spacer height={60} />
						)}
					</div>
					<Show when={!vertical()}>
						<>
							<Spacer width={responsive().switch(24, [BP.lg, 36], [BP.xl, 48])} />
							<div
								id="sidebar"
								class={"max-w-[440px] lg:max-w-[500px] xl:max-w-[650px]  z-10 relative"}
								style={stylex(c.flexGrow(2), c.flexShrink, c.noBasis)}
							>
								<SidebarContainer backSection={props.backSection} settings={props.settings}>
									{props.sidebarContent}
								</SidebarContainer>
							</div>
						</>
					</Show>
				</div>
			</div>
		</RepertoirePageLayout>
	);
};

export const NavBreadcrumbs = () => {
	const responsive = useResponsiveV2();
	const breadcrumbs = () => UI().getBreadCrumbs();

	const hidden = () => breadcrumbs().length === 1;
	const mode = useMode();
	return (
		<FadeInOut
			open={!hidden()}
			class="grow-1"
			style={stylex(c.row, c.alignCenter, c.constrainWidth, {
				"mask-image": "linear-gradient(to right, black calc(100% - 24px), transparent 100%)",
			})}
		>
			<Intersperse
				separator={() => {
					return (
						<div
							style={stylex(c.mx(responsive().switch(6, [BP.lg, 8])), c.unshrinkable)}
							class="pointer-events-none"
						>
							<CMText style={stylex(c.fg(c.gray[40]))}>
								<i class="fa-light fa-angle-right" />
							</CMText>
						</div>
					);
				}}
				each={breadcrumbs}
			>
				{(breadcrumb) => (
					<Pressable
						style={stylex(breadcrumb().onPress ? c.clickable : c.unclickable)}
						class="shrink-0"
						onPress={() => {
							if (!breadcrumb().onPress) {
								return;
							}
							quick((_s) => {
								trackEvent("breadcrumbs.clicked", {
									mode,
									breadcrumb: breadcrumb().text,
								});
								animateSidebar("left");
								breadcrumb().onPress?.();
							});
						}}
					>
						<div style={stylex()} class="p-2 -m-2 group">
							<CMText
								style={stylex(c.weightBold)}
								class={clsx(
									"text-tertiary",
									breadcrumb().onPress && "group-hover:text-primary text-sm transition-colors",
								)}
							>
								{breadcrumb().text}
							</CMText>
						</div>
					</Pressable>
				)}
			</Intersperse>
		</FadeInOut>
	);
};
