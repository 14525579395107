import { trytm } from "@bdsqqq/try";
import * as Sentry from "@sentry/browser";
import { Stockfish as NativeStockfish } from "capacitor-stockfish";
import type StockfishWeb from "lila-stockfish-web";
import { getFeatures } from "./device";
import { isNative } from "./env";
import { MultiCallback } from "./multi_callback";
import { type ObjectStorage, objectStorage } from "./object_storage";

let OBJECT_STORE: ObjectStorage<Uint8Array<ArrayBufferLike>> | undefined;
let getOrSetStore = async () => {
	if (OBJECT_STORE) return OBJECT_STORE;
	let store = await objectStorage<Uint8Array>({ store: "nnuev2", db: "nnuev2--db" });
	OBJECT_STORE = store;
	return store;
};

export let STOCKFISH_LOADED = false;
let onStockfishLoadedCallbacks = new MultiCallback<(...args: any[]) => void>();

export const onStockfishLoaded = (fn: (...args: any[]) => void) => {
	if (STOCKFISH_LOADED) {
		fn();
	} else {
		onStockfishLoadedCallbacks.add(fn);
	}
};

export const sharedWasmMemory = (lo: number, hi = 32767): WebAssembly.Memory => {
	let shrink = 4; // 32767 -> 24576 -> 16384 -> 12288 -> 8192 -> 6144 -> etc
	while (true) {
		try {
			return new WebAssembly.Memory({ shared: true, initial: lo, maximum: hi });
		} catch (e) {
			if (hi <= lo || !(e instanceof RangeError)) throw e;
			hi = Math.max(lo, Math.ceil(hi - hi / shrink));
			shrink = shrink === 4 ? 3 : 4;
		}
	}
};

const getModels = (
	nnueFilenames: string[],
	store: ObjectStorage<Uint8Array<ArrayBufferLike>>,
): Promise<(Uint8Array | undefined)[]> => {
	return Promise.all(
		nnueFilenames.map(async (nnueFilename) => {
			const storedBuffer = await store?.get(nnueFilename).catch(() => undefined);

			if (storedBuffer && storedBuffer.byteLength > 128 * 1024) return storedBuffer;
			const req = new XMLHttpRequest();

			req.open("get", `/js/nnue/${nnueFilename}`, true);
			req.responseType = "arraybuffer";
			req.onprogress = (e) => {
				console.debug("nnue progress", e.loaded, e.total);
			};

			const nnueBuffer = await new Promise<Uint8Array>((resolve, reject) => {
				req.onerror = () => reject(new Error(`fetch '${nnueFilename}' failed: ${req.status}`));
				req.onload = () => {
					if (req.status / 100 === 2) resolve(new Uint8Array(req.response));
					else reject(new Error(`fetch '${nnueFilename}' failed: ${req.status}`));
				};
				req.send();
			});
			store.put(nnueFilename, nnueBuffer).catch((e) => {
				console.warn("IDB store failed", e);
				Sentry.captureException(new Error("IDB store failed", { cause: e }));
			});
			return nnueBuffer;
		}),
	);
};

export interface StockfishInterfaceType {
	loaded: boolean;
	isReady: boolean;
	loadedCallback: MultiCallback<any>;
	ready: () => void;
	setMemory: (mb: number) => void;
	setThreads: (threads: number) => void;
	uciCmd: (cmd: string) => void;
	onBestMove: (uci: string) => void;
	onInfo: (info: StockfishInfo, final?: boolean) => void;
	position: any; // Using any to avoid Chess type dependency
	running: boolean;
}

export const reloadStockfish = () => {
	StockfishInterface.uciCmd("quit");
	STOCKFISH_LOADED = false;
	stockfishModule = null;
	loadStockfish();
};

let loading = false;
let stockfishModule: StockfishWeb | null = null;
let wasmMemory: WebAssembly.Memory | null = null;
export const loadStockfishModels = (
	setUciHandle: (handler: (cmd: string) => void) => void,
	stockfishInterface: StockfishInterfaceType,
) => {
	StockfishInterface.onReady().then(() => {
		onStockfishLoadedCallbacks.callAndClear();
		if (ENABLE_STOCKFISH_CHAOS) {
			chaosMonkey();
		}
	});
	if (loading) {
		return;
	}
	loading = true;
	if (stockfishModule) {
		stockfishModule = null;
	}

	// Create a handler for UCI messages that uses the provided stockfishInterface
	const handleUciMessage = (uciMessage: string) => StockfishInterface.handleUciMessage(uciMessage);

	if (isNative) {
		window.addEventListener("stockfish", (event: any) => {
			if ("output" in event) {
				handleUciMessage(event.output);
			}
		});
		console.info("[STOCKFISH] Loading native stockfish", NativeStockfish);
		NativeStockfish.start().then(() => {
			console.info("[STOCKFISH] Native stockfish started");
			STOCKFISH_LOADED = true;
			stockfishInterface.setMemory(16);
			Promise.all([NativeStockfish.getMaxMemory(), NativeStockfish.getProcessorCount()]).then(
				([maxMemory, processorCount]) => {
					console.info("[STOCKFISH] Got max memory", maxMemory);
					console.info("[STOCKFISH] Got processor count", processorCount);
					stockfishInterface.setMemory(maxMemory.value * 0.25);
					stockfishInterface.setThreads(Math.max(processorCount.value - 2, 1));
				},
			);
			setUciHandle(() => {
				return (cmd: string) => {
					NativeStockfish.cmd({ cmd });
				};
			});
			StockfishInterface.uciCmd("isready");
			loading = false;
		});
		return;
	}
	if (
		getFeatures().includes("sharedMem") &&
		getFeatures().includes("wasm") &&
		getFeatures().includes("simd")
	) {
		(async () => {
			let store = await getOrSetStore();
			let url = "/js/sf17-79.js";
			/* @vite-ignore */
			const makeModule = await import(url);
			if (!wasmMemory) {
				wasmMemory = sharedWasmMemory(2560);
			}
			const module: StockfishWeb = await makeModule.default({
				wasmMemory: wasmMemory,
				onError: (msg: string) => {
					console.error("Error creating stockfish", msg);
					Sentry.captureException(new Error(msg));
					return Promise.reject(new Error(msg));
				},
				locateFile: (file: string) => `/js/${file}`,
			});
			stockfishModule = module;

			module.onError = (msg: string) => {
				console.error("Error creating stockfish", msg);
				if (msg.startsWith("BAD_NNUE")) {
					// if we got this from IDB, we must remove it. but wait for getModels::store.put to finish first
					const index = Math.max(0, Number(msg.slice(9)));
					const nnueFilename = module.getRecommendedNnue(index);
					setTimeout(() => {
						console.warn(
							`Corrupt NNUE file, removing ${nnueFilename} from IDB, then reloading stockfish`,
						);
						store!.remove(nnueFilename);
						reloadStockfish();
					}, 2000);
				}
			};
			let nnueFilenames: string[] = [];
			for (let i = 0; ; i++) {
				const nnueFilename = module.getRecommendedNnue(i);
				if (!nnueFilename || nnueFilenames.includes(nnueFilename)) break;
				nnueFilenames.push(nnueFilename);
			}
			let [_, error] = await trytm(
				(async () => {
					(await getModels(nnueFilenames, store)).forEach((nnueBuffer, i) => {
						module.setNnueBuffer(nnueBuffer!, i);
					});
				})(),
			);
			if (error) {
				console.error("Error loading stockfish nnue files", error);
				Sentry.captureException(new Error("Error loading stockfish nnue files", { cause: error }));
				toast.error("Error loading stockfish NNUE files");
				loading = false;
				return;
			}
			module.listen = (data: string) => handleUciMessage(data);
			STOCKFISH_LOADED = true;
			setUciHandle(() => {
				return (cmd: string) => {
					module.uci(cmd);
				};
			});
			// set threads
			let threads = navigator.hardwareConcurrency;
			stockfishInterface.setMemory(16);
			stockfishInterface.setThreads(Math.max(threads - 2, 1));
			StockfishInterface.uciCmd("isready");
			loading = false;
		})();

		return;
	}
	loading = false;
	console.warn("Can't load stockfish!");
};

import toast from "solid-toast";
import type { StockfishEval } from "~/types/StockfishEval";
import { StockfishInterface, loadStockfish } from "./stockfish";
import { ENABLE_STOCKFISH_CHAOS } from "./test_settings";

// Export the StockfishInfo type for consumers
export interface StockfishInfo {
	stockfishEval: StockfishEval;
	multipv: number;
	pvLan: string;
	nodes?: number;
	pv?: string;
}

let runningChaosMonkey = false;
function chaosMonkey() {
	if (runningChaosMonkey) {
		return;
	}
	runningChaosMonkey = true;
	const INTERVAL_MS = 5000;

	const unleashChaos = () => {
		if (STOCKFISH_LOADED) {
			console.info("Chaos Monkey: Killing Stockfish worker!");
			// Kill the Stockfish worker
			StockfishInterface.uciCmd("quit");
			// Optional: Restart it to simulate recovery
			// startStockfish();
		} else {
			console.info("Chaos Monkey: No Stockfish worker to kill.");
		}
	};

	// Start the chaos
	setInterval(unleashChaos, INTERVAL_MS);
}
