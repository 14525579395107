import { onMount } from "solid-js";
import { quick } from "~/utils/app_state";
import { UI } from "~/utils/app_state";
import { trackEvent } from "~/utils/trackEvent";
import LandingPage from "./LandingPage";
import { LoginSidebar } from "./LoginSidebar";

export default function LandingPageWrapper() {
	onMount(() => {
		trackEvent("landing_page_loaded");
	});
	return (
		<LandingPage
			{...{
				onLogin: () => {
					quick((s) => {
						// @ts-expect-error
						if (window.fbq) {
							// @ts-expect-error
							window.fbq("trackCustom", "landing_page_conversion", { source: "login" });
							// @ts-expect-error
							window.fbq("track", "StartTrial");
						}
						trackEvent("landing_page_conversion", { source: "login" });
						s.userState.pastLandingPage = true;
						UI().pushView(LoginSidebar);
					});
				},
				onClick: (source: string) => {
					quick((s) => {
						trackEvent("landing_page_conversion", { source: source });
						// @ts-expect-error
						if (window.fbq) {
							// @ts-expect-error
							window.fbq("trackCustom", "landing_page_conversion", { source: source });
							// @ts-expect-error
							window.fbq("track", "StartTrial");
						}
						// @ts-expect-error
						if (window.twq) {
							// @ts-expect-error
							twq("event", "tw-oryca-oryec", {});
						}
						trackEvent("onboarding.started");
						s.repertoireState.startOnboarding();
						s.userState.pastLandingPage = true;
					});
				},
			}}
		/>
	);
}
