import { findLast, last } from "lodash-es";
import { type Accessor, createMemo } from "solid-js";
import { EcoCode, type EcoNames } from "~/types/EcoCode";
import { Line } from "~/types/Line";
import { REPERTOIRE_STATE } from "./app_state";

export const useLineEcoCode = (line: string[]): Accessor<EcoNames | null> => {
	const ecoCodeLookup = () => REPERTOIRE_STATE().ecoCodeLookup;
	return createMemo(() => {
		const positions = Line.toPositions(Line.toPgn(line));
		const ecoCodePosition = findLast(positions, (p) => !!ecoCodeLookup()[p]);
		if (ecoCodePosition) {
			const ecoCode = ecoCodeLookup()[ecoCodePosition];
			const parts = EcoCode.getAppropriateEcoName(ecoCode);
			return {
				ecoCode,
				lastVariation: last(parts[1]) ?? null,
				fullVariation: parts[1]?.join(", ") ?? null,
				fullName: ecoCode.fullName,
				name: parts[0],
			};
		}
		return null;
	});
};
