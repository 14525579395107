import { Chess } from "@lubert/chess.ts";
import type { Move } from "@lubert/chess.ts/dist/types";
import * as Sentry from "@sentry/browser";
import { chunk, drop, sortBy } from "lodash-es";
import {
	set as _set,
	cloneDeep,
	every,
	filter,
	find,
	flatten,
	forEach,
	includes,
	isEmpty,
	isNil,
	last,
	map,
	mapValues,
	merge,
	noop,
	some,
	take,
	uniqBy,
	values,
	zip,
} from "lodash-es";
import { type JSXElement, createSignal } from "solid-js";
import { PreAdd } from "~/components/PreAdd";
import { PreCourseImport } from "~/components/PreCourseImport";
import { RepertoireBuilder } from "~/components/RepertoireBuilder";
import { RepertoireCheckInView } from "~/components/RepertoireCheckInView";
import { animateSidebar } from "~/components/SidebarContainer";
import { PracticeIntroOnboarding } from "~/components/SidebarOnboarding";
import { TargetCoverageReachedView } from "~/components/TargetCoverageReachedView";
import { UpgradeSubscriptionView } from "~/components/UpgradeSubscriptionView";
import type { CourseDetailsDTO, CourseOverviewDTO, ImportCourseBehavior } from "~/rspc";
import { THRESHOLD_OPTIONS } from "~/threshold_options";
import { Course } from "~/types/Course";
import { EcoCode } from "~/types/EcoCode";
import { Epd } from "~/types/Epd";
import { GameResultsDistribution } from "~/types/GameResults";
import type { PositionReport } from "~/types/PositionReport";
import { type ByRepertoireId, Repertoire } from "~/types/Repertoire";
import type { RepertoireMiss } from "~/types/RepertoireGrade";
import type { RepertoireMove } from "~/types/RepertoireMove";
import type { Side } from "~/types/Side";
import { StockfishEval } from "~/types/StockfishEval";
import type { StockfishReport } from "~/types/StockfishReport";
import type { TableResponse } from "~/types/TableResponse";
import type { Uuid } from "~/types/Uuid";
import { APP_STATE, AUDIT_STATE, UI, USER_STATE } from "./app_state";
import type { AppState } from "./app_state";
import { buildTableResponses } from "./build_table_responses";
import { START_EPD, genEpd, sideFromEpd } from "./chess";
import { type ChessboardInterface, createChessboardInterface } from "./chessboard_interface";
import client from "./client";
import { getCoverageProgress } from "./coverage_estimation";
import { isDevelopment } from "./env";
import { PAYMENT_ENABLED } from "./payment";
import { parsePlans } from "./plans";
import { createQuick } from "./quick";
import type { FetchRepertoireResponse, RepertoireState } from "./repertoire_state";
import { rspcClient } from "./rspc_client";
import type { StateGetter, StateSetter } from "./state_setters_getters";
import { trackEvent } from "./trackEvent";

export enum SidebarOnboardingImportType {
	LichessUsername = "lichess_username",
	ChesscomUsername = "chesscom_username",
	PGN = "pgn",
	PlayerTemplates = "player_templates",
}

export interface BrowsingState {
	// Functions
	fetchNeededPositionReports: () => void;
	updateRepertoireProgress: () => void;
	finishSidebarOnboarding: () => void;
	getIncidenceOfCurrentLine: () => number;
	getLineIncidences: () => number[];
	getNearestMiss: () => RepertoireMiss | null;
	getBiggestMiss: () => RepertoireMiss | null;
	getMissInThisLine: () => RepertoireMiss | null;
	onPositionUpdate: () => void;
	updateTableResponses: () => void;
	shouldPromptToGoDeeper: (repertoireId?: Uuid | undefined | null) => boolean;
	requestToAddCurrentLine: () => void;
	quick: (fn: (_: BrowsingState) => void) => void;
	addPendingLine: () => Promise<void>;
	getOpeningNameForMove: (epdAfter: string) => string | null;
	updatePlans: () => void;
	checkShowTargetDepthReached: () => void;
	activeCourse: {
		overview: CourseOverviewDTO | undefined;
		details: CourseDetailsDTO | undefined;
	};
	goToBuildOnboarding(): unknown;
	reset: () => void;
	getActiveRepertoire: () => Repertoire | undefined;
	setActiveRepertoire: (repertoireId: Uuid | undefined) => void;
	requestCourseImport: (opts: {
		course: CourseOverviewDTO;
		courseDetails: CourseDetailsDTO;
		fromEpd?: string;
	}) => void;
	importCourse: (opts: {
		course: CourseOverviewDTO;
		courseDetails: CourseDetailsDTO;
		importBehavior: ImportCourseBehavior;
		fromEpd?: string;
	}) => void;

	// Fields
	chessboard: ChessboardInterface;
	chessboardShown: boolean;
	repertoireProgressState: ByRepertoireId<RepertoireProgressState>;

	pendingPositionReports: Set<string>;

	// from sidebar state
	isPastCoverageGoal?: boolean;
	tableResponses: TableResponse[];
	hasAnyPendingResponses?: boolean;
	transposedState: Record<string, never>;
	showPlansState: {
		coverageReached: boolean;
		hasShown: boolean;
		autoShown: boolean;
	};
	deleteLineState: {
		move?: RepertoireMove;
	};
	addedLineState: {
		loading: boolean;
		justCompleted: boolean;
	};
	repertoireSide?: Side;
	activeRepertoireId?: Uuid;
	currentSide: Side;
	pendingResponses?: Record<string, PendingResponse>;
	hasPendingLineToAdd: boolean;
	lastEcoCode?: EcoCode;
	planSections?: (() => JSXElement)[];
}

export type PendingResponse = {
	sanPlus: string;
	epdAfter: string;
	epd: string;
	side: Side;
	mine: boolean;
};

export interface RepertoireProgressState {
	showNewProgressBar?: boolean;
	showPending?: boolean;
	completed: boolean;
	expectedDepth: number;
	showPopover: boolean;
	percentComplete: number;
	pendingMoves: number;
}

export interface BrowserLine {
	epd: string;
	pgn: string;
	ecoCode: EcoCode;
	line: string[];
	deleteMove: RepertoireMove;
}

export interface BrowserSection {
	lines: BrowserLine[];
	ecoCode: EcoCode;
}

type Stack = [BrowsingState, RepertoireState, AppState];

export const uiStateReset = () => {
	return {
		pendingPositionReports: new Set(),
		isPastCoverageGoal: false,
		tableResponses: [] as BrowsingState["tableResponses"],
		hasAnyPendingResponses: false,
		transposedState: {},
		showPlansState: {
			coverageReached: false,
			autoShown: false,
			hasShown: false,
		},
		deleteLineState: {},
		addedLineState: {
			loading: false,
			justCompleted: false,
		},
		pendingResponses: {},
		currentSide: "white",
		hasPendingLineToAdd: false,
	} as Partial<BrowsingState>;
};

export const getInitialBrowsingState = (
	_setDoNotUse: StateSetter<AppState, any>,
	_getDoNotUse: StateGetter<AppState, any>,
) => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _setDoNotUse((s) => fn([s.repertoireState.browsingState, s.repertoireState, s]));
	};
	const setOnly = <T,>(fn: (stack: BrowsingState) => T, _id?: string): T => {
		return _setDoNotUse((s) => fn(s.repertoireState.browsingState));
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _getDoNotUse((s) => fn([s.repertoireState.browsingState, s.repertoireState, s]));
	};
	const initialState = {
		...createQuick(setOnly),
		...uiStateReset(),
		chessboard: undefined as unknown as ChessboardInterface,
		activeCourse: {
			overview: undefined,
			details: undefined,
		},
		repertoireSide: "white",
		hasPendingLineToAdd: false,
		chessboardShown: false,
		plans: {},
		repertoireProgressState: {},
		reset: () => {
			set(([s]) => {
				merge(s, uiStateReset());
			});
		},
		setActiveRepertoire: (repertoireId: Uuid) =>
			set(([s]) => {
				s.activeRepertoireId = repertoireId;
				const repertoire = s.getActiveRepertoire();
				s.repertoireSide = repertoire?.side;
				s.onPositionUpdate();
				s.chessboard.set((c) => {
					c.flipped = s.repertoireSide === "black";
				});
			}),
		getActiveRepertoire: () =>
			get(([s, rs]) => {
				const activeRepertoireId = s.activeRepertoireId;
				if (!activeRepertoireId) {
					// console.log("no active repertoire id", s.activeRepertoireId);
					return;
				}
				const repertoire = rs.repertoires?.[activeRepertoireId];
				return repertoire;
			}),
		requestCourseImport: (opts: {
			course: CourseOverviewDTO;
			courseDetails: CourseDetailsDTO;
			fromEpd: string;
		}) =>
			set(([_s, _rs]) => {
				UI().pushView(PreCourseImport, {
					props: {
						course: opts.course,
						courseDetails: opts.courseDetails,
						fromEpd: opts.fromEpd,
					},
				});
			}),
		importCourse: (opts: {
			course: CourseOverviewDTO;
			courseDetails: CourseDetailsDTO;
			importBehavior: ImportCourseBehavior;
			fromEpd: string;
		}) =>
			set(([s, rs]) => {
				const subscribed = APP_STATE().userState.isSubscribed();
				let additionalMoves = Course.countMovesFrom(
					opts.courseDetails.responses,
					opts.fromEpd ?? START_EPD,
					s.getActiveRepertoire(),
				).totalMoves;

				if (!subscribed && rs.pastFreeTier(s.repertoireSide!, additionalMoves) && PAYMENT_ENABLED) {
					trackEvent("courses.saveToRepertoire.hitPaywall", {
						courseId: opts.course.id,
						courseName: opts.course.name,
					});
					UI().replaceView(UpgradeSubscriptionView, {
						props: { pastLimit: true },
					});
					return;
				}
				animateSidebar("right");
				UI().withoutAnimations(() => {
					UI().backTo(RepertoireBuilder);
				});
				const [loading, setLoading] = createSignal(true);
				UI().pushView(RepertoireCheckInView, {
					props: {
						loading: loading,
						justCompleted: () => false,
						addedFromCoursePair: {
							overview: opts.course,
							details: opts.courseDetails,
						},
					},
				});
				rspcClient
					.query([
						"openings.courses.importIntoRepertoire",
						{
							fromEpd: opts.fromEpd ?? START_EPD,
							repertoireId: s.activeRepertoireId!,
							id: opts.course.id,
							positionHistory: s.chessboard.get((s) => s.positionHistory),
							alternateBehavior: opts.importBehavior,
						},
					])
					.then((data) => {
						trackEvent("courses.importCourse.success", {
							courseId: opts.course.id,
							courseName: opts.course.name,
						});
						set(([s, rs]) => {
							rs.repertoireFetched(data);
							s.onPositionUpdate();
							rs.onRepertoireChange();
							s.activeCourse = {
								overview: undefined,
								details: undefined,
							};
							setLoading(false);
						});
					});
			}),
		goToBuildOnboarding: () =>
			set(([s, rs]) => {
				UI().clearViews();
				if (!rs.onboarding.side) {
					rs.onboarding.side = "white";
				}
				const side = rs.onboarding.side;
				const biggestMiss = find(rs.repertoireGrades, (grade) => grade.side === side)?.biggestMiss;
				if (!biggestMiss) {
					return;
				}
				const currentLine = s.chessboard.getMoveLog();
				const repertoire = rs.getDefaultRepertoire(side);
				if (!repertoire) {
					return;
				}
				UI().clearViews();
				UI().pushView(RepertoireBuilder);
				s.setActiveRepertoire(repertoire.id);
				UI().getActiveChessboard().playLine(currentLine);
			}),
		updateRepertoireProgress: () =>
			set(([s, rs]) => {
				mapValues(rs.repertoires, (repertoire) => {
					const progressState = createEmptyRepertoireProgressState();
					const expectedDepth = Repertoire.getAllEnabledRepertoireMoves(repertoire).reduce(
						(expectedDepth, move) => {
							if (move.mine) {
								return expectedDepth + (move.incidence ?? 0);
							}
							return expectedDepth;
						},
						0,
					);
					progressState.expectedDepth = expectedDepth;
					const biggestMiss = rs.repertoireGrades[repertoire.id]?.biggestMiss;
					const numMoves = rs.numMovesNeededFromEpd?.[repertoire.id][START_EPD] ?? 0;
					// console.debug(
					// 	"Number of moves in repertoire",
					// 	repertoire.name,
					// 	filter(flatten(values(repertoire.positionResponses)), (m) => m.mine).length,
					// );
					const expectedNumMoves = rs.expectedNumMovesFromEpd[repertoire.id][START_EPD];
					console.debug(
						"Number of moves filter(needed) in repertoire",
						repertoire.name,
						numMoves,
						"Expected moves",
						expectedNumMoves,
					);
					const completed = isNil(biggestMiss);
					progressState.completed = completed;
					const savedProgress = completed
						? 1
						: Math.min(0.99, getCoverageProgress(numMoves / expectedNumMoves));
					if (numMoves > 0) {
						progressState.percentComplete = Math.max(0.01, savedProgress);
					} else {
						progressState.percentComplete = savedProgress;
					}
					s.repertoireProgressState[repertoire.id] = progressState;
				});
			}),
		checkShowTargetDepthReached: () => {
			set(([s, rs]) => {
				let isFakePastCoverageGoal =
					s.chessboard.getPly() >= 5 &&
					rs.onboarding.isOnboarding &&
					USER_STATE().user?.ratingRange === "0-1000";

				if (
					(s.isPastCoverageGoal || isFakePastCoverageGoal) &&
					s.repertoireSide !== s.currentSide &&
					s.hasPendingLineToAdd &&
					(!s.showPlansState.hasShown || rs.onboarding.isOnboarding) &&
					UI().mode === "build" &&
					UI().currentView()?.component === RepertoireBuilder
				) {
					if (rs.onboarding.isOnboarding) {
						trackEvent(`${UI().mode}.coverage_reached.onboarding`);
					}
					UI().pushView(TargetCoverageReachedView, { props: { auto: true } });
					s.showPlansState.hasShown = true;
					s.showPlansState.coverageReached = true;
					s.showPlansState.autoShown = true;
				} else {
					if (
						rs.onboarding.isOnboarding &&
						rs.onboarding.autoplay &&
						s.repertoireSide !== s.currentSide
					) {
						s.updateTableResponses();
						let nextMove = s.tableResponses[0]?.suggestedMove?.sanPlus;
						if (nextMove) {
							s.chessboard.makeMove(nextMove, {
								animate: true,
								delay: 400,
								sound: "move",
							});
						}
					}
				}
				if (!s.isPastCoverageGoal) {
					s.showPlansState.hasShown = false;
				}
			});
		},
		shouldPromptToGoDeeper: (_repertoireId?: Uuid | undefined | null) => {
			return get(([s, rs]) => {
				if (!rs.repertoires) {
					return false;
				}
				if (_repertoireId === null) {
					let repertoires = Object.values(rs.repertoires!);
					return every(repertoires, (repertoire) => {
						return s.shouldPromptToGoDeeper(repertoire.id);
					});
				}
				let repertoireId = _repertoireId ?? s.activeRepertoireId;
				if (!repertoireId) {
					return false;
				}
				let biggestMiss = rs.repertoireGrades[repertoireId]?.biggestMiss;
				if (biggestMiss) {
					return false;
				}
				let threshold = rs.getRepertoireThreshold(repertoireId);
				let advancedThreshold = THRESHOLD_OPTIONS.find((t) => t.name === "Advanced")!.value;
				return threshold > advancedThreshold + Number.EPSILON;
			});
		},
		updateTableResponses: () =>
			set(([s, rs]) => {
				if (!s.activeRepertoireId) {
					s.tableResponses = [];
					return;
				}
				const currentSide: Side = s.chessboard.getTurn();
				const currentEpd = s.chessboard.getCurrentEpd();
				const ownSide = currentSide === s.repertoireSide;
				const tableResponses = buildTableResponses({
					repertoireId: s.activeRepertoireId,
					currentEpd,
					currentSide,
					onStockfishUpdate: () => {
						set(([s, _rs]) => {
							s.updateTableResponses();
						});
					},
				});
				s.tableResponses = tableResponses;
				const positionReport = rs.positionReports[s.activeRepertoireId]?.[currentEpd];
				const noneNeeded = every(tableResponses, (tr) => !tr.suggestedMove?.needed);
				if (!isNil(positionReport) && (!ownSide || (ownSide && isEmpty(tableResponses)))) {
					if (noneNeeded || isEmpty(tableResponses)) {
						s.isPastCoverageGoal = true;
					} else {
						s.isPastCoverageGoal = false;
					}
				}
				s.fetchNeededPositionReports();
			}),
		getBiggestMiss: () =>
			get(([s, rs]) => {
				if (!s.activeRepertoireId) {
					return null;
				}
				return rs.repertoireGrades[s.activeRepertoireId!]?.biggestMiss ?? null;
			}),
		getNearestMiss: (): RepertoireMiss | null | undefined =>
			get(([s, rs]) => {
				const repertoire = s.getActiveRepertoire();
				if (!repertoire) {
					return null;
				}
				return rs.getNearestMiss(
					repertoire,
					s.chessboard.get((c) => c.positionHistory),
				);
			}),
		finishSidebarOnboarding: () =>
			set(([s, _rs]) => {
				animateSidebar("right");
				s.reset();
			}),
		fetchNeededPositionReports: () =>
			set(([s, rs]) => {
				// debugger;
				let activeRepertoire = s.getActiveRepertoire();
				if (rs.onboarding.isOnboarding) {
					activeRepertoire = rs.getDefaultRepertoire("white");
				}
				const debugFriendly = isDevelopment && false;
				let requests: {
					epd: string;
					moves: string[];
					repertoireId: Uuid;
				}[] = [];
				forEach(rs.repertoires, (r) => {
					requests.push({
						epd: START_EPD,
						moves: [],
						repertoireId: r.id,
					});
				});
				if (!isNil(activeRepertoire)) {
					const moveLog: string[] = [];
					const epdLog: string[] = [];
					forEach(
						zip(s.chessboard.get((s) => s).positionHistory, s.chessboard.get((s) => s).moveLog),
						([epd, move], _i) => {
							if (move) {
								moveLog.push(move);
							}
							if (!epd) {
								return;
							}
							epdLog.push(epd);
							requests.push({
								epd: epd!,
								repertoireId: activeRepertoire.id,
								moves: [...moveLog],
							});
						},
					);
					const tableResponses = rs.browsingState.tableResponses;
					if (tableResponses && !debugFriendly) {
						// only first 5 moves since the rest are unlikely
						[
							...take(tableResponses, 5),
							...(filter(tableResponses, (tr) => tr.suggestedMove?.needed) as TableResponse[]),
						].forEach((tr) => {
							const sm = tr.suggestedMove;
							if (!sm) {
								return;
							}
							const epd = sm.epdAfter;
							let smMoveLog = [...moveLog, sm.sanPlus];
							requests.push({
								epd: epd,
								repertoireId: activeRepertoire.id,
								moves: [...moveLog, sm.sanPlus],
							});
							if (rs.onboarding.isOnboarding) {
								let positionReport = rs.positionReports[activeRepertoire.id]?.[sm.epdAfter];
								if (!positionReport) {
									return;
								}
								const topSuggestedMoves = take(
									sortBy(
										positionReport.suggestedMoves,
										(sm) => -(GameResultsDistribution.getTotalGames(sm.masterResults) ?? 0),
									),
									4,
								);
								forEach(topSuggestedMoves, (sm) => {
									requests.push({
										epd: sm.epdAfter,
										repertoireId: activeRepertoire.id,
										moves: [...smMoveLog, sm.sanPlus],
									});
								});
							}
						});
					}
				}
				if (isNil(activeRepertoire)) {
					forEach(rs.repertoires, (rep) => {
						if (rep.side !== "white") {
							return;
						}
						const startResponses = rep?.positionResponses[START_EPD];
						if (startResponses?.length === 1) {
							requests.push({
								epd: startResponses[0].epdAfter,
								moves: [startResponses[0].sanPlus],
								repertoireId: rs.getDefaultRepertoire("white")!.id,
							});
						}
					});
				}
				// get from queue of audit moves
				take([AUDIT_STATE().activeMove, ...AUDIT_STATE().queue], 3).forEach((move) => {
					if (!move) {
						return;
					}
					requests.push({
						epd: move.move.epd,
						moves: [move.move.sanPlus],
						repertoireId: move.repertoireId,
					});
				});
				const createKey = (request: {
					epd: string;
					repertoireId: string | null;
				}) => {
					return `${request.epd}-${request.repertoireId}`;
				};
				requests = filter(requests, (r) => !rs.positionReports[r.repertoireId]?.[r.epd]);
				requests = filter(requests, (r) => !s.pendingPositionReports.has(createKey(r)));
				requests = uniqBy(requests, (r) => createKey(r));
				if (isEmpty(requests)) {
					return;
				}
				requests.forEach((request) => {
					s.pendingPositionReports.add(createKey(request));
				});
				chunk(requests, 100).forEach((requests) => {
					rspcClient
						.query(["openings.fetchPositionReports", requests])
						.then((positionReports) => {
							set(([s, rs]) => {
								positionReports.forEach((report) => {
									// let reportMutated = report as Partial<PositionReportDTO> & Partial<PositionReport>;
									s.pendingPositionReports.delete(createKey(report));
									report.suggestedMoves!.forEach((move) => {
										if (move.stockfish) {
											// @ts-expect-error
											move.stockfish = new StockfishEval([
												move.stockfish as unknown as StockfishReport,
												sideFromEpd(report.epd),
											]);
										}
									});
									_set(
										rs.positionReports,
										[report.repertoireId!, report.epd],
										report as unknown as PositionReport,
									);
								});
								s.updateTableResponses();
								if (UI().mode === "audit") {
									AUDIT_STATE().updateTableResponses();
								}
								s.updatePlans();
								s.fetchNeededPositionReports();
							});
						})
						.finally(() => {
							requests.forEach((request) => {
								s.pendingPositionReports.delete(createKey(request));
							});
						});
				});
			}),
		requestToAddCurrentLine: () =>
			set(([s, rs, gs]) => {
				const subscribed = gs.userState.isSubscribed();

				if (
					!subscribed &&
					rs.pastFreeTier(s.repertoireSide!) &&
					PAYMENT_ENABLED &&
					!rs.onboarding.isOnboarding
				) {
					UI().replaceView(UpgradeSubscriptionView, {
						props: { pastLimit: true },
					});
					return;
				}
				if (s.hasPendingLineToAdd) {
					s.addPendingLine();
				}
			}),

		updatePlans: () =>
			set(([s, rs]) => {
				const activeRepertoire = s.getActiveRepertoire();
				if (!activeRepertoire) {
					return;
				}
				const plans =
					rs.positionReports[activeRepertoire.id]?.[s.chessboard.getCurrentEpd()]?.plans ?? [];

				const maxOccurence = plans[0]?.occurences ?? 0;
				const consumer = parsePlans(
					cloneDeep(plans),
					activeRepertoire.side,
					s.chessboard.get((s) => s.position),
				);
				s.chessboard.set((c) => {
					c.focusedPlans = [];
					c.plans = consumer.metaPlans.filter((p) => consumer.consumed.has(p.id));
					s.planSections = consumer.planSections;
					c.maxPlanOccurence = maxOccurence;
				});
			}),
		onPositionUpdate: () =>
			set(([s, rs]) => {
				s.currentSide = s.chessboard.getTurn();
				s.pendingResponses = {};

				s.updatePlans();

				const incidences = s.getLineIncidences();
				if (rs.ecoCodeLookup) {
					s.lastEcoCode = rs.getLastEcoCode(s.chessboard.get((s) => s.positionHistory));
				}
				const line = s.chessboard.get((s) => s.moveLog);
				// so we can check if each san is valid from each position
				let isInvalid = false;
				const positionHistory = s.chessboard.get((s) => s.positionHistory);
				map(
					zip(positionHistory, drop(positionHistory, 1), line, incidences),
					([position, nextPosition, san, _incidence], i) => {
						if (!san || !position || isInvalid) {
							return;
						}
						if (isDevelopment) {
							const chessPosition = new Chess(Epd.toFen(position!));
							const validatedMoves = chessPosition.validateMoves([san]);
							if (validatedMoves === null) {
								chessPosition.move(san);
								const nextEpd = genEpd(chessPosition);
								if (nextEpd !== nextPosition) {
									isInvalid = true;
									console.error("This should never happen! EPD mismatch");
									s.chessboard.resetPosition();
									rs.backToOverview();
								}
							}
						}

						const mine = i % 2 === (s.repertoireSide === "white" ? 0 : 1);
						if (
							!some(s.getActiveRepertoire()?.positionResponses[position!], (m) => {
								return m.sanPlus === san;
							})
						) {
							s.pendingResponses![position!] = {
								epd: position,
								epdAfter: s.chessboard.get((s) => s.positionHistory)[i + 1],
								sanPlus: san,
								side: s.repertoireSide!,
								mine: mine,
							};
						}
					},
				);

				s.hasAnyPendingResponses = !isEmpty(flatten(values(s.pendingResponses)));
				s.hasPendingLineToAdd = some(flatten(values(s.pendingResponses)), (m) => m.mine);
				// StockfishInterface.cancel();
				s.updateTableResponses();
				s.fetchNeededPositionReports();
				rs.fetchNeededAnnotations();
			}),
		getLineIncidences: () =>
			get(([s, rs]) => {
				if (!s.repertoireSide) {
					return [];
				}

				let incidence = 1.0;
				return map(
					zip(
						s.chessboard.get((s) => s.positionHistory),
						s.chessboard.get((s) => s.moveLog),
					),
					([position, san], _i) => {
						if (!position || !s.activeRepertoireId) {
							return incidence;
						}
						const positionReport = rs.positionReports[s.activeRepertoireId]?.[position];
						if (positionReport) {
							const suggestedMove = find(positionReport.suggestedMoves, (sm) => sm.sanPlus === san);
							if (suggestedMove) {
								incidence = suggestedMove.incidence ?? 0;
								return incidence;
							}
						}

						return incidence;
					},
				);
			}),
		getIncidenceOfCurrentLine: () =>
			get(([s, _rs]) => {
				return last(s.getLineIncidences());
			}),
		getOpeningNameForMove: (epdAfter: string) => {
			return get(([s, rs]) => {
				if (!s.lastEcoCode && s.chessboard.getCurrentEpd() !== START_EPD) {
					return null;
				}
				const [currentOpeningName, currentVariations] = s.lastEcoCode
					? EcoCode.getAppropriateEcoName(s.lastEcoCode)
					: [];
				const nextEcoCode = rs.ecoCodeLookup[epdAfter];
				let includeOpeningName = false;
				if (nextEcoCode) {
					const [name, variations] = EcoCode.getAppropriateEcoName(nextEcoCode);
					if (name !== currentOpeningName) {
						includeOpeningName = true;
						return name;
					}
					const lastVariation = last(variations);

					if (name === currentOpeningName && lastVariation !== last(currentVariations)) {
						includeOpeningName = true;
						return last(variations);
					}
					if (includeOpeningName) {
						return last(variations);
					}
				}
			});
		},
		addPendingLine: () =>
			set(([s, _rs]) => {
				s.showPlansState.hasShown = false;
				const [loading, setLoading] = createSignal(true);
				const [justCompleted, setJustCompleted] = createSignal(false);
				UI().withoutAnimations(() => {
					UI().cutTo(RepertoireBuilder);
				});
				UI().pushView(RepertoireCheckInView, {
					props: {
						loading: loading,
						justCompleted: justCompleted,
					},
				});
				s.addedLineState.loading = true;
				const completeBefore = s.repertoireProgressState[s.activeRepertoireId!]?.completed;
				return client
					.post("/api/v2/openings/add_moves", {
						moves: flatten(cloneDeep(values(s.pendingResponses))),
						repertoireId: s.activeRepertoireId!,
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						set(([s, rs]) => {
							rs.repertoireFetched(data);
							if (rs.onboarding.isOnboarding) {
								UI().pushView(PracticeIntroOnboarding);
							} else {
								rs.repertoireFetched(data);
								s.onPositionUpdate();
								rs.onRepertoireChange();
								const completeAfter = s.repertoireProgressState[s.activeRepertoireId!]?.completed;
								setLoading(false);
								setJustCompleted(!completeBefore && completeAfter);
							}
						});
					})
					.catch((err: Error) => {
						Sentry.captureException(err);
					})
					.finally(() => {
						set(([s]) => {
							s.addedLineState.loading = false;
						});
					});
			}),
	} as Omit<BrowsingState, "chessboardState">;

	initialState.chessboard = createChessboardInterface()[1];
	initialState.chessboard.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([s]) => {
					s.onPositionUpdate();
				});
			},

			madeManualMove: () => {
				get(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {});
			},
			onReset: () => {
				set(([s]) => {
					s.showPlansState.hasShown = false;
				});
			},
			onMovePlayed: () => {
				set(([s, rs]) => {
					const repertoireId = s.activeRepertoireId || rs.getDefaultRepertoire("white")?.id;
					if (!repertoireId) {
						return;
					}
					if (includes(["side_overview", "overview"], UI().mode)) {
						s.setActiveRepertoire(repertoireId);
						UI().pushView(RepertoireBuilder);
					} else if (UI().currentView()?.component === PreAdd) {
						UI().pushView(RepertoireBuilder);
					}

					s.checkShowTargetDepthReached();
				});
			},
			shouldMakeMove: (_move: Move) =>
				set(([_s]) => {
					animateSidebar("right");
					if (UI().hasView(RepertoireBuilder)) {
						UI().cutTo(RepertoireBuilder);
					}
					return true;
				}),
		};
	});
	return initialState;
};

function createEmptyRepertoireProgressState(): RepertoireProgressState {
	return {
		pendingMoves: 0,
		completed: false,
		expectedDepth: 0,
		percentComplete: 0,
		showPopover: false,
	};
}
