import toast from "solid-toast";

export const todo = (message: string): any => {
	console.error("TODO", message);
	toast.error(message);
};

export const todoRelease = (x: never): any => {
	if (typeof x === "function") {
		// @ts-expect-error
		x();
	} else {
		console.error("TODO", x);
		return x as any;
	}
};
