import { Chess } from "@lubert/chess.ts";
import * as Sentry from "@sentry/browser";
import { createSignal } from "solid-js";
import { Epd } from "~/types/Epd";
import { StockfishEval } from "~/types/StockfishEval";
import { moveToLan } from "./chess";
import { MultiCallback } from "./multi_callback";
import {
	STOCKFISH_LOADED,
	type StockfishInfo,
	loadStockfishModels,
	reloadStockfish,
} from "./stockfish_models";
import { Side } from "~/types/Side";

export { onStockfishLoaded } from "./stockfish_models";

export const EVAL_NODES = {
	SHALLOW: 100_000,
	STANDARD: 250_000,
	GAME: 500_000,
	DEEP: 2_000_000,
} as { SHALLOW: number; STANDARD: number; DEEP: number; GAME: number };

const [uciHandle, setUciHandle] = createSignal<(cmd: string) => void>(() => {});
const postMessage = (msg: string) => {
	uciHandle()(msg);
};

export const loadStockfish = () => {
	loadStockfishModels(setUciHandle, StockfishInterface);
	return StockfishInterface;
};

const EVAL_CACHE: Record<string, StockfishEval> = {};

const toEvalCacheKey = (epd: string, nodes: number) => `${epd}|${nodes}`;

export type StockfishMove = {
	san: string;
	stockfishEval: StockfishEval;
	final?: boolean;
};

class FailureDetector {
	static TIMEOUT_MS = 2000;
	timeoutId: ReturnType<typeof setTimeout> | null = null;
	lastTimeoutStart: number;
	failCallback: () => void;

	constructor(failCallback: () => void) {
		this.failCallback = failCallback;
		this.timeoutId = setTimeout(() => {
			this.onFail();
		}, FailureDetector.TIMEOUT_MS);
		this.lastTimeoutStart = performance.now();
	}

	delayFail() {
		console.debug(
			`[FailureDetector] delaying fail after ${(performance.now() - this.lastTimeoutStart).toFixed(2)}ms`,
		);
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		this.timeoutId = setTimeout(() => {
			this.onFail();
		}, FailureDetector.TIMEOUT_MS);
		this.lastTimeoutStart = performance.now();
	}

	destroy() {
		console.debug("[FailureDetector] destroyed");
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
	}

	onFail() {
		console.warn("[FailureDetector] fail triggered");
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		this.timeoutId = null;
		this.failCallback();
	}
}

const RESURRECTION_LIMIT = 10;
export const StockfishInterface = {
	position: new Chess() as Chess | null,
	loaded: false,
	nodeGoal: EVAL_NODES.STANDARD as number,
	isReady: false,
	running: false,
	readyCallback: new MultiCallback<() => void>(),
	loadedCallback: new MultiCallback<() => void>(),
	evalUpdatedCallback: new MultiCallback<(_: StockfishEval) => void>(),
	topMovesUpdatedCallback: new MultiCallback<(_: StockfishMove[]) => void>(),
	topMovesFinalCallback: new MultiCallback<(_: StockfishMove[]) => void>(),
	finalEvalCallback: new MultiCallback<(_: StockfishEval) => void>(),
	okCallback: new MultiCallback<() => void>(),
	taskQueue: [] as (() => void)[],
	isProcessing: false,
	failureDetector: null as FailureDetector | null,
	resurrections: 0,

	// epd to map of uci lan moves to san moves
	legalMoves: {} as Record<string, string>,
	uciCmd: (cmd: string) => {
		console.debug("[Stockfish postMessage]", cmd);
		postMessage(cmd);
	},
	getOption: (name: string) => {
		return StockfishInterface.uciCmd(`option name ${name} value`);
	},
	setOption: (name: string, value: string) => {
		StockfishInterface.uciCmd(`setoption name ${name} value ${value}`);
	},
	setMemory: (mb: number) => {
		StockfishInterface.setOption("Hash", `${mb}`);
	},
	setThreads: (threads: number) => {
		StockfishInterface.setOption("Threads", `${threads}`);
	},
	multiPvSetting: 1,
	setMultiPv: (multipv: number) => {
		StockfishInterface.setOption("MultiPV", `${multipv}`);
		StockfishInterface.multiPvSetting = multipv;
	},

	getTopMoves: (
		epd: string,
		{ pv, nodes }: { pv: number; nodes: number },
		progressCallback?: (moves: StockfishMove[]) => void,
	): Promise<StockfishMove[]> => {
		return StockfishInterface.enqueueTask(async () => {
			StockfishInterface.setMultiPv(pv);
			if (progressCallback) {
				StockfishInterface.topMovesUpdatedCallback.add(progressCallback);
			}
			StockfishInterface.go(epd, { nodes });
			const promise = new Promise<StockfishMove[]>((resolve) => {
				StockfishInterface.topMovesFinalCallback.add((stockfishMoves) => {
					resolve(stockfishMoves);
				});
			});
			return promise;
		});
	},
	checkReady: () => {
		return new Promise<boolean>((resolve) => {
			StockfishInterface.readyCallback.add(() => {
				resolve(true);
			});
			StockfishInterface.uciCmd("isready");
		});
	},
	setPosition: (epd: string) => {
		StockfishInterface.uciCmd(`position fen ${Epd.toFen(epd)}`);
	},
	epd: null as string | null,
	go: async (
		epd: string,
		opts: {
			nodes?: number;
		},
	): Promise<StockfishEval> => {
		return StockfishInterface.enqueueTask(() => {
			StockfishInterface.running = false;
			const position = new Chess(Epd.toFen(epd));
			const moves = position.moves({ verbose: true });
			moves.forEach((move) => {
				StockfishInterface.legalMoves[moveToLan(move)] = move.san;
			});
			// console.debug("[Stockfish] legal moves", Stockfish.legalMoves);
			StockfishInterface.position = position;
			StockfishInterface.epd = epd;
			StockfishInterface.setPosition(epd);
			StockfishInterface.uciCmd(`go nodes ${opts.nodes || EVAL_NODES}`);
			StockfishInterface.nodeGoal = opts.nodes ?? EVAL_NODES.STANDARD;
			const promise = new Promise<StockfishEval>((resolve) => {
				StockfishInterface.finalEvalCallback.add((stockfishEval) => {
					StockfishInterface.running = false;
					resolve(stockfishEval);
				});
			});
			return promise;
		});
	},
	evalPos: async ({
		epd,
		nodes,
		cb,
	}: {
		epd: string;
		nodes?: number;
		cb?: (res: StockfishEval) => void;
	}): Promise<StockfishEval | null> => {
		return StockfishInterface.enqueueTask(async () => {
			if (!STOCKFISH_LOADED) {
				return null;
			}
			const nodesValue = nodes ?? EVAL_NODES.STANDARD;

			const cacheKey = toEvalCacheKey(epd, nodesValue);
			if (EVAL_CACHE[cacheKey]) {
				return EVAL_CACHE[cacheKey];
			}

			StockfishInterface.setMultiPv(1);
			StockfishInterface.setPosition(epd);
			let position = new Chess(Epd.toFen(epd));
			StockfishInterface.position = position;
			StockfishInterface.uciCmd(`go nodes ${nodesValue}`);
			StockfishInterface.nodeGoal = nodesValue;
			if (cb) {
				StockfishInterface.evalUpdatedCallback.add(cb);
			}
			const promise = new Promise<StockfishEval>((resolve) => {
				StockfishInterface.finalEvalCallback.add((stockfishEval) => {
					EVAL_CACHE[cacheKey] = stockfishEval;
					resolve(stockfishEval);
				});
			});
			return await promise;
		});
	},
	ready: () => {
		StockfishInterface.isReady = true;
		StockfishInterface.readyCallback.call();
	},
	onBestMove: (_uci: string) => {
		if (StockfishInterface.lastInfo) {
			// console.log("saying we found the best move!");
			StockfishInterface.onInfo(StockfishInterface.lastInfo!, true);
		}
	},
	onReady: (): Promise<void> => {
		return new Promise<void>((resolve) => {
			StockfishInterface.readyCallback.add(() => {
				resolve();
			});
		});
	},
	lastInfo: null as StockfishInfo | null,
	pendingMoves: [] as StockfishMove[],
	setupForNewTask: () => {
		StockfishInterface.uciCmd("stop");
		StockfishInterface.uciCmd("ucinewgame");
		StockfishInterface.finalEvalCallback.clear();
		StockfishInterface.evalUpdatedCallback.clear();
		StockfishInterface.topMovesFinalCallback.clear();
		StockfishInterface.topMovesUpdatedCallback.clear();
		StockfishInterface.failureDetector?.destroy();
		StockfishInterface.failureDetector = null;
		StockfishInterface.pendingMoves = [];
		StockfishInterface.lastInfo = null;
		StockfishInterface.running = false;
		StockfishInterface.epd = null;
	},
	handleUciMessage: (msg: any) => {
		StockfishInterface.failureDetector?.delayFail();
		console.debug("handleUciMessage", msg);
		if (msg === "uciok") {
			StockfishInterface.loaded = true;
			StockfishInterface.loadedCallback.call();
			StockfishInterface.uciCmd("isready");
			return;
		}
		if (msg === "readyok") {
			StockfishInterface.isReady = true;
			StockfishInterface.ready();
			return;
		}
		let match = msg.match(/^bestmove ([a-h][1-8][a-h][1-8][qrbn]?)/);
		/// Did the AI move?
		if (match) {
			StockfishInterface.running = false;
			StockfishInterface.onBestMove(match[1]);
		} else {
			match = msg.match(/^info .*\bdepth (\d+) .*\bnps (\d+)/);
		}

		match =
			/^info .*\b multipv (?<multipv>\d+).*score (?<evalType>\w+).* (?<score>-?\d+).* nodes (?<nodes>\d+) .* pv (?<pv>\w+)/.exec(
				msg,
			);
		if (match) {
			const matches: Record<string, string> = match.groups;
			let score = Number.parseInt(matches.score);
			if (StockfishInterface.position?.turn() === "b") {
				score = -score;
			}
			const stockfishEval = new StockfishEval("0");
			stockfishEval.thinking = true;
			/// Is it measuring in centipawns?
			if (matches.evalType === "cp") {
				stockfishEval.eval = score;
			} else if (matches.evalType === "mate") {
				stockfishEval.eval = undefined;
				stockfishEval.mate = [
					score,
					score === 0
						? Side.fromColor(StockfishInterface.position?.turn() || "w")
						: score < 0
							? "black"
							: "white",
				];
			}
			let pv = matches.pv;
			stockfishEval.pv = pv;

			StockfishInterface.onInfo({
				stockfishEval,
				nodes: matches.nodes ? Number.parseInt(matches.nodes) : undefined,
				multipv: Number.parseInt(matches.multipv),
				pvLan: matches.pv,
				pv: pv,
			});
		}
	},
	onInfo: (info: StockfishInfo, final = false) => {
		const { stockfishEval, multipv, pvLan } = info;
		if (final) {
			stockfishEval.thinking = false;
		}
		StockfishInterface.evalUpdatedCallback.call(stockfishEval);
		if (final) {
			StockfishInterface.finalEvalCallback.call(stockfishEval);
		}
		StockfishInterface.lastInfo = info;
		// console.debug("parsed info", info, Stockfish.legalMoves);
		const stockfishMove: StockfishMove = {
			stockfishEval,
			san: StockfishInterface.legalMoves[pvLan]!,
		};
		// console.debug("stockfishMove", stockfishMove);
		if (multipv === 1) {
			StockfishInterface.pendingMoves = [];
		}
		StockfishInterface.pendingMoves.push(stockfishMove);

		// top moves callbacks will just be unused if it has no callers
		if (final) {
			StockfishInterface.pendingMoves = StockfishInterface.pendingMoves.map((sm) => {
				sm.stockfishEval.thinking = false;
				return { ...sm, final: true };
			});
		}
		StockfishInterface.topMovesUpdatedCallback.call(StockfishInterface.pendingMoves);
		if (final) {
			StockfishInterface.topMovesFinalCallback.call(StockfishInterface.pendingMoves);
		}
	},
	enqueueTask<T>(task: () => Promise<T>): Promise<T> {
		// If calling from a task already, just run it
		if (StockfishInterface.isProcessing) {
			return task();
		}
		return new Promise<T>((resolve, reject) => {
			const wrappedTask = async () => {
				StockfishInterface.failureDetector = new FailureDetector(() => {
					StockfishInterface.failureDetector?.destroy();
					StockfishInterface.failureDetector = null;
					reject(new Error("Stockfish failed to respond"));
					StockfishInterface.reloadStockfish();
				});
				StockfishInterface.setupForNewTask();
				await StockfishInterface.checkReady();
				try {
					const result = await task();
					resolve(result);
				} catch (error) {
					reject(error);
				} finally {
					StockfishInterface.isProcessing = false;
					StockfishInterface.processNextTask();
					StockfishInterface.failureDetector?.destroy();
				}
			};

			if (StockfishInterface.isProcessing) {
				StockfishInterface.taskQueue.push(wrappedTask);
			} else {
				StockfishInterface.isProcessing = true;
				wrappedTask();
			}
		});
	},
	processNextTask: () => {
		if (StockfishInterface.taskQueue.length > 0 && !StockfishInterface.isProcessing) {
			const nextTask = StockfishInterface.taskQueue.shift()!;
			StockfishInterface.isProcessing = true;
			nextTask();
		}
	},
	reloadStockfish: () => {
		if (StockfishInterface.resurrections > RESURRECTION_LIMIT) {
			console.error("Guardian angel: Stockfish is dead, giving up");
			Sentry.captureException(new Error("Guardian angel: Stockfish is dead, giving up"));
			return;
		}
		let message = `Guardian angel: Stockfish is dead, resurrecting for the ${StockfishInterface.resurrections} time`;
		console.error(message);
		Sentry.captureMessage(message);
		reloadStockfish();
	},
};

// @ts-expect-error
window.StockfishInterface = StockfishInterface;
