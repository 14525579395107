import { isEmpty, some, sortBy } from "lodash-es";
import { filter, isNil, noop } from "lodash-es";

import type { ChessCardDTO } from "~/rspc";

import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";

import { type AppState, USER_STATE } from "./app_state";

import type { Move } from "@lubert/chess.ts";
import { getInitialCardReviewState } from "./cards_review_state";
import { type ChessboardInterface, createChessboardInterface } from "./chessboard_interface";
import { rspcClient } from "./rspc_client";
import type { StateGetter, StateSetter } from "./state_setters_getters";

export type CardsState = ReturnType<typeof getInitialChesscardsState>;

type Stack = [CardsState, AppState];
const selector = (s: AppState): Stack => [s.cardsState, s];

export const getInitialChesscardsState = (
	_setDoNotUse: StateSetter<AppState, any>,
	_getDoNotUse: StateGetter<AppState, any>,
) => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _setDoNotUse((s) => fn(selector(s)));
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _getDoNotUse((s) => fn(selector(s)));
	};
	const initialState = {
		cards: undefined as ChessCardDTO[] | undefined,
		chessboardOverview: undefined as unknown as ChessboardInterface,
		reviewState: getInitialCardReviewState(),
		onboarding: false,
		chessboardBuild: undefined as unknown as ChessboardInterface,
		totalDue: 0 as number,
		processingState: {
			isFetching: false,
			processingCards: 0,
			processingGames: 0,
		},
		earliestDue: undefined as string | undefined,
		deleteCard: (card: ChessCardDTO) => {
			set(([s]) => {
				if (!s.cards) {
					return;
				}
				rspcClient.query(["cards.deleteCard", { cardId: card.id }]);
				s.cards = filter(s.cards, (c) => c.id !== card.id);
				s.onCardsUpdate();
			});
		},
		addCard: (card: ChessCardDTO) => {
			set(([s]) => {
				if (!s.cards) {
					return;
				}
				if (some(s.cards, (c) => c.id === card.id)) {
					return;
				}
				s.cards?.push(card);
				s.onCardsUpdate();
			});
		},
		onCardsUpdate: () => {
			set(([s]) => {
				if (!s.cards) {
					return;
				}
				s.totalDue = filter(
					s.cards,
					(c) => SpacedRepetitionStatus.isReviewDue(c.srs) && !c.srs.firstReview,
				).length;
				s.earliestDue =
					sortBy(s.cards.map((c) => c.srs?.dueAt).filter(isNil), (x) => x!)[0] ?? undefined;
			});
		},
		initState: () => {
			set(([s, _gs]) => {
				s.fetchCards();
			});
		},
		fetchCards: () => {
			set(([s]) => {
				s.processingState.isFetching = true;
			});
			rspcClient.query(["cards.fetchCards", {}]).then((chessCards) => {
				set(([s]) => {
					s.cards = chessCards;
					s.onCardsUpdate();
					if (!isEmpty(chessCards)) {
						USER_STATE().pastLandingPage = true;
					}
				});
			});
		},
	};

	initialState.chessboardOverview = createChessboardInterface()[1];
	initialState.chessboardOverview.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([_s]) => {});
			},

			madeManualMove: () => {
				get(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {});
			},
			onReset: () => {
				set(([_s]) => {});
			},
			onMovePlayed: () => {
				set(([_s, _rs]) => {});
			},
			shouldMakeMove: (_move: Move) =>
				set(([_s]) => {
					return true;
				}),
		};
	});
	initialState.chessboardBuild = createChessboardInterface()[1];
	initialState.chessboardBuild.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([_s]) => {});
			},

			madeManualMove: () => {
				get(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {});
			},
			onReset: () => {
				set(([_s]) => {});
			},
			onMovePlayed: () => {
				set(([_s, _rs]) => {});
			},
			shouldMakeMove: (_move: Move) =>
				set(([_s]) => {
					return true;
				}),
		};
	});

	return initialState;
};
