import { isNil } from "lodash-es";
import type { Side } from "./Side";

export interface GameResultsDistribution {
	white: number;
	black: number;
	draw: number;
}
export namespace GameResultsDistribution {
	export function getTotalGames(results: GameResultsDistribution) {
		if (!results) {
			return null;
		}
		return results.draw + results.black + results.white;
	}

	export function getWinRate(x: GameResultsDistribution, side: string) {
		// @ts-expect-error
		return x[side] / GameResultsDistribution.getTotalGames(x);
	}

	export function getDrawAdjustedWinRate(x: GameResultsDistribution, side: Side) {
		return (x[side] + x.draw / 2) / GameResultsDistribution.getTotalGames(x)!;
	}

	export function getWinRateRange(
		x: GameResultsDistribution,
		side: Side,
	): [number, number, number] {
		const w = x[side];
		const n = GameResultsDistribution.getTotalGames(x);
		const { left, right } = wilson(w, n!);
		return [left, right, Math.abs(left - right)];
	}

	export const wilson = (positiveScore: number, total: number) => {
		if (total === 0) {
			return {
				left: 0,
				right: 0,
			};
		}

		// phat is the proportion of successes
		// in a Bernoulli trial process
		const phat = positiveScore / total;

		// z is 1-alpha/2 percentile of a standard
		// normal distribution for error alpha=5%
		const z = 1.96;

		// implement the algorithm
		// (http://goo.gl/kgmV3g)
		const a = phat + (z * z) / (2 * total);
		const b = z * Math.sqrt((phat * (1 - phat) + (z * z) / (4 * total)) / total);
		const c = 1 + (z * z) / total;

		return {
			left: (a - b) / c,
			right: (a + b) / c,
		};
	};

	export const getPlayRate = (
		before: GameResultsDistribution,
		after: GameResultsDistribution,
	): number | null => {
		const total = GameResultsDistribution.getTotalGames(before);
		const divisor = GameResultsDistribution.getTotalGames(after);
		if (isNil(total) || isNil(divisor) || total === 0) {
			return null;
		}
		return divisor / total;
	};
}
