import { cloneDeep, find, last, noop, upperFirst } from "lodash-es";
import { type Accessor, For, Match, Show, Switch, createSignal } from "solid-js";
import { Puff } from "solid-spinner";
import toast from "solid-toast";
import { Spacer } from "~/components/Space";
import { THRESHOLD_OPTIONS, type ThresholdOption } from "~/threshold_options";
import type { Uuid } from "~/types/Uuid";
import { getExpectedNumMovesBetween } from "~/utils//coverage_estimation";
import { APP_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import client from "~/utils/client";
import { isChessbook, isDevelopment, isIos, isNative } from "~/utils/env";
import { BETA_FEATURES } from "~/utils/features";
import {
	type FrontendSetting,
	type FrontendSettingOption,
	type FrontendSettings,
	SETTINGS,
	createFrontendSettingActions,
} from "~/utils/frontend_settings";
import {
	RECEIPT_VERIFIED_MULTI_CALLBACK,
	findLatestOriginalTransactionId,
} from "~/utils/in_app_purchases";
import { isMobile } from "~/utils/isMobile";
import { c, stylex } from "~/utils/styles";
import { type BoardThemeId, COMBINED_THEMES_BY_ID, combinedThemes } from "~/utils/theming";
import { renderThreshold } from "~/utils/threshold";
import { trackEvent } from "~/utils/trackEvent";
import { identify } from "~/utils/user_properties";
import { AuthStatus, DEFAULT_THRESHOLD } from "~/utils/user_state";
import { floatEqual } from "~/utils/utils";
import { CMText } from "./CMText";
import { ConnectAccountsSetting, ConnectedAccountIconAndText } from "./ConnectedAccounts";
import { DeleteAccountView } from "./DeleteAccountView";
import { Intersperse } from "./Intersperse";
import { Pressable } from "./Pressable";
import { PrivacyPolicyAndTermsView } from "./PrivacyPolicyAndTermsView";
import { RatingSelection } from "./RatingSelection";
import {
	type SidebarAction,
	SidebarActions,
	SidebarFullWidthButton,
	SidebarSectionHeader,
} from "./SidebarActions";
import { SidebarSelectOneOf } from "./SidebarSelectOneOf";
import { SidebarTemplate } from "./SidebarTemplate";
import { initTooltip } from "./Tooltip";
import { UpgradeSubscriptionView } from "./UpgradeSubscriptionView";

export const CoverageSettings = (props: { repertoireId?: Uuid }) => {
	const repertoire = () =>
		props.repertoireId ? REPERTOIRE_STATE().repertoires?.[props.repertoireId!] : null;
	const missThreshold = () => REPERTOIRE_STATE().getRepertoireThreshold(props.repertoireId ?? null);
	const selected = () => find(THRESHOLD_OPTIONS, (o) => floatEqual(o.value, missThreshold()));
	const onSelect = (t: ThresholdOption | null) => {
		quick((s) => {
			if (props.repertoireId) {
				s.repertoireState.setRepertoireThreshold(props.repertoireId, t?.value ?? DEFAULT_THRESHOLD);
			} else {
				if (t) {
					s.userState.setTargetDepth(t.value);
				}
			}
		});
	};
	const [expanded, setExpanded] = createSignal(false);
	const thresholdOptions = () => {
		let options = cloneDeep(THRESHOLD_OPTIONS);
		if (!expanded()) {
			return options.filter((o) => !o.hidden);
		}
		return options;
	};
	const getExpectedMoves = (threshold: number) => {
		return getExpectedNumMovesBetween(1, threshold, "white", USER_STATE().getSingleElo());
	};
	const maxMoves = () => getExpectedMoves(last(thresholdOptions())!.value);

	const footerActions = () => {
		return [
			{
				onPress: () => {
					setExpanded(!expanded());
				},
				text: expanded() ? "Hide" : "Show more",
			},
		];
	};
	return (
		<SidebarTemplate
			actions={[]}
			header={
				isMobile()
					? "What size repertoire do you want?"
					: "What size repertoire do you want to create?"
			}
		>
			<div class="row items-center border-0 border-b border-border border-solid  text-tertiary font-semibold pb-2 md:pb-3 pr-4 md:pr-4 lg:pr-6 text-xs text-right">
				<div class="grow" />
				<div class="w-26 lg:w-28 mr-6 ">Coverage goal</div>
				<div class="w-16 md:w-30 shrink-0 text-left">Study time</div>
				<div class="w-6" />
			</div>
			<SidebarSelectOneOf
				description={undefined}
				choices={thresholdOptions()}
				activeChoice={selected()}
				onSelect={onSelect}
				equality={(choice: ThresholdOption, activeChoice?: ThresholdOption) => {
					if (!activeChoice) return false;
					return floatEqual(choice.value, activeChoice.value);
				}}
				renderChoice={(r: ThresholdOption, _active: boolean) => {
					const moves = getExpectedMoves(r.value);
					return (
						<div class="row items-center">
							<div class="grow whitespace-nowrap text-sm">
								{isMobile() && r.name.includes("Tournament") ? "Tournament" : r.name}
							</div>
							<div
								class="w-26 lg:w-32 mr-6 text-xs  text-right"
								ref={(x) => {
									initTooltip({
										ref: x,
										maxWidth: 200,
										content: () => (
											<p>
												Your repertoire will be complete when you have a response to all moves
												you’ll see in at least <b>{renderThreshold(r.value)} games</b> at your level
											</p>
										),
									});
								}}
							>{`1 in ${Math.round(1 / r.value)} games`}</div>
							<div
								class="w-16 md:w-30  shrink-0 h-1 rounded overflow-hidden"
								ref={(x) => {
									initTooltip({
										ref: x,
										maxWidth: 200,
										content: () => (
											<p>
												A complete repertoire will have around{" "}
												<b>{Math.round(moves / 20) * 20} moves</b>
											</p>
										),
									});
								}}
							>
								<div
									class={"bg-purple-55 h-full rounded"}
									style={{ width: `${(moves / maxMoves()) * 100}%` }}
								/>
							</div>
						</div>
					);
				}}
			/>
			<div class="row items-center space-x-4 padding-sidebar pt-2">
				<For each={footerActions()}>
					{(action) => (
						<Pressable
							class={clsx("pb-1")}
							onPress={(e: Event) => {
								e.stopPropagation();
								action.onPress();
							}}
						>
							<CMText
								style={stylex(c.fontSize(12), c.weightSemiBold)}
								class="text-tertiary &hover:text-primary transition-colors"
							>
								{action.text}
							</CMText>
						</Pressable>
					)}
				</For>
			</div>
			<Show when={repertoire()?.coverageTarget}>
				<Spacer between={["table", "actions"]} />
				<SidebarActions
					actions={[
						{
							style: "secondary",
							onPress: () => {
								onSelect(null);
							},
							text: "Reset to account setting",
						},
					]}
				/>
			</Show>
		</SidebarTemplate>
	);
};
export const RatingSettings = () => {
	return (
		<SidebarTemplate actions={[]} header={"Your rating"} bodyPadding={true}>
			<CMText style={stylex()} class={"text-secondary"}>
				This is used to determine which moves your opponents are likely to play.
			</CMText>

			<Spacer between={["body-text", "form"]} />
			<RatingSelection />
		</SidebarTemplate>
	);
};

export const ThemeSettings = () => {
	const user = () => APP_STATE().userState?.user;
	return (
		<SidebarTemplate header={"Board appearance"} actions={[]}>
			<SidebarSelectOneOf
				choices={combinedThemes.map((t) => t.boardTheme)}
				activeChoice={user()?.theme ?? "default"}
				onSelect={(boardThemeId: BoardThemeId) => {
					quick((s) => {
						const theme = find(combinedThemes, (t) => t.boardTheme === boardThemeId);
						s.userState.updateUserSettings({
							theme: theme!.boardTheme,
							pieceSet: theme!.pieceSet,
						});
					});
				}}
				renderChoice={(boardThemeId: BoardThemeId) => {
					const theme = find(combinedThemes, (t) => t.boardTheme === boardThemeId);
					return (
						<div style={stylex(c.row)}>
							<CMText style={stylex(c.weightSemiBold, c.fontSize(14))}>{theme!.name}</CMText>
						</div>
					);
				}}
			/>
		</SidebarTemplate>
	);
};

export const BoardSettings = () => {};

export const BetaFeaturesSettings = () => {
	const userState = () => APP_STATE().userState;
	const features = BETA_FEATURES;
	return (
		<SidebarTemplate header={"Beta features"} actions={[]}>
			<SidebarActions
				actions={features.map((feature) => {
					const enabled = () => userState().flagEnabled(feature.flag);
					return {
						text: feature.name,
						style: enabled() ? "focus" : "primary",
						right: enabled() ? "Enabled" : "Disabled",
						subtext: feature.description,
						onPress: () => {
							quick((s) => {
								trackEvent("user_flag_toggle", { flag: feature.flag });
								s.userState.setFlag(feature.flag, !enabled());
							});
						},
					};
				})}
			/>
		</SidebarTemplate>
	);
};

export const FrontendSettingView = <T,>(props: {
	setting: FrontendSetting<T>;
	onChange?: (value: T) => void;
}) => {
	return (
		<SidebarTemplate header={props.setting.title} actions={[]}>
			<Show when={props.setting.description}>
				<p class="body-text padding-sidebar">{props.setting.description}</p>
				<Spacer between={["body-text", "actions"]} />
			</Show>
			<SidebarSelectOneOf
				choices={props.setting.options}
				activeChoice={APP_STATE().userState.getFrontendSetting(
					props.setting.key as keyof FrontendSettings,
				)}
				onSelect={(option: FrontendSettingOption<T>) => {
					quick((s) => {
						trackEvent(`frontend_setting.${props.setting.key}.change`, {
							value: option.value,
						});
						identify({
							[`frontend_setting.${props.setting.key}`]: option.value,
						});
						s.userState.user!.frontendSettings[props.setting.key as keyof FrontendSettings] =
							option.value as any;
						s.userState
							.updateUserSettings({
								frontendSettings: s.userState.user!.frontendSettings as FrontendSettings,
							})
							.then(() => {
								props.onChange?.(option.value as T);
							});
					});
				}}
				equality={(option: FrontendSettingOption<T>, activeOption?: FrontendSettingOption<T>) => {
					return option.value === activeOption?.value;
				}}
				renderChoice={(option: FrontendSettingOption<T>) => {
					return (
						<div style={stylex(c.row)}>
							<CMText style={stylex(c.weightSemiBold, c.fontSize(14))}>{option.label}</CMText>
						</div>
					);
				}}
			/>
		</SidebarTemplate>
	);
};

export const SidebarSettings = () => {
	const user = () => USER_STATE().user;
	const authStatus = () => USER_STATE().authStatus;
	const needsLogin = () =>
		authStatus() === AuthStatus.Unauthenticated ||
		(authStatus() === AuthStatus.Authenticated && user()?.temporary);
	const [loading, setLoading] = createSignal(false);

	const userState = () => APP_STATE().userState;
	const themeId = () => userState().user?.theme;
	const theme = () =>
		find(combinedThemes, (theme) => theme.boardTheme === themeId()) ||
		COMBINED_THEMES_BY_ID.default;
	const sections: Accessor<{ actions: SidebarAction[]; title: string | null }[]> = () => {
		const sections: {
			actions: SidebarAction[];
			title: string | null;
			hidden?: boolean;
		}[] = [];
		sections.push({
			title: null,
			actions: [
				{
					hidden: !isChessbook,
					onPress: () => {
						quick((_s) => {
							trackEvent("home.settings.rating");
							UI().pushView(RatingSettings);
						});
					},
					text: "Your rating",
					right: userState().user?.ratingRange
						? `${userState().user?.ratingRange} ${userState().user?.ratingSystem}`
						: "Unset",
					style: "secondary",
				} as SidebarAction,
				{
					hidden: !isChessbook,
					onPress: () => {
						quick((_s) => {
							trackEvent("home.settings.coverage");
							UI().pushView(CoverageSettings);
						});
					},
					text: "Cover positions seen in",
					right: `1 in ${Math.round(1 / userState().getCurrentThreshold())} games`,
					style: "secondary",
				} as SidebarAction,
				{
					onPress: () => {
						quick((_s) => {
							UI().pushView(ConnectAccountsSetting);
						});
					},
					// todo: enable handling of xs breakpoint stuff
					text: "Connected to",
					right: (
						<div class="flex row space-x-2 md:space-x-4">
							<ConnectedAccountIconAndText
								text="Lichess"
								connected={!!userState().user?.lichessUsername}
							/>
							<ConnectedAccountIconAndText
								text="Chess.com"
								connected={!!userState().user?.chesscomUsername}
							/>
						</div>
					),
					style: "secondary",
				} as SidebarAction,
				{
					hidden: !isChessbook,
					...createFrontendSettingActions(SETTINGS.modelGamesSource, () => {
						quick((s) => {
							s.repertoireState.modelGamesState.fetchDailyModelGame({
								forceNewGame: true,
							});
						});
					}),
				} as SidebarAction,
			],
		});

		sections.push({
			title: "Review settings",
			hidden: !isChessbook,
			actions: [
				{
					onPress: () => {
						quick((_s) => {
							trackEvent("home.settings.reviewStyle");
							UI().pushView(FrontendSettingView, {
								props: { setting: SETTINGS.reviewStyle },
							});
						});
					},
					text: SETTINGS.reviewStyle.title,
					right: userState().getFrontendSetting("reviewStyle").label,
					style: "secondary",
				} as SidebarAction,
				{
					onPress: () => {
						quick((_s) => {
							trackEvent("home.settings.recommended_moves_size");
							UI().pushView(FrontendSettingView, {
								props: { setting: SETTINGS.recommendedMovesSize },
							});
						});
					},
					text: SETTINGS.recommendedMovesSize.title,
					right: userState().getFrontendSetting("recommendedMovesSize").label,
					style: "secondary",
				} as SidebarAction,
			],
		});
		sections.push({
			title: "Board settings",
			actions: [
				{
					onPress: () => {
						quick((_s) => {
							trackEvent("home.settings.theme");
							UI().pushView(ThemeSettings);
						});
					},
					text: "Appearance",
					right: `${upperFirst(theme().name)}`,
					style: "secondary",
				} as SidebarAction,

				{
					onPress: () => {
						quick((_s) => {
							trackEvent("home.settings.pieceAnimationSpeed");
							UI().pushView(FrontendSettingView, {
								props: { setting: SETTINGS.pieceAnimation },
							});
						});
					},
					text: SETTINGS.pieceAnimation.title,
					right: userState().getFrontendSetting("pieceAnimation").label,
					style: "secondary",
				} as SidebarAction,
				{
					onPress: () => {
						quick((_s) => {
							trackEvent("home.settings.sounds");
							UI().pushView(FrontendSettingView, {
								props: { setting: SETTINGS.sound },
							});
						});
					},
					text: SETTINGS.sound.title,
					right: userState().getFrontendSetting("sound").label,
					style: "secondary",
				} as SidebarAction,
			],
		});
		sections.push({
			title: "Subscription",
			hidden: (userState().user?.subscribed && isNative) || !isChessbook,
			actions: [
				{
					onPress: () => {
						quick((_s) => {
							if (!userState().isSubscribed()) {
								trackEvent("home.settings.subscribe");
								UI().pushView(UpgradeSubscriptionView);
							} else {
								trackEvent("home.settings.manage_subscription");
								return client
									.post("/api/stripe/create-billing-portal-link")
									.then(({ data }: { data: { url: string } }) => {
										if (!window.open(data.url, "_blank")) {
											window.location.href = data.url;
										}
									})
									.finally(noop);
							}
						});
					},
					text: userState().isSubscribed()
						? "Manage your subscription"
						: "Upgrade to add unlimited moves",
					right: userState().isSubscribed() ? "Subscribed" : null,
					style: "secondary",
				} as SidebarAction,
				{
					onPress: () => {
						(async () => {
							// @ts-expect-error
							await import("cordova-plugin-purchase");
							setLoading(true);
							trackEvent("settings.restore_purchases");
							const err = await CdvPurchase.store.restorePurchases();
							if (err) {
								console.error(err);
								setLoading(false);
								toast.error("Failed to restore purchases");
								return;
							}
							toast("Restoring purchases");
							const onCallback = (receipt: CdvPurchase.AppleAppStore.SKApplicationReceipt) => {
								const originalTransactionId = findLatestOriginalTransactionId([receipt]);
								if (!originalTransactionId) {
									toast("No purchases found");
									setLoading(false);
									RECEIPT_VERIFIED_MULTI_CALLBACK.remove(onCallback);
									return;
								}
								client
									.post("/api/apple/restore-purchases", {
										transactionId: originalTransactionId,
									})
									.then(({ data }) => {
										if (data?.user) {
											quick((s) => {
												s.userState.handleAuthResponse(data);
											});
										}
										setLoading(false);
										quick((s) => {
											s.repertoireState.backToOverview();
										});
										toast.success("Purchases restored");
									})
									.catch(() => {
										toast.error("Failed to restore purchases");
									});
								RECEIPT_VERIFIED_MULTI_CALLBACK.remove(onCallback);
							};
							RECEIPT_VERIFIED_MULTI_CALLBACK.add(onCallback);
						})();
					},
					hidden: !isIos,
					text: "Restore purchases",
					style: "secondary",
				} as SidebarAction,
			],
		});
		sections.push({
			title: "Account",
			actions: [
				...(isNative || isDevelopment
					? ([
							{
								onPress: () => {
									quick((_s) => {
										trackEvent("home.privacy_and_terms");
										UI().pushView(PrivacyPolicyAndTermsView);
									});
								},
								text: "Privacy Policy / Terms of Use",
								style: "secondary",
							},
						] as SidebarAction[])
					: []),
				...(user()?.email
					? [
							{
								onPress: () => {
									quick((_s) => {
										trackEvent("home.delete_account");
										UI().pushView(DeleteAccountView);
									});
								},
								text: "Delete your account",
								style: "secondary",
							} as SidebarAction,
						]
					: []),
				...(user()?.isAdmin ? [createFrontendSettingActions(SETTINGS.adminDebugMode)] : []),
			],
		});
		if (!isIos && BETA_FEATURES.length > 0) {
			sections.push({
				title: "Experimental",
				hidden: !isChessbook,
				actions: [
					{
						onPress: () => {
							quick((_s) => {
								trackEvent("home.settings.beta_features");
								UI().pushView(BetaFeaturesSettings);
							});
						},
						text: "Beta features",
						right: `${
							(userState().getBetaFlagsEnabled()?.length ?? 0) > 0
								? `${userState().getBetaFlagsEnabled()?.length} enabled`
								: "None enabled"
						}`,
						style: "secondary",
					} as SidebarAction,
				],
			});
		}

		return sections.filter((x) => !x.hidden);
	};
	return (
		<SidebarTemplate bodyPadding={false} header={"Settings"} actions={[]}>
			<Show when={loading()}>
				<div class={"row w-full justify-center"}>
					<Puff />
				</div>
			</Show>
			<Show when={!loading()}>
				<Intersperse each={sections} separator={() => <Spacer between={["actions", "actions"]} />}>
					{(section) => {
						return (
							<div>
								<Show when={section().title}>
									<SidebarSectionHeader text={section().title!} />
								</Show>
								<SidebarActions actions={section().actions} />
							</div>
						);
					}}
				</Intersperse>
				<Spacer height={48} />
				<Switch>
					<Match when={!needsLogin()}>
						<SidebarFullWidthButton
							action={{
								text: "Log out",
								right: <p class="text-secondary text-xs">{user()!.email}</p>,
								style: "primary",
								onPress: () => {
									quick((s) => {
										trackEvent("top_buttons.log_out.clicked");
										s.userState.logout();
									});
								},
							}}
						/>
					</Match>
				</Switch>
			</Show>
		</SidebarTemplate>
	);
};
