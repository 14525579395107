import { Chess } from "@lubert/chess.ts";
import { isEmpty } from "lodash-es";
import { For, Show, createEffect, createMemo, createSignal } from "solid-js";
import { Epd } from "~/types/Epd";
import { type OpeningReport, OpeningsReport } from "~/types/OpeningsReport";
import type { Uuid } from "~/types/Uuid";
import type { EnrichedComponent } from "~/types/View";
import { OPENINGS_REPORT_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { createChessProxy } from "~/utils/chess_proxy";
import { clsx } from "~/utils/classes";
import client from "~/utils/client";
import { useLineEcoCode } from "~/utils/eco_codes";
import {
	type OpeningsReportTimeRange,
	SETTINGS,
	createFrontendSettingActions,
} from "~/utils/frontend_settings";
import { registerViewMode } from "~/utils/register_view_mode";
import { onBack } from "~/utils/signals/onBack";
import { c } from "~/utils/styles";
import { LoadingSpinner } from "./LoadingSpinner";
import { OpeningReportView } from "./OpeningReportView";
import { SideDot } from "./SideDot";
import { SidebarActions } from "./SidebarActions";
import { SidebarTable } from "./SidebarTable";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";
import { initTooltip } from "./Tooltip";
import { NewBadge } from "./icons/NewBadge";

export const OpeningsReportView: EnrichedComponent<any> = (_props) => {
	const seenInsights: Set<Uuid> = new Set();
	onBack(() => {
		setInsightsReviewed([...seenInsights], "client");
		quick((_s) => {
			REPERTOIRE_STATE().backToOverview();
		});
	});
	const timeRange = createMemo(
		() => USER_STATE().getFrontendSetting("openingsTimeRange").value as OpeningsReportTimeRange,
	);
	const reports =
		createMemo(() => OPENINGS_REPORT_STATE().openingsReportsByTimeRange[timeRange()]) ?? null;
	const [activeReport, setActiveReport] = createSignal<OpeningsReport | null>(null);

	const renderOpening = (opening: OpeningReport) => {
		const eco = useLineEcoCode(opening.line);
		const isNew = !opening.reviewed;
		return (
			<div class="flex items-center row  max-w-full shrink-1">
				<SideDot side={opening.side} size={8} />
				<Spacer width={10} />
				<div class="text-primary font-medium whitespace-nowrap overflow-y-visible overflow-x-clip text-ellipsis text-xs inline-block">
					{eco()?.fullName ?? ""}
				</div>
				<Spacer width={8} />
				<Show when={isNew}>
					<div class="shrink-0 max-w-10 h-2.5 ">
						<NewBadge color={c.colors.purple[50]} />
					</div>
				</Show>
			</div>
		);
	};
	createEffect(() => {
		if (reports() === null) {
			setActiveReport(null);
			return;
		}

		if (activeReport() === null) {
			setActiveReport(reports()![0]! ?? null);
		}
	});
	createEffect(() => {
		if (activeReport() === null) {
			return;
		}
		const insights = OpeningsReport.getAllReports(activeReport()!)
			.filter((o) => !o.reviewed)
			.map((o) => o.insightId);
		setInsightsReviewed(insights, "server");
		for (const insight of insights) {
			seenInsights.add(insight);
		}
	});
	const setInsightsReviewed = (insightIds: Uuid[], on: "server" | "client" | "both" = "both") => {
		if (!reports()) {
			return;
		}
		if (on === "client" || on === "both") {
			const insightsSet = new Set(insightIds);
			quick((_s) => {
				reports()!
					.flatMap((o) => OpeningsReport.getAllReports(o))
					.forEach((o) => {
						if (!o) {
							return;
						}
						if (insightsSet.has(o.insightId)) {
							o.reviewed = true;
						}
					});
			});
		}
		if (on === "server" || on === "both") {
			client.post("/api/v1/openings_report/insight_reviewed", {
				insightIds,
			});
		}
	};
	const onClickReport = (opening: OpeningReport, type: "best" | "worst") => {
		setInsightsReviewed([opening.insightId], "both");
		quick((_s) => {
			UI().pushView(OpeningReportView, {
				props: {
					type: type,
					report: opening,
					eco: useLineEcoCode(opening.line)()!,
					overallReport: activeReport()!,
				},
			});
		});
	};
	const renderBottomBorder = (active: boolean) => {
		return (
			<div
				class={clsx(
					"absolute bottom-0 left-0 right-0 h-0.5 transition-colors bg-primary",
					active ? "bg-gray-80" : "bg-gray-20",
				)}
			></div>
		);
	};
	const onBlurTable = () => {
		UI()
			.getActiveChessboard()
			.set((c) => {
				c.previewPosition = null;
			});
	};
	const onHover = (report: OpeningReport) => {
		UI()
			.getActiveChessboard()
			.set((c) => {
				c.previewPosition = createChessProxy(new Chess(Epd.toFen(report.epd)));
			});
	};
	return (
		<SidebarTemplate header={"Your openings report"} actions={[]}>
			<SidebarActions
				actions={[
					createFrontendSettingActions(SETTINGS.openingsTimeRange, (timeRange) => {
						quick((s) => {
							OPENINGS_REPORT_STATE().openingsReportsByTimeRange[timeRange] = null;
							s.repertoireState.openingReportsState.fetchOpeningsReport();
						});
					}),
				]}
			/>
			<Spacer height={12} />
			<Show when={reports() === null}>
				<div class="flex flex-col items-center justify-center pt-8">
					<LoadingSpinner />
				</div>
			</Show>
			<Show when={isEmpty(reports()) && reports() !== null}>
				<Spacer height={12} />
				<p class="padding-sidebar body-text">
					We weren't able to find any openings where you are over or under-performing in the past 90
					days on your connected account(s). You may need to play more games for us to get a bigger
					sample size.
				</p>
			</Show>
			<Show when={activeReport() !== null}>
				<Show when={(reports()?.length ?? 0) > 1}>
					<div class="flex gap-2 row relative">
						{renderBottomBorder(false)}
						<For each={reports()!}>
							{(r) => (
								<div
									use:onClick={() => setActiveReport(r)}
									class={clsx(
										"text-sm  transition-colors cursor-pointer py-4 font-medium relative padding-sidebar",
										activeReport()?.id === r.id
											? "text-primary"
											: "text-tertiary &hover:text-primary",
									)}
								>
									{r.timeClass}, {r.mode}
									{renderBottomBorder(activeReport()?.id === r.id)}
									{/*
								<div
									class={clsx(
										"absolute top-0 right-0 h-[10px] w-[10px] rounded-full translate-x-1/2 -translate-y-1/2  transition-opacity bg-purple-50",
										OpeningsReport.countInsights(r) > 0
											? "opacity-100"
											: "opacity-0",
									)}
								></div>
                */}
								</div>
							)}
						</For>
					</div>
				</Show>
				<Show when={!isEmpty(reports())}>
					<Spacer between={["tabs", "table"]} />
					<Show when={activeReport()!.bestOpenings.length > 0}>
						<SidebarTable
							onBlurTable={onBlurTable}
							onHover={onHover}
							title="Best openings"
							leftColumns={[
								{
									label: "Opening",
									labelStyle: "hidden",
									width: "auto",
									render: (opening) => {
										return renderOpening(opening);
									},
								},
							]}
							rows={activeReport()!.bestOpenings}
							onClick={(r) => onClickReport(r, "best")}
							rightColumns={[
								{
									label: "Performance",
									width: 70,
									align: "right",
									render: (opening) => {
										const diff = Math.round(opening.minStrength - activeReport()!.minStrength);
										return (
											<div
												class="text-green-70"
												ref={(ref) => {
													initTooltip({
														ref,
														content: () => {
															return `You perform ${diff} points above your average, when you play this opening.`;
														},
														maxWidth: 160,
													});
												}}
											>
												+{diff} Elo
											</div>
										);
									},
								},
							]}
						/>
					</Show>
					<Show when={activeReport()!.worstOpenings.length > 0}>
						<Show when={activeReport()!.bestOpenings.length > 0}>
							<Spacer between={["table", "table"]} />
						</Show>
						<SidebarTable
							title="Worst openings"
							onBlurTable={onBlurTable}
							onHover={onHover}
							leftColumns={[
								{
									label: "Opening",
									labelStyle: "hidden",
									width: "auto",
									render: (opening) => {
										return renderOpening(opening);
									},
								},
							]}
							rows={activeReport()!.worstOpenings}
							onClick={(r) => onClickReport(r, "worst")}
							rightColumns={[
								{
									label: "Performance",
									width: 70,
									align: "right",
									render: (opening) => {
										const diff = Math.round(opening.maxStrength - activeReport()!.maxStrength);
										return (
											<div
												class="text-red-70"
												ref={(ref) => {
													initTooltip({
														ref,
														content: () => {
															return `You perform ${Math.abs(
																diff,
															)} points below your average, when you play this opening.`;
														},
														maxWidth: 160,
													});
												}}
											>
												{diff} Elo
											</div>
										);
									},
								},
							]}
						/>
					</Show>
					<Spacer between={["table", "body-text"]} />
					<p class="padding-sidebar body-text">
						This report was generated from the last <b>{activeReport()!.gamesCount}</b> games on
						your connected account(s).
					</p>
				</Show>
			</Show>
		</SidebarTemplate>
	);
};

registerViewMode(OpeningsReportView, "openings_report");
