import { destructure } from "@solid-primitives/destructure";
import { dateGte } from "~/types/SpacedRepetition";
import { clsx } from "~/utils/classes";
import { plural, pluralize } from "~/utils/pluralize";
import { c, stylex } from "~/utils/styles";
import { CMText } from "./CMText";

export const ReviewText = (props: {
	date?: string | null;
	hideIcon?: boolean;
	inverse?: boolean;
	text?: string;
	numDue: number;
	iconColor?: string;
	textColor?: string;
	descriptor?: string;
	pluralizeDescriptor?: boolean;
	class?: string;
	moves?: boolean;
	icon?: string;
}) => {
	const descriptor = () => props.descriptor || "due";
	const date = () => (props.date ? new Date(props.date) : new Date());
	const now = () => new Date();
	const due = () => dateGte(date(), now());
	const prefix = () => `${descriptor()}`;
	const { textColor, iconColor, dueString, bolded } = destructure(() => {
		const bolded = false;
		let dueString = "";
		let iconColor = props.iconColor ?? "text-tertiary";
		let textColor = props.textColor ?? "text-tertiary";
		if (props.text) {
			return {
				iconColor,
				textColor,
				dueString: props.text,
				bolded: true,
			};
		}
		if (!props.date || due() || props.numDue > 0) {
			iconColor = props.iconColor ?? (props.inverse ? "text-yellow-30" : "text-yellow-50");
			textColor = "text-secondary";
			if (props.moves) {
				dueString = `${pluralize(props.numDue, "move")}`;
			} else {
				dueString = `${props.numDue.toLocaleString()} ${props.pluralizeDescriptor ? plural(props.numDue, descriptor()) : descriptor()}`;
			}
		} else {
			dueString = `${prefix()} ${getHumanTimeUntil(date())}`;
		}
		return {
			iconColor,
			textColor,
			dueString,
			bolded,
		};
	});
	return (
		<>
			<div style={stylex(c.row, c.alignCenter)} class={clsx(props.class)}>
				<CMText
					class={clsx(
						"text-xs leading-5 tabular-nums",
						textColor(),
						bolded() ? "font-semibold" : "font-semibold",
					)}
				>
					{dueString()}
				</CMText>
				{!props.hideIcon && (
					<i
						style={stylex(c.fontSize(12))}
						class={clsx(props.icon ? props.icon : "fa fa-clock", "pl-2", iconColor())}
					/>
				)}
			</div>
		</>
	);
};

function getDaysBetween(x: Date, y: Date) {
	// Create new dates with time set to midnight to avoid time zone/daylight savings issues
	const date1 = new Date(x.getFullYear(), x.getMonth(), x.getDate());
	const date2 = new Date(y.getFullYear(), y.getMonth(), y.getDate());

	// Get difference in milliseconds and convert to days
	const diffInMs = date2.getTime() - date1.getTime();
	const days = Math.round(diffInMs / (1000 * 60 * 60 * 24));

	return days;
}

// Then your function could be simplified to:
export const getHumanTimeUntil = (date: Date) => {
	const now = new Date();
	const days = getDaysBetween(now, date);

	if (days <= 0) return "Now";
	if (days === 1) return "in 1 day";
	return `in ${days} days`;
};
