import type { ChessboardMove } from "~/utils/chessboard_interface";

export type Kep = { epd: string; san: string };
export type KepKey = string;

export namespace Kep {
	export function toKey({ epd, san }: Kep): KepKey {
		return `${epd}|${san}`;
	}

	export function fromChessboardMove(chessboardMove: ChessboardMove): Kep {
		return {
			epd: chessboardMove.epd,
			san: chessboardMove.san,
		};
	}
}
