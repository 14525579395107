import { capitalize, filter, isEmpty, map, mapValues, some } from "lodash-es";
import { Show, createMemo } from "solid-js";
import { SIDES, type Side } from "~/types/Side";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import type { Uuid } from "~/types/Uuid";
import type { EnrichedComponent } from "~/types/View";
import {
	APP_STATE,
	BROWSING_STATE,
	REPERTOIRE_STATE,
	UI,
	USER_STATE,
	quick,
} from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { isMobile } from "~/utils/isMobile";
import { pluralize } from "~/utils/pluralize";
import { Quiz, countQueue } from "~/utils/queues";
import { registerViewMode } from "~/utils/register_view_mode";
import { c } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { BigBar } from "./BigBar";
import { Label } from "./Label";
import { PlansBrowser } from "./PlansBrowser";
import { RepertoireBrowser } from "./RepertoireBrowser";
import { RepertoireReview } from "./RepertoireReview";
import { ReviewShortcuts } from "./ReviewShortcuts";
import { ReviewText } from "./ReviewText";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";
import { UpgradeSubscriptionView } from "./UpgradeSubscriptionView";

export const PreReview: EnrichedComponent<{
	repertoireId: Uuid | null;
	startEpd?: string;
	startLine?: string[];
}> = (props) => {
	const _numMovesDueByRepertoire = () =>
		mapValues(
			REPERTOIRE_STATE().repertoires ?? {},
			(repertoire) => REPERTOIRE_STATE().numMovesDueFromEpd[repertoire.id]?.[START_EPD] ?? 0,
		);
	const repertoire = () =>
		props.repertoireId ? REPERTOIRE_STATE().repertoires?.[props.repertoireId] : undefined;
	const pastFreeTierWarning = () => {
		if (USER_STATE().isSubscribed()) {
			return null;
		}
		let getReason: () => Side | "both" | null = () => {
			if (
				!repertoire() &&
				(REPERTOIRE_STATE().getNumberOfMovesPastFreeTier("white") > 0 ||
					REPERTOIRE_STATE().getNumberOfMovesPastFreeTier("black") > 0)
			) {
				return "both";
			}
			for (let side of SIDES) {
				if (
					REPERTOIRE_STATE().getNumberOfMovesPastFreeTier(side) > 0 &&
					(side === repertoire()?.side || !repertoire())
				) {
					return side;
				}
			}
			return null;
		};
		let reason = getReason();
		if (!reason) {
			return null;
		}
		return (
			<>
				You've reached the limit on the free plan. Practice will be limited to your 100 most common
				moves {reason === "both" ? "for each color" : `for ${capitalize(reason)}`}.
			</>
		);
	};
	const dueQueue = createMemo(() => {
		return APP_STATE().repertoireState.reviewState.buildQueue({
			repertoireId: repertoire()?.id,
			startPosition: props.startEpd ?? START_EPD,
			startLine: props.startLine ?? [],
			filter: "due",
		});
	});
	const allQueue = createMemo(() => {
		return APP_STATE().repertoireState.reviewState.buildQueue({
			repertoireId: repertoire()?.id,
			startPosition: props.startEpd ?? START_EPD,
			startLine: props.startLine ?? [],
			filter: "all",
		});
	});
	const isQuizzing = () => {
		return !isEmpty(APP_STATE().repertoireState.reviewState.activeQueue);
	};
	const recommendedReviewSize = USER_STATE().getFrontendSetting("recommendedMovesSize")
		.value as number;
	const fromSpecificPosition = () => props.startEpd !== undefined;
	const actions = createMemo(() => {
		const actions: SidebarAction[] = [];
		// todo: this could be more performant
		const difficultDueCount = countQueue(
			filter(dueQueue(), (m) =>
				some(Quiz.getMoves(m), (m) => SpacedRepetitionStatus.isDifficult(m.srs)),
			),
		);
		const difficultCount = countQueue(
			filter(allQueue(), (m) =>
				some(Quiz.getMoves(m), (m) => SpacedRepetitionStatus.isDifficult(m.srs)),
			),
		);
		const due = countQueue(dueQueue());
		const anyDue = due > 0;
		if (isQuizzing()) {
			actions.push({
				onPress: () => {
					quick(() => {
						trackEvent("pre_review.resume");
						animateSidebar("right");
						UI().pushView(RepertoireReview);
					});
				},
				text: (
					<div class={clsx("row items-center")}>
						<p class={clsx()}>Continue your practice session</p>
					</div>
				),
				right: (
					<ReviewText
						class="!text-primary"
						icon="fa fa-forward"
						iconColor={c.colors.text.secondary}
						descriptor="Left"
						numDue={APP_STATE().repertoireState.reviewState.activeQueue.length}
					/>
				),
				style: "primary",
			});
		}
		if (due > 0) {
			actions.push({
				onPress: () => {
					quick((s) => {
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							startPosition: props.startEpd,
							startLine: props.startLine,
							filter: "recommended",
						});
						trackEvent("pre_review.common_moves");
					});
				},
				text: "Recommended moves",
				right: <ReviewText numDue={Math.min(recommendedReviewSize, due)} />,
				style: isQuizzing() ? "secondary" : "primary",
			});
		}
		if (due > recommendedReviewSize) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.all_due");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							startPosition: props.startEpd,
							startLine: props.startLine,
							filter: "due",
						});
					});
				},
				text: (
					<div class={clsx("row items-center")}>
						<p class={clsx()}>
							Everything that's due {isMobile() ? "" : "for review"}
							{!isQuizzing && <Label>Recommended</Label>}
						</p>
					</div>
				),
				right: <ReviewText numDue={due} />,
				style: "secondary",
			});
		}
		if (difficultDueCount > 0) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.difficult_due");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							startPosition: props.startEpd,
							startLine: props.startLine,
							filter: "difficult-due",
						});
					});
				},
				text: "Just the moves I often get wrong",
				right: <ReviewText numDue={difficultDueCount} />,
				style: "secondary",
			});
		}
		if (!anyDue) {
			const earlyQueue = APP_STATE().repertoireState.reviewState.buildQueue({
				repertoireId: repertoire()?.id,
				startPosition: props.startEpd ?? START_EPD,
				startLine: props.startLine ?? [],
				filter: "early",
			});
			if (countQueue(earlyQueue) > 0) {
				actions.push({
					onPress: () => {
						quick((s) => {
							trackEvent("pre_review.early");
							UI().cutView();
							s.repertoireState.reviewState.startReview({
								repertoireId: repertoire()?.id,
								startPosition: props.startEpd,
								startLine: props.startLine,
								filter: "early",
							});
						});
					},
					text: "Moves that will be due soon",
					right: `${pluralize(earlyQueue.length, "move")}`,
					style: "secondary",
				});
			}
		}
		if (!anyDue && difficultCount > 0) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.difficult");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							startPosition: props.startEpd,
							startLine: props.startLine,
							filter: "difficult",
						});
					});
				},
				text: "Just the moves I often get wrong",
				right: `${pluralize(difficultCount, "move")}`,
				style: "secondary",
			});
		}
		const numMyMoves = countQueue(allQueue());
		if (numMyMoves > due) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.all");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							startPosition: props.startEpd ?? START_EPD,
							startLine: props.startLine ?? [],
							filter: "all",
						});
					});
				},
				text: fromSpecificPosition()
					? "Everything from here"
					: repertoire()
						? `My entire ${repertoire()!.name} repertoire`
						: "My entire repertoire",
				right: <div class="tabular-nums">{numMyMoves} moves</div>,
				style: "secondary",
			});
		}
		if (!fromSpecificPosition()) {
			actions.push({
				onPress: () => {
					quick((_s) => {
						trackEvent("pre_review.specific");
						if (props.repertoireId) {
							BROWSING_STATE().setActiveRepertoire(props.repertoireId);
							UI().pushView(ReviewShortcuts, {
								props: { repertoireId: props.repertoireId },
							});
						} else {
							UI().pushView(ChooseSideForSpecificPractice, { props: { intendedMode: "browse" } });
						}
					});
				},
				text: "A specific opening I've added",
				right: <i class="fa fa-arrow-right" />,
				style: "secondary",
			});
		}

		if (!fromSpecificPosition()) {
			actions.push({
				onPress: () => {
					quick((_s) => {
						trackEvent("side_overview.view_plans_and_model_games");
						trackEvent("pre_review.specific");
						if (props.repertoireId) {
							UI().clearViews();
							animateSidebar("right");
							UI().pushView(PlansBrowser);
							BROWSING_STATE().setActiveRepertoire(props.repertoireId);
						} else {
							UI().pushView(ChooseSideForSpecificPractice, {
								props: { intendedMode: "plans_and_model_games" },
							});
						}
					});
				},
				text: "View plans and model games",
				right: <i class="fa fa-arrow-right" />,
				style: "secondary",
			});
		}
		return actions;
	});
	let masteryOverall = () => REPERTOIRE_STATE().masteryOverall;
	return (
		<div>
			<Show when={pastFreeTierWarning()}>
				<div class="padding-sidebar">
					<div class="flex flex-row gap-2 items-start">
						<i class="fa-solid fa-triangle-exclamation text-yellow-60 text-sm leading-normal"></i>
						<p class="text-yellow-60 text-sm leading-normal font-medium">{pastFreeTierWarning()}</p>
					</div>
					<Spacer between={["body-text", "actions"]} />
				</div>
				<SidebarActions
					actions={[
						{
							text: "Upgrade to practice all your moves",
							right: <i class="fa fa-arrow-right text-inverse" />,
							style: "focus",
							onPress: () => {
								quick((_s) => {
									trackEvent("pre_review.upgrade");
									UI().pushView(UpgradeSubscriptionView);
								});
							},
						},
					]}
				/>
				<Spacer between={["actions", "actions"]} />
			</Show>
			<SidebarTemplate
				header={
					fromSpecificPosition()
						? "What would you like to practice from here?"
						: "What would you like to practice?"
				}
				actions={actions()}
				bodyPadding={false}
			>
				<Show when={masteryOverall() > 0}>
					<div class="padding-sidebar pt-2">
						<BigBar
							type="mastery"
							decimalPlaces={1}
							repertoireId={repertoire()?.id}
							epd={props.startEpd}
						/>
					</div>
				</Show>
			</SidebarTemplate>
		</div>
	);
};

registerViewMode(PreReview, "browse");

export const ChooseSideForSpecificPractice = (props: {
	intendedMode: "browse" | "plans_and_model_games";
}) => {
	return (
		<SidebarTemplate
			header={`Which repertoire do you want to ${props.intendedMode === "browse" ? "practice" : "view"}?`}
			actions={map(REPERTOIRE_STATE().repertoiresList, (repertoire) => ({
				style: "primary",
				onPress: () => {
					quick((_s) => {
						if (props.intendedMode === "browse") {
							BROWSING_STATE().setActiveRepertoire(repertoire.id);
							UI().pushView(ReviewShortcuts, {
								props: {
									repertoireId: repertoire.id,
								},
							});
						} else {
							UI().pushView(
								props.intendedMode === "plans_and_model_games" ? PlansBrowser : RepertoireBrowser,
							);
							BROWSING_STATE().setActiveRepertoire(repertoire.id);
						}
					});
				},
				text: repertoire.name,
			}))}
			bodyPadding={false}
		></SidebarTemplate>
	);
};
