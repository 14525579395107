import type { ChessboardMoveFeedback } from "~/utils/chessboard_interface";

export enum MoveRating {
	Good = "good",
	Inaccuracy = "inaccuracy",
	Mistake = "mistake",
	Blunder = "blunder",
}

export namespace MoveRating {
	export const toFeedback = (rating: MoveRating): ChessboardMoveFeedback["result"] => {
		switch (rating) {
			case MoveRating.Good:
				return "correct";
			case MoveRating.Inaccuracy:
				return "inaccuracy";
			case MoveRating.Mistake:
				return "mistake";
			case MoveRating.Blunder:
				return "blunder";
		}
	};
}
