import { isNil } from "lodash-es";
import { Match, Switch } from "solid-js";
import { CMText } from "~/components/CMText";
import { initTooltip } from "~/components/Tooltip";
import { MoveRating } from "~/types/MoveRating";
import type { PositionReport } from "~/types/PositionReport";
import type { Side } from "~/types/Side";
import type { StockfishEval } from "~/types/StockfishEval";
import type { SuggestedMove } from "~/types/SuggestedMove";
import { c, stylex } from "~/utils/styles";

export const getMoveRating = ({
	before,
	after,
	positionReport,
	suggestedMove,
	side,
	includeInaccuracy = false,
}: {
	before: StockfishEval | undefined;
	after: StockfishEval | undefined;
	positionReport?: PositionReport;
	suggestedMove?: SuggestedMove;
	includeInaccuracy?: boolean;
	side: Side;
}) => {
	// Modern
	if (
		positionReport?.epd === "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq -" &&
		suggestedMove?.sanPlus === "g6"
	) {
		return null;
	}
	// Scandi
	if (
		positionReport?.epd === "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq -" &&
		suggestedMove?.sanPlus === "d5"
	) {
		return null;
	}
	// Pirc
	if (
		positionReport?.epd === "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq -" &&
		suggestedMove?.sanPlus === "d6"
	) {
		return null;
	}
	if (isNil(before) || isNil(after)) {
		return null;
	}
	const expectedWinBefore = before.getPovWinPercentage(side);
	const expectedWinAfter = after.getPovWinPercentage(side);
	return getMoveRatingFromWinPercentages(expectedWinBefore, expectedWinAfter, includeInaccuracy);
};

export const getMoveRatingFromWinPercentages = (
	winBefore: number,
	winAfter: number,
	includeInaccuracy = false,
) => {
	const diff = winAfter - winBefore;
	if (diff < -0.2) {
		return MoveRating.Blunder;
	}
	if (diff < -0.09) {
		return MoveRating.Mistake;
	}
	if (diff < -0.06 && includeInaccuracy) {
		return MoveRating.Inaccuracy;
	}
};

export const getMoveRatingIcon = (rating: MoveRating) => {
	const styles = stylex(c.weightBold, c.fontSize(14));
	return (
		<div
			ref={(ref) => {
				initTooltip({
					ref,
					content: () => (
						<p>
							This move is{" "}
							{rating === MoveRating.Inaccuracy ? (
								<>
									an <b>inaccuracy</b>
								</>
							) : (
								<>
									a <b>{rating === MoveRating.Mistake ? "mistake" : "blunder"}</b>
								</>
							)}
						</p>
					),
					maxWidth: 200,
				});
			}}
		>
			<Switch>
				<Match when={rating === MoveRating.Inaccuracy}>
					<CMText style={stylex(c.fg(c.yellow[60]), styles)}>?!</CMText>
				</Match>
				<Match when={rating === MoveRating.Mistake}>
					<CMText style={stylex(c.fg(c.orange[60]), styles)}>?</CMText>
				</Match>
				<Match when={rating === MoveRating.Blunder}>
					<CMText style={stylex(c.fg(c.red[60]), styles)}>??</CMText>
				</Match>
			</Switch>
		</div>
	);
};
