import { every, filter, isNil, map, maxBy, sum, take, values } from "lodash-es";
import { GameResultsDistribution } from "~/types/GameResults";
import { MoveRating } from "~/types/MoveRating";
import { MoveTag } from "~/types/MoveTag";
import type { PositionReport } from "~/types/PositionReport";
import { Side } from "~/types/Side";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import type { StockfishEval } from "~/types/StockfishEval";
import { SuggestedMove } from "~/types/SuggestedMove";
import type { TableResponse } from "~/types/TableResponse";
import { REPERTOIRE_STATE, UI } from "./app_state";
import { getMoveRating } from "./move_inaccuracy";
import { EVAL_NODES, StockfishInterface } from "./stockfish";
import { scoreTableResponses, shouldUsePeerRates } from "./table_scoring";
import { isTheoryHeavy } from "./theory_heavy";

export const buildTableResponses = ({
	repertoireId,
	currentEpd,
	currentSide,
	onStockfishUpdate,
}: {
	repertoireId: string;
	currentEpd: string;
	currentSide: Side;
	onStockfishUpdate: () => void;
}): TableResponse[] => {
	let repertoires = REPERTOIRE_STATE().repertoires;
	if (!repertoires) {
		return [];
	}
	const threshold = REPERTOIRE_STATE().getRepertoireThreshold(repertoireId);
	const repertoire = repertoires[repertoireId];
	if (!repertoire) {
		return [];
	}
	const repertoireSide = repertoire?.side;
	const mode = UI().mode;
	const positionReport = REPERTOIRE_STATE().positionReports[repertoireId]?.[currentEpd];
	const _tableResponses: Record<string, TableResponse> = {};
	positionReport?.suggestedMoves
		.filter((sm) => GameResultsDistribution.getTotalGames(sm.results)! > 0)
		.map((sm) => {
			_tableResponses[sm.sanPlus] = {
				suggestedMove: sm,
				tags: [],
				side: repertoireSide,
			};
		});
	const isMySide = repertoireSide === currentSide;
	const totalPeerGames = sum(
		map(_tableResponses, (r) => {
			const results = r.suggestedMove?.results;
			if (!results) {
				return 0;
			}
			return GameResultsDistribution.getTotalGames(results);
		}),
	);
	const totalMasterGames = sum(
		map(_tableResponses, (r) => {
			const masterResults = r.suggestedMove?.masterResults;
			if (!masterResults) {
				return 0;
			}
			return GameResultsDistribution.getTotalGames(masterResults);
		}),
	);
	if (
		positionReport &&
		((isMySide &&
			Object.keys(_tableResponses).length < 4 &&
			totalMasterGames < 30 &&
			totalPeerGames < 50) ||
			(!isMySide && Object.keys(_tableResponses).length === 0))
	) {
		if (StockfishInterface.epd !== currentEpd) {
			StockfishInterface.getTopMoves(
				currentEpd,
				{ pv: 3, nodes: EVAL_NODES.DEEP },
				(_stockfishMoves) => {
					onStockfishUpdate();
				},
			);
		}
	} else {
		// in case we started before getting a good position report, don't waste cycles
		StockfishInterface.setupForNewTask();
	}
	const existingMoves = repertoire.positionResponses[currentEpd];
	existingMoves?.map((r) => {
		if (_tableResponses[r.sanPlus]) {
			_tableResponses[r.sanPlus].repertoireMove = r;
		} else {
			_tableResponses[r.sanPlus] = {
				repertoireMove: r,
				tags: [],
				side: repertoireSide,
			};
		}
	});
	StockfishInterface?.pendingMoves?.map((stockfishMove) => {
		const san = stockfishMove.san;
		if (_tableResponses[san]) {
			_tableResponses[san].stockfishMove = stockfishMove;
		} else {
			_tableResponses[san] = {
				stockfishMove: stockfishMove,
				tags: [],
				side: repertoireSide,
			};
		}
	});
	const ownSide = currentSide === repertoireSide;
	const usePeerRates = shouldUsePeerRates(positionReport);
	let tableResponses = values(_tableResponses);
	if (mode === "browse") {
		tableResponses = tableResponses.filter((tr) => tr.repertoireMove);
	}
	const biggestMisses = REPERTOIRE_STATE().repertoireGrades[repertoireId]?.biggestMisses ?? {};
	tableResponses.forEach((tr) => {
		const epd = tr.suggestedMove?.epdAfter || tr.repertoireMove?.epdAfter!;
		if (biggestMisses[epd]) {
			tr.biggestMiss = biggestMisses[epd];
		}
	});
	tableResponses.forEach((tr) => {
		if (ownSide && tr.suggestedMove && positionReport) {
			const positionWinRate = GameResultsDistribution.getWinRate(
				positionReport?.results,
				repertoireSide,
			);
			const [, , ci] = GameResultsDistribution.getWinRateRange(
				tr.suggestedMove.results,
				repertoireSide,
			);
			const moveWinRate = GameResultsDistribution.getWinRate(
				tr.suggestedMove.results,
				repertoireSide,
			);
			if (ci > 0.12 && Math.abs(positionWinRate - moveWinRate) > 0.02) {
				tr.lowConfidence = true;
			}
		}
	});
	tableResponses.forEach((tr) => {
		if (!ownSide && mode === "build") {
			if (
				// @ts-expect-error
				tr.suggestedMove?.incidence < threshold &&
				tr.suggestedMove?.needed
			) {
				tr.tags.push(MoveTag.RareDangerous);
			}
		}
	});
	const now = new Date();
	tableResponses.forEach((tr) => {
		if (UI().mode === "browse" && tr.repertoireMove) {
			const epd = tr.suggestedMove?.epdAfter || tr.repertoireMove.epdAfter;
			let dueBelow = REPERTOIRE_STATE().numMovesDueFromEpd[repertoireId]?.[epd];
			let earliestBelow = REPERTOIRE_STATE().earliestReviewDueFromEpd[repertoireId]?.[epd];
			const dueAt = tr.repertoireMove.srs?.dueAt;
			if (dueAt && (dueAt < earliestBelow || !earliestBelow)) {
				earliestBelow = dueAt;
			}
			const isDue =
				tr.repertoireMove.srs && SpacedRepetitionStatus.isReviewDue(tr.repertoireMove.srs, now);
			dueBelow = dueBelow + (isDue ? 1 : 0);
			tr.reviewStatus = {
				earliestDue: earliestBelow,
				due: dueBelow,
			};
		}
	});
	const stockfishEvals: StockfishEval[] = filter(
		map(tableResponses, (tr) => {
			return tr.suggestedMove?.stockfish;
		}),
		(stockfish) => !isNil(stockfish),
	) as StockfishEval[];
	const bestStockfishEval = maxBy(stockfishEvals, (stockfish: StockfishEval) => {
		return stockfish.getPovWinPercentage(currentSide);
	});
	tableResponses.forEach((tr) => {
		if (!ownSide && mode === "build") {
			if (
				isCommonMistake(tr, positionReport, bestStockfishEval) &&
				!tr.tags.includes(MoveTag.RareDangerous)
			) {
				tr.tags.push(MoveTag.CommonMistake);
			}
		}
	});
	tableResponses.forEach((tr) => {
		if (tr.repertoireMove?.isDisabled) {
			tr.tags.push(MoveTag.Disabled);
		}
	});
	tableResponses.forEach((tr) => {
		if (mode !== "build") {
			return;
		}
		if (tr.repertoireMove || !tr.suggestedMove) {
			return;
		}
		const epdAfter = tr.suggestedMove.epdAfter;

		if (!tr.repertoireMove && REPERTOIRE_STATE().epdNodes[repertoireId]?.[epdAfter]) {
			tr.transposes = true;
			tr.tags.push(MoveTag.Transposes);
		}

		if (isTheoryHeavy(tr, currentEpd) && ownSide) {
			tr.tags.push(MoveTag.TheoryHeavy);
		}
	});
	tableResponses.forEach((tr) => {
		const moveRating = getMoveRating({
			positionReport,
			after: tr.suggestedMove?.stockfish,
			before: bestStockfishEval,
			suggestedMove: tr.suggestedMove,
			side: currentSide,
		});
		// @ts-expect-error
		tr.moveRating = moveRating;
	});
	if (ownSide && tableResponses.length >= 3) {
		tableResponses.forEach((tr, i) => {
			if (mode !== "build") {
				return;
			}
			const allOthersInaccurate = every(tableResponses, (tr, j) => {
				const playedByMasters =
					tr.suggestedMove &&
					SuggestedMove.getPlayRate(tr.suggestedMove, positionReport, true)! > 0.02;
				return (!isNil(tr.moveRating) && !playedByMasters) || j === i;
			});
			const playedEnough =
				GameResultsDistribution.getTotalGames(tr.suggestedMove?.results!)! /
					GameResultsDistribution.getTotalGames(positionReport?.results!)! >
				0.02;
			if (allOthersInaccurate && isNil(tr.moveRating) && playedEnough) {
				tr.tags.push(MoveTag.BestMove);
			}
		});
	}
	tableResponses = scoreTableResponses(
		tableResponses,
		positionReport,
		bestStockfishEval,
		currentSide,
		UI().mode,
		ownSide,
		!usePeerRates,
	);
	if (tableResponses.length >= 3) {
		let contemptValue = 125;
		let minimumContemptAdvantageThreshold = 1.01;

		let highestExpectedScore = maxBy(
			filter(take(tableResponses, 5), (tr) => {
				return (
					!isNil(tr.suggestedMove?.leelaExpectedScore) &&
					tr.moveRating !== MoveRating.Mistake &&
					tr.moveRating !== MoveRating.Blunder
				);
			}),
			(tr) => tr.suggestedMove!.leelaExpectedScore,
		);
		let excludedPositions = new Set();
		excludedPositions.add("rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -");

		let highestContemptTableResponse = maxBy(
			filter(take(tableResponses, 5), (tr) => {
				return (
					!isNil(tr.suggestedMove?.contemptDelta) &&
					tr.moveRating !== MoveRating.Mistake &&
					tr.moveRating !== MoveRating.Blunder
				);
			}),
			(tr) =>
				tr.suggestedMove!.leelaExpectedScore! + tr.suggestedMove!.contemptDelta! * contemptValue,
		);
		if (highestContemptTableResponse && mode === "build" && highestExpectedScore) {
			if (
				highestContemptTableResponse.suggestedMove!.leelaExpectedScore! +
					highestContemptTableResponse.suggestedMove!.contemptDelta! * contemptValue >
					(highestExpectedScore.suggestedMove!.leelaExpectedScore! +
						highestExpectedScore.suggestedMove!.contemptDelta! * contemptValue) *
						minimumContemptAdvantageThreshold &&
				highestContemptTableResponse.suggestedMove?.leelaExpectedScore !==
					highestExpectedScore.suggestedMove?.leelaExpectedScore
			) {
				if (!excludedPositions.has(highestContemptTableResponse.suggestedMove!.epdAfter)) {
					highestContemptTableResponse.tags.push(MoveTag.Sharpest);
				}
			}
		}
	}
	return tableResponses;
};

const isCommonMistake = (
	tr: TableResponse,
	positionReport: PositionReport,
	bestStockfishEval: StockfishEval | undefined,
): boolean => {
	if (!tr.suggestedMove || !positionReport) {
		return false;
	}
	if (GameResultsDistribution.getTotalGames(tr.suggestedMove?.results)! < 100) {
		return false;
	}
	if (!tr.suggestedMove?.needed) {
		return false;
	}
	if (
		GameResultsDistribution.getWinRate(tr.suggestedMove.results, Side.flip(tr.side)) >
		GameResultsDistribution.getWinRate(positionReport.results, Side.flip(tr.side)) + 0.02
	) {
		return false;
	}
	const moveRating = getMoveRating({
		positionReport,
		after: tr.suggestedMove?.stockfish,
		before: bestStockfishEval,
		suggestedMove: tr.suggestedMove,
		side: Side.flip(tr.side),
	});
	if (isNil(moveRating) || moveRating < MoveRating.Mistake) {
		return false;
	}
	return true;
};
