import { For, Show } from "solid-js";
import { clsx } from "~/utils/classes";

export const TextIconRow = (props: {
	icons: { icon?: string | null; class?: string; text: string | number }[];
}) => {
	return (
		<div class="row items-center space-x-4 lg:space-x-8">
			<For each={props.icons}>
				{(i) => {
					return (
						<div class="row items-center cursor-default">
							<p class={clsx("text-sm font-semibold lg:text-base text-secondary tabular-nums")}>
								{i.text}
							</p>
							<Show when={i.icon}>
								<i class={clsx(i.class, i.icon, " ml-2 text-sm lg:text-base")} />
							</Show>
						</div>
					);
				}}
			</For>
		</div>
	);
};
