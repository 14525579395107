import confetti from "canvas-confetti";
import { createSignal } from "solid-js";
import { isMobile } from "~/utils/isMobile";

const [confettiInstance, setConfettiInstance] = createSignal<typeof confetti>();

export const showerSuccessConfetti = () => {
	const colors = ["#ff4f6a", "#f68820", "#54cbcb", "#64b550", "#ffb316"];

	const duration = Date.now() + 250;
	const confetti = confettiInstance();
	const screenWidth = window.innerWidth;
	if (!confetti) {
		return;
	}
	const startVelocity = Math.min(screenWidth / 15, 50);

	let angle = -40;
	if (isMobile()) {
		angle = -70;
	}
	let particleCount = 10;
	(function f(): void {
		confetti({
			particleCount,
			angle: angle,
			spread: 75,
			ticks: 150,
			startVelocity,
			origin: { x: 0, y: 0 },
			colors,
		});
		confetti({
			particleCount,
			angle: 180 - angle,
			spread: 75,
			ticks: 150,
			startVelocity,
			origin: { x: 1, y: 0 },
			colors,
		});

		if (Date.now() < duration) {
			requestAnimationFrame(f);
		}
	})();
};

export const showerFeedbackConfetti = ({ x, y }: { x: number; y: number }) => {
	const colors = ["#ff4f6a", "#f68820", "#54cbcb", "#64b550", "#ffb316"];

	// const duration = Date.now() + 10;
	const confetti = confettiInstance();
	const screenWidth = window.innerWidth;
	if (!confetti) {
		return;
	}
	const startVelocity = 10;
	let screenHeight = window.innerHeight;
	let xPercentage = x / screenWidth;
	let yPercentage = y / screenHeight;

	(function f(): void {
		confetti({
			particleCount: 30,
			angle: 0,
			spread: 360,
			decay: 0.8,
			scalar: 0.8,
			ticks: 30,

			startVelocity,
			origin: { x: xPercentage, y: yPercentage },
			colors,
		});

		// if (Date.now() < duration) {
		// 	requestAnimationFrame(f);
		// }
	})();
};

export const Confetti = () => {
	return (
		<>
			<canvas
				id="confetti"
				ref={(canvas) => {
					setConfettiInstance(
						() =>
							confetti.create(canvas, {
								resize: true,
								useWorker: true,
							}) as typeof confetti,
					);
				}}
				class="z-[9999] fixed inset-0 pointer-events-none w-full h-full"
			/>
		</>
	);
};
